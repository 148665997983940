const Formatter = {
  number(e) {
    return String(e).replace(/[^0-9]/g, "")
  },
  decimalNumber(e){
    return String(e).replace(/[^0-9,.]/g, "").replaceAll(",", ".")
  },

  default(e){
    return String(e)
  },

  normalText(e){
    return String(e).replace(/[^a-zA-Z0-9'.\s-/,_()&"{}|?<>\[\]]:;/g, "").replace(/  +/g, ' ')
  },

  degrees(e){
    const withoutDuplicateDots = String(e).replace(/\.{2,}/g, ".")
    const numberValue = parseFloat(withoutDuplicateDots) || 0
    return numberValue == 0 ? numberValue : numberValue.toFixed(2)
  }
}
export default Formatter