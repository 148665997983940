<template>
  <div>                  
    <table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
        <thead>
        <tr>
            <th width="40">Aksi</th>
            <th width="160">Jenis</th>
            <th width="320">Nama</th>
            <th width="150">Jumlah</th>
            <th width="210">Frekuensi</th>
            <th width="80">Oral</th>
            <th>Keterangan</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(v,k) in isParent.resepObat" :key="'resepObat'+k">
            <td>
            <a href="javascript:;" @click="removeObat(v,k)"
                class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                class="icon-trash"></i></a>
            </td>
            <td>
            <v-select placeholder="Pilih Item" v-model="isParent.resepObat[k].aukpkrr_jenis"
                :options="isParent.Config.mr.configResepRanap" label="text"
                @input="changeJenis($event,k)" :reduce="v=>v.value">
            </v-select>
            <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                v-model="isParent.resepObat[k].aukpkrr_jenis" :rules="{required : 1}" />
            </td>
            <td>
            <div class="d-flex">
                <template v-if="isParent.resepObat[k]['aukpkrr_jenis'] == 1">
                <v-select style="flex: 1;" class="med-selection select-paging"
                    placeholder="Pilih Item"
                    :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                    @open="countLen" @search="filterObat($event,k);countLen();"
                    v-model="isParent.resepObat[k].aukpkrr_item_id" :options="isParent.mObatPreferensi"
                    label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                    :reduce="v=>v.value"
                    :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                    <template slot="selected-option">
                    <span
                        :class="isAlergiLabel(v.aukpkrr_nama)?'text-warning':''">{{ v.aukpkrr_nama }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                        v-if="option.preferensi"><b>{{ option.text }}</b></span>
                    <span :class="isAlergiLabel(option.text)?'text-warning':''"
                        v-else>{{ option.text }}</span>
                    </template>
                    <template #list-footer>
                    <li class="list-paging">
                        <div class="d-flex align-items-center justify-content-between">
                        <b-button
                            @click="changePageObat(v.currentPage,k,'min','resepObat')"
                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                            <i class="icon-arrow-left22 text-white"></i></b-button>
                        <small>Halaman {{v.currentPage}} dari
                            {{ceilData(v.totalRows/10)}}</small>
                        <b-button
                            @click="changePageObat(v.currentPage,k,'plus','resepObat')"
                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                            <i class="icon-arrow-right22 text-white"></i></b-button>
                        </div>
                    </li>
                    </template>
                </v-select>
                <br />
                </template>

                <template v-else-if="isParent.resepObat[k]['aukpkrr_jenis'] == 2">
                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                    placeholder="Pilih Item" v-model="isParent.resepObat[k].aukpkrr_item_id"
                    :options="isParent.mAlatKesehatan" label="text"
                    @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                    :reduce="v=>v.value">
                    <template slot="selected-option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                </v-select>
                </template>

                <template v-else-if="isParent.resepObat[k]['aukpkrr_jenis'] == 3">
                <v-select style="flex: 1;" class="med-selection select-paging"
                    placeholder="Pilih Item" v-model="isParent.resepObat[k].aukpkrr_item_id"
                    :options="isParent.mRacikan" label="text"
                    @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                    :reduce="v=>v.value">
                    <template slot="selected-option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                </v-select>
                </template>

                
                <template v-else-if="isParent.resepObat[k]['aukpkrr_jenis'] == 5">
                <v-select style="flex: 1;" class="med-selection select-paging select-custom-height"
                    placeholder="Pilih Item" v-model="isParent.resepObat[k].aukpkrr_item_id"
                    :options="isParent.mAlatInfus" label="text"
                    @input="selectJumlahSatuanInfus($event,k)" :clearable="true"
                    :reduce="v=>v.value">
                    <template slot="selected-option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                    <template slot="option" slot-scope="option">
                    <span>{{ option.text }}</span>
                    </template>
                </v-select>
                </template>

                <a href="javascript:;" v-if="isParent.resepObat[k]['aukpkrr_jenis'] !== 3"
                @click="isParent.resepObat[k]['aukpkrr_item_id'] = 99999; isParent.resepObat[k]['aukpkrr_nama'] = 'Lainnya'"
                v-b-tooltip.hover="'Pilih Lainnya'"
                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                    class="icon-plus-circle2"></i></a>
            </div>
            <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                v-model="isParent.resepObat[k]['aukpkrr_item_id']" :rules="{required : 1}" />

            <div v-if="isParent.resepObat[k]['aukpkrr_item_id'] == 99999">
                <b-form-input type="text" v-model="isParent.resepObat[k]['aukpkrr_item_lainnya']"
                class="form-control" id="itemCount" />
                <VValidate :name="'Item Lainnya '+(k+1)"
                message="Nama Item Lainnya Harus Diisi"
                v-model="isParent.resepObat[k]['aukpkrr_item_lainnya']" :rules="{required : 1}" />
            </div>

            <a v-if="isParent.resepObat[k].aukpkrr_jenis == 3 && isParent.resepObat[k]['aukpkrr_item_id']"
                href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title=""
                data-toggle="modal" data-target="#modalRacikanDetail"
                class="font-weight-semibold border-bottom"
                data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

            <small class="text-warning"
                v-if="isAlergiLabel(isParent.resepObat[k].aukpkrr_nama) ">Obat ini termasuk dalam
                kategori alergi</small>
            </td>
            <td>
            <div class="input-group">
                <b-form-input type="text" v-model="isParent.resepObat[k]['aukpkrr_jumlah']"
                :formatter="isParent.number" class="form-control" id="itemCount" />
                <div class="input-group-append">
                <span class="input-group-text">{{isParent.resepObat[k]['aukpkrr_satuan']}}</span>
                </div>
            </div>

            <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                v-model="isParent.resepObat[k]['aukpkrr_jumlah']" :rules="{required : 1}" />
            </td>

            <td>
            <span class="d-flex">
                <v-select style="flex: 1;" class="med-selection"
                placeholder="Pilih Frekuensi" v-model="isParent.resepObat[k].aukpkrr_frekuensi"
                :options="isParent.mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                </v-select>
                <a href="javascript:;" @click="isParent.resepObat[k]['aukpkrr_frekuensi'] = 99999"
                v-b-tooltip.hover="'Pilih Lainnya'"
                class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                    class="icon-plus-circle2"></i></a>
            </span>
            <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                v-model="isParent.resepObat[k]['aukpkrr_frekuensi']" 
                v-if="isParent.resepObat[k].aukpkrr_jenis != 2"
                :rules="{required : 1}" />

            <div v-if="isParent.resepObat[k]['aukpkrr_frekuensi'] == 99999">
                <b-form-input type="text"
                v-model="isParent.resepObat[k]['aukpkrr_frekuensi_lainnya']" class="form-control"
                id="itemCount" />
                <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                message="Frekuensi Lainnya Harus Diisi"
                v-if="isParent.resepObat[k].aukpkrr_jenis != 2"
                v-model="isParent.resepObat[k]['aukpkrr_frekuensi_lainnya']"
                :rules="{required : 1}" />
            </div>
            </td>
            
            
            <td>
              <b-form-radio-group
                class="custom-block"
                :options="isParent.Config.mr.rutePemberian"
                v-model="isParent.resepObat[k]['aukpkrr_rute']"
              />
              <VValidate :name="'Rute Pemberian '+(k+1)" message="Rute Pemberian"
                v-model="isParent.resepObat[k]['aukpkrr_rute']" :rules="{required : 1}" />
            </td>

            <td>
            <b-textarea v-model="isParent.resepObat[k].aukpkrr_keterangan" name="itemNotes"
                id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                class="form-control"></b-textarea>
            </td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
            <td class="text-center" colspan="99">
            <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                data-toggle="modal" data-target="#modalResep">
                <i class="icon-plus2 mr-1 align-middle"></i>
                <span class="align-middle">Tambah Terapi</span>
            </a>
            </td>
        </tr>
        </tfoot>
    </table>


    <b-modal v-model="openRacikan" :title="'Komposisi Racikan'" size="md" hide-footer>
        <div class="modal-body p-0 table-responsive">
            <p>{{rowRacikan.aukpkrr_jumlah||"-"}} {{rowRacikan.aukpkrr_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri
            dari:</p>
            <table class="table table-striped table-sm table-hover table-bordered">
            <thead>
                <tr>
                <th width="40">#</th>
                <th>Nama Obat</th>
                <th>Qty</th>
                <th>Frekuensi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in rowRacikan.aukpkrr_racikan" :key="k+'rowracik'">
                <td>{{k+1}}</td>
                <td>{{getObat(v.value)||"-"}}</td>
                <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="modal-footer p-0 mt-3">

            <a href="javascript:;" @click="doOpenFormRacikan()" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            <div class="icon-pencil7"></div>
            Ubah Komposisi Racikan
            </a>
            <button type="button" @click="openRacikan = false" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
        </div>
    </b-modal>

    <validation-observer ref="VFormRacikan">
    <b-form @submit.prevent="doSubmitRacikan">
        <b-modal v-model="openFormRacikan" :title="'Ubah Komposisi Racikan'" size="lg" hide-footer>
        <div class="modal-body p-0">
            <div class="alert alert-info">
            <span>Saat ini Anda sedang melakukan perubahan komposisi racikan
                <strong>{{rowRacikan.mrd_nama_racikan||"-"}}</strong> milik
                <strong>{{rowRacikan.bu_full_name||"-"}}</strong></span>
            </div>
            <div class="form-group">
            <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
            <table class="table table-bordered table-striped table-sm">
                <thead>
                <tr>
                    <th width="48">#</th>
                    <th width="33%">Obat</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in rowRacikan.aukpkrr_racikan" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                    <v-select placeholder="Pilih Obat" @input="selectObat($event,k)"
                        v-model="rowRacikan.aukpkrr_racikan[k]['value']" :options="getMasterObat(rowRacikan.aukpkrr_racikan,k)"
                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                    <VValidate :name="'Obat #'+(k+1)" message="Obat Harus Diisi"
                        v-model="rowRacikan.aukpkrr_racikan[k]['value']" :rules="{required : 1}" />
                    </td>
                    <td>
                    <div class="input-group">
                        <input v-model="rowRacikan.aukpkrr_racikan[k]['dosis']" type="text" class="form-control"
                        placeholder="cth. 120">
                        <div class="input-group-append"><input style="width: 90px;"
                            v-model="rowRacikan.aukpkrr_racikan[k]['satuan_dosis']" type="text" class="form-control"
                            placeholder="cth. Kaplet"></div>
                    </div>
                    </td>
                    <td>
                    <div class="input-group">
                        <b-form-input type="text" v-model="rowRacikan.aukpkrr_racikan[k]['jumlah']"
                        :formatter="isParent.number" class="form-control" placeholder="cth. 5" />
                        <div class="input-group-append"><span
                            class="input-group-text">{{rowRacikan.aukpkrr_racikan[k]['satuan_jumlah']||"-"}}</span></div>
                    </div>

                    <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                        v-model="rowRacikan.aukpkrr_racikan[k]['jumlah']" :rules="{required: 1}" />
                    </td>
                    <td>
                    <a href="javascript:;" @click="rowRacikan.aukpkrr_racikan.splice(k,1)"
                        class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                        data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="9" class="text-center">
                    <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Obat</span>
                    </a>
                    </td>
                </tr>
                </tfoot>
            </table>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
            <div>
                <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                <div class="input-group">
                <b-form-input type="text" :formatter="isParent.number" v-model="rowRacikan.aukpkrr_jumlah"
                    class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan" />
                <div class="input-group-append">
                    <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="rowRacikan.aukpkrr_satuan"
                    :options="isParent.mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
                </div>
                <VValidate name="Jumlah" v-model="rowRacikan.aukpkrr_jumlah" :rules="{required : 1}" />
                <VValidate name="Satuan" v-model="rowRacikan.aukpkrr_satuan" :rules="{required : 1}" />
            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            Batal
            </a>
            <button type="submit" @click="doSubmitRacikan" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
        </div>
        </b-modal>
    </b-form>
    </validation-observer>
    
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import { stringSimilarity } from "string-similarity-js"

export default{
  extends: GlobalVue,
  data() {
    return {
      openFormRacikan: false,
      obatLen: 0,
      currentPage: 1,
      openRacikan: false,
      rowRacikan: {},

      openRiwayatObat: false,
      selectedObat: '',
      searchObat: '',
    }
  },
  computed: {
    isParent(){
      return this.$parent.$parent
    },
    modulePage(){
      return 'UGDKajianRestrain' 
    }
  },
  methods: {
    changePageObat(page, k, act, col) {
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this.isParent[col][k]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this.isParent[col][k]['totalRows'] / 10)) {
                page += 1
                this.isParent[col][k]['currentPage'] += 1
            }
        }

        let data = {
            type: 'select-paging-obat',
            page: page,
            idDokter: this.isParent.rowReg.aur_dokter_id,
            search: this.isParent[col][k]['search']
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.isParent.mObatPreferensi = res.data.data
        })
    },

    openObat(v = null) {
        let data = {
            aukpkrr_aur_id: this.isParent.rowReg.aur_id,
            aukpkrr_aukpkr_id: this.isParent.rowData.aukpkr_id,
            aukpkrr_jenis: 1,
            aukpkrr_item_id: null,
            aukpkrr_nama: null,
            aukpkrr_jumlah: null,
            aukpkrr_satuan: null,
            aukpkrr_frekuensi: null,
            aukpkrr_frekuensi_lainnya: null,
            aukpkrr_keterangan: null,
            aukpkrr_code : null,
            aukpkrr_is_alergi: null,
            aukpkrr_signa1: null,
            aukpkrr_signa2: null,
            aukpkrr_rute: null,
            id: null
        }
        data.type = 'add-resep-dokter'
        Gen.apiRest(
            "/do/" + this.modulePage, {
            data: data
            },
            "POST"
        ).then(res => {
            let resp = res.data
            resp.row.currentPage = 1
            resp.row.totalRows = 1345
            //notes
            this.isParent.resepObat.push(resp.row)
        })
    },
    
    removeObat(v, k) {
      let data = {
          type: 'remove-resep-dokter',
          id: v.aukpkrr_id
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
          this.isParent.resepObat.splice(k, 1)
      })
    },
    
    resetObat() {
        this.loadingOverlay = true
        let data = {
            id: this.isParent.rowData.aukpkrr_id,
            type: 'get-resep-dokter'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            let resp = res.data
            this.isParent.resepObat = resp.data
            this.loadingOverlay = false
        })
    },

    
    toRacikan(v) {
        this.loadingOverlay = true
        let data = {
            id: v.aukpkrr_item_id,
            type: 'get-racikan-data'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.loadingOverlay = false
            this.rowRacikan = {
                ...v,
                ...res.data.row
            }
            this.isParent.mObat = res.data.mObat
            this.openRacikan = true
        })
    },
    
    doSubmitRacikan() {
      this.$refs['VFormRacikan'].validate().then(success => {
        if (success) {
          let data = this.rowRacikan
          let idx = this.isParent.resepObat.findIndex(x => x.aukpkrr_id == this.rowRacikan.aukpkrr_id)
          this.isParent.resepObat[idx]['aukpkrr_satuan'] = this.rowRacikan.aukpkrr_satuan
          this.isParent.resepObat[idx]['aukpkrr_jumlah'] = this.rowRacikan.aukpkrr_jumlah
          this.isParent.resepObat[idx]['aukpkrr_racikan'] = this.rowRacikan.aukpkrr_racikan
          this.openFormRacikan = false
        } else {
          setTimeout(() => {
          let inv = []
          let el = document.querySelectorAll('.label_error')
          for (let i = 0; i < (el || []).length; i++) {
              if (el[i].style.display !== 'none') {
              inv.push(el[i].id)
              }
          }
          if (inv.length) document.getElementById(inv[0]).scrollIntoView({
              behavior: 'smooth',
              block: 'center'
          })
          }, 500)
        }
      })
    },
    
    doOpenFormRacikan() {
        let data = {
            type: 'get-all-obat'
        }
        Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
        ).then(res => {
            this.isParent.mObat = res.data.data
            this.openFormRacikan = true
            this.openRacikan = false
        })
    },
    
    addObat() {
        this.rowRacikan.aukpkrr_racikan.push({
            value: null,
            dosis: null,
            satuan_dosis: null,
            jumlah: null,
            satuan_jumlah: null,
        })
    },
    
    resetData() {
        this.searchObat = ''
        this.openFormRacikan = false
        this.isParent.apiGetForm()
    },

    
    changeJenis(e, k) {
        this.isParent.resepObat[k].aukpkrr_item_id = null
        this.isParent.resepObat[k].aukpkrr_frekuensi = null
        this.isParent.resepObat[k].aukpkrr_frekuensi_lainnya = null
    },
    ceilData(value) {
        return Math.ceil(value)
    },
    selectObat(e, k) {
        let index = this.isParent.mObat.findIndex(x => x.value == e)
        if (index !== -1) {
            let data = this.isParent.mObat[index]
            this.rowRacikan.aukpkrr_racikan[k]['dosis'] = data.dosis
            this.rowRacikan.aukpkrr_racikan[k]['satuan_dosis'] = data.satuan_dosis
            this.rowRacikan.aukpkrr_racikan[k]['satuan_jumlah'] = data.satuan
        }
    },
    filterObat: _.debounce(function (e, k) {
        if (e) {
            this.isParent.resepObat[k]['search'] = e
            let id = []
            for (let i = 0; i < (this.isParent.resepObat || []).length; i++) {
                if (this.isParent.resepObat[i]['aukpkrr_item_id'] && this.isParent.resepObat[i]['aukpkrr_jenis'] == 1) {
                id.push(this.isParent.resepObat[i]['aukpkrr_item_id'])
                }
            }
            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'select-obat',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.isParent.mObatPreferensi = res.data.data
                this.isParent.resepObat[k]['currentPage'] = 1
                this.isParent.resepObat[k]['totalRows'] = res.data.totalRows
            })
        }
    }, 10),

    
    selectJumlahSatuan(e, k) {
        let data = {
            id: e,
            type: 'get-by-obat'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.isParent.resepObat[k].aukpkrr_satuan = res.data.satuan
            this.isParent.resepObat[k].aukpkrr_nama = res.data.nama
            this.isParent.resepObat[k].aukpkrr_racikan = null

            this.isParent.resepObat[k].aukpkrr_dosis = res.data.dosis
            this.isParent.resepObat[k].aukpkrr_satuan_dosis = res.data.satuan_dosis
            this.isParent.resepObat[k].aukpkrr_item_lainnya = null

            this.isAlergi(res.data.nama, k)
        })
    },
    
    isAlergi(nama, k) {
        let data = []
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }

        this.isParent.resepObat[k]['aukpkrr_is_alergi'] = isWarning ? "Y" : "N"
    },
    selectJumlahSatuanAlkes(e, k) {
        let data = {
            id: e,
            type: 'get-by-alkes'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.isParent.resepObat[k].aukpkrr_satuan = res.data.satuan
            this.isParent.resepObat[k].aukpkrr_nama = res.data.nama
            this.isParent.resepObat[k].aukpkrr_racikan = null

            this.isParent.resepObat[k].aukpkrr_dosis = null
            this.isParent.resepObat[k].aukpkrr_satuan_dosis = null
            this.isParent.resepObat[k].aukpkrr_frekuensi = null
            this.isParent.resepObat[k].aukpkrr_frekuensi_lainnya = null
            this.isParent.resepObat[k].aukpkrr_item_lainnya = null
        })
    },
    selectJumlahSatuanInfus(e, k) {
        let data = {
            id: e,
            type: 'get-by-infus'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.isParent.resepObat[k].aukpkrr_satuan = res.data.satuan
            this.isParent.resepObat[k].aukpkrr_nama = res.data.nama
            this.isParent.resepObat[k].aukpkrr_racikan = null

            this.isParent.resepObat[k].aukpkrr_dosis = null
            this.isParent.resepObat[k].aukpkrr_satuan_dosis = null
            this.isParent.resepObat[k].aukpkrr_frekuensi = null
            this.isParent.resepObat[k].aukpkrr_frekuensi_lainnya = null
            this.isParent.resepObat[k].aukpkrr_item_lainnya = null
        })
    },
    selectJumlahSatuanRacikan(e, k) {
        let data = {
            id: e,
            type: 'get-by-racikan'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            this.isParent.resepObat[k].aukpkrr_satuan = res.data.satuan
            this.isParent.resepObat[k].aukpkrr_nama = res.data.nama
            this.isParent.resepObat[k].aukpkrr_racikan = res.data.data

            this.isParent.resepObat[k].aukpkrr_dosis = null
            this.isParent.resepObat[k].aukpkrr_satuan_dosis = null
            this.isParent.resepObat[k].aukpkrr_frekuensi = null
            this.isParent.resepObat[k].aukpkrr_frekuensi_lainnya = null
            this.isParent.resepObat[k].aukpkrr_item_lainnya = null
        })
    },
    isAlergiLabel(nama) {
        let data = []
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }
        return isWarning
    },
    countLen() {
      setTimeout(() => {
          this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    toValidate(val){
      return {...val}
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
          let index = (master||[]).findIndex(x => x.value == value)
          if (index !== -1) {
              text = master[index]['text']
          }
      }
      return text
    },
    
    getObat(val){
        let index = this.isParent.mObat.findIndex(x => x.value == val)        
        if(index !== -1){
            return this.isParent.mObat[index]['text']
        }else{
            return null
        }
    },
    
    getMasterObat(data, k) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
          return k2 != k
      }), v2 => {
          return v2.value
      })
          return _.filter(this.isParent.mObat, v2 => {
          return sldMenu.indexOf(v2.value) <= -1
      })
    },

      
  },

}
</script>