<template>
	<div class="card">
		<div class="card-header bg-white border-bottom-str">
			<h6 class="card-title font-weight-semibold">Tambah Kajian Restrain</h6>
		</div>
		<validation-observer ref="VForm">
			<b-form @submit.prevent="doSubmit">
				<div class="card-body">

					<div class="row">
						<div class="col-md-6 col-lg-4">
							<div class="form-group">
								<label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
								<div class="input-group mb-3">
									<datepicker input-class="form-control transparent h-38" placeholder="Tanggal" class="my-datepicker"
										calendar-class="my-datepicker_calendar" v-model="rowData.aukpkr_date">
									</datepicker>

									<vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38"
										v-model="rowData.aukpkr_jam">
									</vue-timepicker>
									<div class="input-group-append calendar-group">
										<span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
									</div>
								</div>
								<VValidate :name="'Tanggal'" v-model="rowData.aukpkr_date" :rules="{required : 1}" class="d-block" />
								<VValidate :name="'Jam'" v-model="rowData.aukpkr_jam" :rules="{required: 1}" />
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
								</div>
								<div class="card-body p-3">
									<div class="row">
										<div class="col-md-4">
											<div class="form-group">
												<label>Tekanan Darah <span class="text-danger">*</span></label>
												<div class="input-group">
													<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_tekanan_darah_min"
														type="text" name="name" class="form-control" placeholder="Systole" />

													<div class="input-group-append input-group-prepend"><span class="input-group-text">/</span>
													</div>

													<b-form-input :formatter="number" placeholder="Diastole"
														v-model="rowData.aukpkrd_ttv_tekanan_darah_max" type="text" class="form-control" />
													<div class="input-group-append"><span class="input-group-text">mmHG</span></div>
												</div>

												<VValidate v-if="isParent.row.ap_usia > 15" name="Tekanan Darah Min"
													v-model="rowData.aukpkrd_ttv_tekanan_darah_min"
													:rules="toValidate(mrValidation.aukpkrd_ttv_tekanan_darah_min)" />
												<VValidate v-if="isParent.row.ap_usia > 15" name="Tekanan Darah Max"
													v-model="rowData.aukpkrd_ttv_tekanan_darah_max"
													:rules="toValidate(mrValidation.aukpkrd_ttv_tekanan_darah_max)" />
											</div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label>Ukuran Pupil </label>
												<div class="form-row">
													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_ukuran_pupil" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">mm/Ki</span>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="Ukuran Pupil" v-model="rowData.aukpkrd_ttv_ukuran_pupil"
													:rules="toValidate(mrValidation.aukpkrd_ttv_ukuran_pupil)" />
											</div>

										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label>Nadi <span class="text-danger">*</span></label>
												<div class="form-row">
													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_nadi" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
															<div class="input-group-append">
																<div style="width: 140px;">
																	<v-select placeholder="Pilih Label" v-model="rowData.aukpkrd_ttv_label"
																		:options="Config.mr.StatusRegular" label="text" :clearable="true"
																		:reduce="v=>v.value">
																	</v-select>
																</div>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="Nadi" v-model="rowData.aukpkrd_ttv_nadi"
													:rules="toValidate(mrValidation.aukpkrd_ttv_nadi)" />
												<VValidate name="Label" v-model="rowData.aukpkrd_ttv_label"
													:rules="toValidate(mrValidation.aukpkrd_ttv_label)" />
											</div>
										</div>

										<div class="col-md-2">
											<div class="form-group">
												<label>GDA</label>
												<div class="form-row">
													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="alphanum" v-model="rowData.aukpkrd_ttv_gula_darah" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">mg/dL</span>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="Gula Darah" v-model="rowData.aukpkrd_ttv_gula_darah"
													:rules="toValidate(mrValidation.aukpkrd_ttv_gula_darah)" />
											</div>
										</div>

										<div class="col-md-4">
											<div class="form-group">
												<label>Pernafasan<span class="text-danger">*</span></label>
												<div class="input-group">
													<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_pernafasan" type="text"
														class="form-control" />
													<div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
												</div>

												<VValidate name="Pernafasan" v-model="rowData.aukpkrd_ttv_pernafasan"
													:rules="toValidate(mrValidation.aukpkrd_ttv_pernafasan)" />
											</div>
										</div>

										<div class="col-md-3">
											<div class="form-group">
												<label>SPO2</label>
												<div class="form-row">
													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_spo2" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">%</span>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="SPO2" v-model="rowData.aukpkrd_ttv_spo2"
													:rules="toValidate(mrValidation.aukpkrd_ttv_spo2)" />
											</div>
										</div>

										<div class="col-md-2">
											<div class="form-group">
												<label>Suhu<span class="text-danger">*</span></label>
												<div class="form-row">

													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_suhu" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">C</span>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="Suhu" v-model="rowData.aukpkrd_ttv_suhu"
													:rules="toValidate(mrValidation.aukpkrd_ttv_suhu)" />
											</div>
										</div>

										<div class="col-md-2">
											<div class="form-group">
												<label>Berat Badan</label>
												<div class="form-row">

													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_weight" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">kg</span>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="Berat Badan" v-model="rowData.aukpkrd_ttv_weight"
													:rules="toValidate(mrValidation.aukpkrd_ttv_weight)" />
											</div>
										</div>


										<div class="col-md-2">
											<div class="form-group">
												<label>Tinggi</label>
												<div class="form-row">

													<div class="col-md-12">
														<div class="input-group">
															<b-form-input @input="hitungBMI()" :formatter="number"
																v-model="rowData.aukpkrd_ttv_height" type="text" class="form-control" />
															<div class="input-group-append"><span class="input-group-text">cm</span>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="Tinggi Badan" v-model="rowData.aukpkrd_ttv_height"
													:rules="toValidate(mrValidation.aukpkrd_ttv_height)" />
											</div>
										</div>

										<div class="col-md-2">
											<div class="form-group">
												<label>Lingkar Kepala</label>
												<div class="form-row">

													<div class="col-md-12">
														<div class="input-group">
															<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_lingkar_kepala"
																type="text" class="form-control" />
															<div class="input-group-append"><span class="input-group-text">cm</span>
															</div>
														</div>
													</div>
												</div>

												<VValidate name="Lingkar Kepala" v-model="rowData.aukpkrd_ttv_lingkar_kepala"
													:rules="toValidate(mrValidation.aukpkrd_ttv_lingkar_kepala)" />
											</div>
										</div>

										<div class="col-md-3">
											<div class="form-group">
												<label>BMI</label>
												<div class="form-row">
													<div class="col-md-12">
														<div class="input-group">
															<b-form-input disabled v-model="rowData.aukpkrd_ttv_bmi" type="text"
																class="form-control" />
															<div class="input-group-append"><span class="input-group-text">m2</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-3" v-if="isParent.row.ap_usia <= 15">
											<div class="form-group">
												<label>Luas Permukaan Tubuh Anak</label>
												<div class="input-group">
													<b-form-input :formatter="number" v-model="rowData.aukpkrd_ttv_luas_permukaan_anak"
														type="text" class="form-control" />
													<div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-4 mt-2">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Hasil Observasi</h6>
								</div>
								<div class="card-body p-3">
									<div class="row">
										<div class="col-md-12">
											<b-form-group>
												<b-form-checkbox-group class="checkbox-block" v-model="rowData.aukpkrd_hasil_observasi"
													:options="Config.mr.ranapResHasilObservasi" name="hasil_observasi">
												</b-form-checkbox-group>
											</b-form-group>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-4 mt-2">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Pertimbangan Klinis</h6>
								</div>
								<div class="card-body p-3">
									<div class="row">
										<b-form-group>
											<b-form-checkbox-group class="checkbox-block" v-model="rowData.aukpkrd_pertimbangan_klinis"
												:options="Config.mr.ranapResPertimbanganKlinis" name="hasil_klinis">
											</b-form-checkbox-group>
										</b-form-group>
									</div>
								</div>
							</div>
						</div>

						<div class="col-4 mt-2">
							<div class="card mb-0">
								<div class="card-header bg_head_panel">
									<h6 class="card-title font-weight-semibold">Penilaian dan Order Dokter</h6>
								</div>
								<div class="card-body p-3">
									<div class="row">
										<div class="col-md-12">
											<b-form-group>
												<b-form-checkbox-group class="checkbox-block" v-model="rowData.aukpkrd_order_dokter"
													:options="Config.mr.ranapResPenilaianOrder" name="hasil_penilaian">
												</b-form-checkbox-group>
											</b-form-group>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-12">
							<div class="card">
								<div class="card-header bg_head_panel">
									<h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
								</div>
								<div class="card-body mt-2">
									<table class="table table-bordered table-hover">
										<thead>
											<tr>
												<th>Pengkajian</th>
												<th>Nilai</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span>
												</td>

												<!-- ele pertama hardcode -->
												<td>
													<b-form-radio v-model="rowData.aukpkrd_response_mata" name="aukkp_tingkat_kesadaran_mata"
														:value="4">Dapat Membuka
														Mata Secara Spontan <span class="text-blue-800 font-weight-semibold">(4)</span>
													</b-form-radio>
												</td>

											</tr>
											<template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
												<tr v-if="k != 0" :key="k">
													<td>
														<b-form-radio v-model="rowData.aukpkrd_response_mata"
															name="aukkp_tingkat_kesadaran_mata" :value="v.value">
															{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span>
														</b-form-radio>
													</td>
												</tr>
											</template>
										</tbody>
										<tbody>
											<tr>
												<td class="table-warning" rowspan="5"><span
                                                        class="font-weight-semibold">Respons Verbal</span></td>

                                                <td>
                                                    <b-form-radio v-model="rowData.aukpkrd_response_verbal"
                                                        name="aukkp_tingkat_kesadaran_verbal" :value="5">Pasien Sadar
                                                        dan Merespons Pertanyaan dengan Benar<span
                                                            class="text-blue-800 font-weight-semibold">(5)</span>
                                                    </b-form-radio>
                                                </td>

                                            </tr>
                                            <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                                <tr v-if="k != 0" :key="k">
                                                    <td>
                                                        <b-form-radio v-model="rowData.aukpkrd_response_verbal"
                                                            name="aukkp_tingkat_kesadaran_verbal" :value="v.value">
                                                            {{v.text}}<span
                                                                class="text-blue-800 font-weight-semibold">({{v.value}})</span>
                                                        </b-form-radio>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                        <tbody>
                                            <tr>
                                                <td class="table-warning" rowspan="6"><span
                                                        class="font-weight-semibold">Respons Motorik</span></td>

                                                <td>
                                                    <b-form-radio v-model="rowData.aukpkrd_response_motorik"
                                                        name="aukkp_tingkat_kesadaran_motorik" :value="6">Pasien Dapat
                                                        Melakukan Gerakan Sesuai Perintah<span
                                                            class="text-blue-800 font-weight-semibold">(6)</span>
                                                    </b-form-radio>
                                                </td>

                                            </tr>
                                            <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                                <tr v-if="k != 0" :key="k">
                                                    <td>
                                                        <b-form-radio v-model="rowData.aukpkrd_response_motorik"
                                                            name="aukkp_tingkat_kesadaran_motorik" :value="v.value">
                                                            {{v.text}}<span
                                                                class="text-blue-800 font-weight-semibold">({{v.value}})</span>
                                                        </b-form-radio>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td class="table-info" colspan="9">
                                                    <span class="font-weight-semibold">Nilai GCS:
                                                        {{tingkatKesadaranTotal}}</span>
                                                    <span v-if="tingkatKesadaranTotal >= 14"
                                                        class="font-weight-semibold border-left ml-2 pl-2">
                                                        Composmentis</span>
                                                    <span
                                                        v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13"
                                                        class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                                    <span
                                                        v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11"
                                                        class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                                    <span
                                                        v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9"
                                                        class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                                    <span
                                                        v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6"
                                                        class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                                    <span
                                                        v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4"
                                                        class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                                    <span v-else
                                                        class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body table-success">
                                    <b-form-checkbox v-model="rowData.aukpkrd_is_kel_restrain" value="Y"
                                        :unchecked-value="null">
                                        Keluarga sudah dijelaskan tentang restrain
                                    </b-form-checkbox>
                                    <VValidate name="DiJelaskan Restrain" v-model="rowData.aukpkrd_is_kel_restrain"
                                        :rules="{required: 1}" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mb-0">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Tindakan Lanjutan</h6>
                    </div>
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-md-5">
                                <label for="">Jadwal Monitoring <span class="text-danger">*</span></label>
                                <b-form-radio-group :options="Config.mr.lanjutanMonitoring"
                                    v-model="rowData.aukpkr_is_next_monitoring" />
                                <VValidate :name="'Jadwal Monitoring'" v-model="rowData.aukpkr_is_next_monitoring"
                                    :rules="{required: 1}" />
                            </div>

                            <div class="col-md-5" v-if="rowData.aukpkr_is_next_monitoring == 'Y'">
                                <label>Interval Waktu<small class="txt_mandatory">*</small></label>
                                <div class="form-row">
                                    <div class="col-lg-5">
                                        <v-select placeholder="-- Pilih Salah Satu -- "
                                            v-model="rowData.aukpkrd_label"
                                            :options="isParent.Config.mr.waktuKajianInterval" label="text"
                                            :clearable="true" :reduce="v=>v.value"></v-select>

                                        <VValidate :name="'Interval Waktu'" v-model="rowData.aukpkrd_label"
                                            :rules="toValidate(mrValidation.aukpkrd_label)" class="d-block" />
                                    </div>
                                    <div class="col-lg-7" v-if="rowData.aukpkrd_label == 999999">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <b-form-input :formatter="numberOnly" v-model="rowData.aukpkrd_waktu"
                                                    type="text" class="form-control" />
                                                <div class="input-group-append">
                                                    <div style="width: 120px;">
                                                        <v-select placeholder="-- Pilih -- "
                                                            v-model="rowData.aukpkrd_waktu_label"
                                                            :options="isParent.Config.mr.ketWaktuInterval" label="text"
                                                            :clearable="true" :reduce="v=>v.value"></v-select>
                                                    </div>
                                                </div>

                                                <VValidate :name="'Interval Waktu Lainnya'"
                                                    v-model="rowData.aukpkrd_waktu"
                                                    :rules="toValidate(mrValidation.aukpkrd_waktu)" class="d-block" />

                                                <VValidate :name="'Keterangan Waktu'"
                                                    v-model="rowData.aukpkrd_waktu_label"
                                                    :rules="toValidate(mrValidation.aukpkrd_waktu_label)"
                                                    class="d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="text-right">
                        <button type="button" @click="back()" class="btn btn-light mr-2">Back</button>
                        <button type="button" class="btn btn-info mr-2" @click="doSave()">Simpan Sementara <i
                                class="icon-paperplane mr-2"></i></button>
                        <button type="submit" class="btn btn-primary">Submit <i
                                class="icon-paperplane mr-2"></i></button>
                    </div>
                </div>

            </b-form>
        </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
  extends: GlobalVue,
  components: {Datepicker,VueTimepicker}, 
  computed: {
    isParent(){
      return this.$parent
    },
    tingkatKesadaranTotal(){
        return (this.rowData.aukpkrd_response_mata||0) + 
        (this.rowData.aukpkrd_response_verbal||0) + 
        (this.rowData.aukpkrd_response_motorik||0)
    },
    modulePage(){
      return 'UGDKajianRestrain' 
    },
  },
  data() {
    return {
      rowData: {},
      isMonitoring: false,
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    
    hitungBMI(){
      if(this.rowData.aukpkrd_ttv_weight && this.rowData.aukpkrd_ttv_height){
        this.rowData.aukpkrd_ttv_bmi = this.rowData.aukpkrd_ttv_weight / ((this.rowData.aukpkrd_ttv_height/100)*(this.rowData.aukpkrd_ttv_height/100))
        this.rowData.aukpkrd_ttv_bmi = this.rowData.aukpkrd_ttv_bmi.toFixed(2) 
      }else{
        this.rowData.aukpkrd_ttv_bmi = null 
      }
    },
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.query.kajianNo
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.query.kajianNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

    doSave(){
      let data = this.rowData
      data.aukpkr_updated_by = this.user.id
      data.aukpkr_updated_date = moment().format("YYYY-MM-DD HH:mm:ss")
      data.type = 'auto-save'

      this.loadingOverlay = true

      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.loadingOverlay = false
        
        let resp = res.data
        resp.statusType = 200
        this.$swal({
            title: "Data Berhasil Disimpan",
            icon: "success",
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            this.$router.push({ 
                name: 'UGDTindakLanjut', 
                params: {pageSlug: this.pageSlug},
                query: {regId: this.pageSlug, isRestrain: true}
            }).catch(()=>{}) 
        })
      })
    },

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            text: "Pastikan Jadwal Monitoring yang diiisi telah sesuai",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data'
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                        this.$router.push({ 
                            name: 'UGDTindakLanjut', 
                            params: {pageSlug: this.pageSlug},
                            query: {regId: this.pageSlug, isRestrain: true}
                        }).catch(()=>{}) 
                  }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                  })
              })
            }
          })
        }
      })
    }
  },
  mounted() {
    this.apiGetForm()
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  
    tingkatKesadaranTotal(v){
        setTimeout(()=>{
            this.rowData.aukpkrd_response_gcs = v
        },250)
    },
  }
}

</script>