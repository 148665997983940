<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row" v-if="!rowPemaparanRM.ausipr_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowPemaparanRM['ausipr_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowPemaparanRM.ausipr_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowPemaparanRM.ausipr_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="form-group">
                <label for="">Tanda Tangan Pembuat Pernyataan</label>
                <div class="signing-element" data-target-id="ttdDokter">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdDokter"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdDokter','ausipr_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdDokter','ausipr_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>


                    </button>
                    </div>
                </div>
                </div>
                
                <template v-if="rowPemaparanRM.ausipr_created_on == 'PJ'">
                <div class="form-group">
                    <label for="">Nama Pembuat Pernyataan<strong class="text-danger">*</strong></label>
                    <b-form-input type="text" class="form-control" v-model="rowPemaparanRM.ausipr_nama_pembuat_pernyataan" placeholder="e.g Bambang" />
                    
                    <VValidate 
                      name="Nama Pembuat Pernyataan" 
                      v-model="rowPemaparanRM.ausipr_nama_pembuat_pernyataan" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                </div>
                
                <div class="form-group">
                  <label for="">Tanggal Lahir<strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <div class="input-group mb-3">
                      <datepicker 
                        input-class="form-control transparent"
                        placeholder="Tanggal Lahir" class="my-datepicker"
                        calendar-class="my-datepicker_calendar"
                        v-model="rowPemaparanRM.auispr_tanggal"
                        >
                      </datepicker>
                      <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i
                            class="icon-calendar"></i></span>
                      </div>
                    </div>
                  </div>
                  <VValidate 
                    name="Tanggal Lahir" 
                    v-model="rowPemaparanRM.auispr_tanggal" 
                    :rules="{required: 1}"
                />
                </div>
                
                <div class="form-group">
                  <label for="">Jenis Kelamin<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-radio-group
                      :options="Config.mr.StatusKelamin"
                      v-model="rowPemaparanRM['auispr_jenis_kelamin']"
                    />
                    <VValidate 
                      name="Jenis Kelamin" 
                      v-model="rowPemaparanRM.auispr_jenis_kelamin" 
                      :rules="{required: 1}"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Pekerjaan<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-input type="text" class="form-control" v-model="rowPemaparanRM.auispr_pekerjaan" placeholder="Pekerjaan"/>
                    <VValidate 
                      name="Pekerjaan" 
                      v-model="rowPemaparanRM.auispr_pekerjaan" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                </div>
                
                <div class="form-group">
                  <label for="">No Identitas<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-input type="text" class="form-control" v-model="rowPemaparanRM.auispr_no_identitas" placeholder="No Identitas"/>
                    <VValidate 
                      name="No Identitas" 
                      v-model="rowPemaparanRM.auispr_no_identitas" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Alamat<strong class="text-danger">*</strong></label>
                  <div class="mb-2">
                    <b-form-textarea type="text" class="form-control" v-model="rowPemaparanRM.auispr_alamat" placeholder="Alamat"/>
                    <VValidate 
                      name="Alamat" 
                      v-model="rowPemaparanRM.auispr_alamat" 
                      :rules="{required: 1, min:3, max: 128}"
                    />
                  </div>
                </div>
                </template>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                <div class="df-content">
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                    <div style="padding: 8px;border: 8px double #000;">
                        <table style="border-collapse: collapse;width: 100%;font-size: 13px;">
                        <tbody><tr>
                            <th colspan="3" style="padding: 8px;border: 1px solid #000;">
                            <h2 style="font-size: 16px;text-transform: uppercase;margin: 0;display: inline-block;">Surat Izin Pemaparan Isi Rekam Medis</h2>
                            </th>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding: 4px 8px;padding-top: 16px;text-align: justify;">
                            
                            <template v-if="rowPemaparanRM.ausipr_created_on == 'P'">
                                <p style="margin-top: 0;margin-bottom: 8px;">Saya yang bertanda tangan dibawah ini:</p>
                                <div style="padding-left: 16px;">
                                    <table style="border-collapse: collapse;font-size: 13px;" border="0">
                                    
                                    <tbody><tr>
                                        <td style="padding: 2px 8px">Nama</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPasien.ap_fullname||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Tgl. Lahir</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">
                                        <span style="margin-left: 16px;">Sex: {{rowPasien.ap_gender == 1 ? 'L' : 'P'}}</span>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Pekerjaan</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPasien.ap_pekerjaan_text||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Alamat</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPasien.ap_address||"-"}}</td>
                                    </tr>
                                    </tbody>

                                    </table>
                                </div>
                            </template>

                            <template v-else-if="rowPemaparanRM.ausipr_created_on == 'PJ'">
                                <p style="margin-top: 0;margin-bottom: 8px;">Saya yang bertanda tangan dibawah ini:</p>
                                <div style="padding-left: 16px;">
                                    <table style="border-collapse: collapse;font-size: 13px;">
                                    <tbody><tr>
                                        <td style="padding: 2px 8px">Nama</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPemaparanRM.ausipr_nama_pembuat_pernyataan||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Tgl. Lahir</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">
                                        <span>{{rowPemaparanRM.auispr_tanggal | moment('DD MMMM YYYY')}}</span>
                                        <span style="margin-left: 16px;">Umur: {{rowPemaparanRM.auispr_tanggal | moment("from", "now", true)}}</span>
                                        <span style="margin-left: 16px;">Sex: {{getConfigDynamic(Config.mr.StatusKelamin,rowPemaparanRM.auispr_jenis_kelamin)||"-"}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Pekerjaan</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPemaparanRM.auispr_pekerjaan||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">No. Identitas</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPemaparanRM.auispr_no_identitas||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Alamat</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPemaparanRM.auispr_alamat||"-"}}</td>
                                    </tr>
                                    </tbody>
                                    </table>
                                </div>
                                
                                <p style="margin-top: 8px;margin-bottom: 8px;">adalah {{rowPasien.arpj_hubungan_keluarga_text||"Penanggung jawab"}}:</p>
                                <div style="padding-left: 16px;">
                                    <table style="border-collapse: collapse;font-size: 13px;" border="0">
                                    <tbody><tr>
                                        <td style="padding: 2px 8px">Nama</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPasien.ap_fullname||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Tgl. Lahir</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">

                                        <span v-if="rowPasien.ap_dob">{{rowPasien.ap_dob | moment('DD MMMM YYYY')}}</span>
                                        <span v-else> - </span>

                                        <span style="margin-left: 16px;">Umur: {{rowPasien.ap_dob | moment("from", "now", true)}}</span>

                                        <span style="margin-left: 16px;">Sex: {{getConfigDynamic(Config.mr.StatusKelamin,rowPasien.ap_usia)||"-"}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Pekerjaan</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPasien.mp_name||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">No. Identitas</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPasien.ap_nik||"-"}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 2px 8px">Alamat</td>
                                        <td>:</td>
                                        <td style="padding: 2px 8px">{{rowPasien.ap_address||"-"}}</td>
                                    </tr>
                                    </tbody></table>
                                </div>
                            </template>

                            <!-- End Jika diisi bukan oleh pasien -->
                            <p style="margin-top: 16px;text-indent: 24px;margin-bottom: 8px;">
                                <!-- Uncomment part bawah jika diisi oleh pasien -->
                                <!-- <span>Adalah pasien </span> -->
                                <span v-if="rowPemaparanRM.ausipr_tanggal_rawat_start && rowPemaparanRM.ausipr_tanggal_rawat_end">Yang dirawat di RSH Harapan dari tanggal 
                                
                                <span v-if="rowPemaparanRM.ausipr_tanggal_rawat_start">{{rowPemaparanRM.ausipr_tanggal_rawat_start | moment("DD MMMM YYYY") }}</span>
                                <span v-else> - </span>

                                sampai dengan tanggal 
                                <span v-if="rowPemaparanRM.ausipr_tanggal_rawat_end">
                                    {{rowPemaparanRM.ausipr_tanggal_rawat_end | moment("DD MMMM YYYY") }} 
                                </span>
                                <span v-else> - </span>

                                </span>. Saya memberi izin kepada Dokter RS Harapan yang telah merawat saya/penderita untuk memberikan keterang tertulis mengenai rahasia medis selama saya/penderita dirawat di RS Harapan guna keperluan: {{rowPemaparanRM.ausipr_keperluan||"-"}}
                            </p>
                            <p style="margin-top: 0;text-indent: 24px;margin-bottom: 16px;">Saya bertanggungjawab dan menyatakan tidak akan menuntut Dokter RS Harapan atas akibat yang ditimbulkan dari pemaparan kerahasiaan medis tersebut. Demikian surat izin ini saya buat dengan kesadaran penuh tanpa paksaan dari pihak manapun.</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="60%" style="vertical-align: bottom;font-size: 11px;">
                            </td>
                            <td>
                            <span>Magelang,<br>Yang membuat pernyataan, {{now}}</span>
                            
                            <span v-if="rowPemaparanRM.ausipr_is_ttd == 'Digital'">
                            <img :src="rowPemaparanRM.ausipr_ttd ? rowPemaparanRM.ausipr_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                            <br/>
                            </span>
                            <span v-else>
                            <br/>
                            <br/>
                            <br/>
                            <br/>                          
                            </span>
                            ({{rowPemaparanRM.ausipr_ttd ? (rowPemaparanRM.ausipr_created_on == 'P' ? rowPasien.ap_fullname : rowPemaparanRM.ausipr_nama_pembuat_pernyataan) : "-"}})
                            </td>
                        </tr>
                        </tbody></table>
                    </div>
                    </div>

                    <!--
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                    <div style="padding: 24px;box-sizing: border-box;">
                        <table style="border-collapse: collapse;width: 100%;font-size: 14px;line-height: 1.5;">
                        <tbody><tr>
                            <th colspan="3" style="padding: 8px 54px;">
                            <div style="text-align: center;">
                                <div style="width: 20%;float: left;line-height: 68px;box-sizing: border-box;padding-right: 16px;">
                                <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                </div>
                                <div style="float: left;text-align: center;width: 80%;border-bottom: 1px solid #000;">
                                <h2 style="margin: 0;font-size: 20px;text-transform: uppercase;line-height: 22px;margin-bottom: 2px;">RS Harapan Magelang</h2>
                                <div><strong>Jl. Panembahan Senopati No. 11 Magelang 56123</strong></div>
                                <div><strong>Telp. (0293) 364033 - 364035</strong></div>
                                </div>
                                <div style="clear: both;"></div>
                            </div>
                            </th>
                        </tr>
                        <tr>
                            <th colspan="3" style="padding: 8px;padding-top: 16px;">
                            <h2 style="font-size: 16px;text-transform: uppercase;margin: 0;display: inline-block;">Surat Keterangan Sehat Dokter</h2>
                            </th>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding: 4px 8px;padding-top: 8px;text-align: justify;">
                            <p style="margin-top: 0;margin-bottom: 8px;">Yang bertanda tangan dibawah ini menerangkan bahwa:</p>
                            <div style="padding-left: 16px;">
                                <table style="border-collapse: collapse;font-size: 14px;line-height: 1.5;">
                                <tbody><tr>
                                    <td style="padding: 2px 8px">Nama</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_fullname||"-"}}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Tempat, Tgl. Lahir</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_pob}}, {{rowPasien.ap_dob | moment("DD MMMM YYYY") }}</td>
                                </tr>
                                <tr>
                                    <td style="vertical-align: top;padding: 2px 8px">Tanda-tanda Vital</td>
                                    <td style="vertical-align: top;">:</td>
                                    <td style="padding: 2px 8px">
                                    <div>
                                        <span style="margin-right: 12px;">TB: 
                                            <span v-if="rowData.autl_ttv_height">
                                                {{rowData.autl_ttv_height||"-"}}cm
                                            </span>
                                            <span v-else> - </span>
                                        </span>
                                        <span style="margin-right: 12px;">BB: 
                                            <span v-if="rowData.autl_ttv_weight">
                                                {{rowData.autl_ttv_weight||"-"}}kg
                                            </span>
                                            <span v-else> - </span>
                                        </span>
                                        <span style="margin-right: 12px;">TD: 
                                        <span v-if="rowData.autl_ttv_tekanan_darah_min && rowData.autl_ttv_tekanan_darah_max">
                                            {{rowData.autl_ttv_tekanan_darah_min||"-"}}/
                                            {{rowData.autl_ttv_tekanan_darah_max||"-"}} mmHG
                                        </span>
                                        <span v-else> - </span>
                                        </span>
                                    </div>
                                    <div>
                                        <span style="margin-right: 12px;">N: 
                                            <span v-if="rowData.autl_ttv_nadi">
                                                {{rowData.autl_ttv_nadi||"-"}}x/mnt
                                            </span>
                                            <span v-else> - </span>
                                        </span> 
                                        <span style="margin-right: 12px;">S: 
                                            <span v-if="rowData.autl_ttv_nadi">
                                                {{rowData.autl_ttv_suhu||"-"}}%
                                            </span>
                                            <span v-else> - </span>
                                        </span>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Alamat</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_address||"-"}}</td>
                                </tr>
                                </tbody></table>
                            </div>
                            <p style="margin-top: 8px;margin-bottom: 8px;">Pada pemeriksaan jasmani saat ini dalam keadaan sehat</p>
                            
                            <template>
                            <p style="margin-top: 16px;margin-bottom: 8px;">Surat keterangan ini digunakan untuk:</p>
                            <ol style="padding-left: 16px;margin: 0 0 8px;">
                                <li>Pendaftaran akademi</li>
                            </ol>
                            </template>
                            
                            <p style="margin-top: 0;margin-bottom: 16px;">Harap yang berkepentingan maklum.</p>
                            </td>
                        </tr>
                        <tr>
                            <td width="60%" style="vertical-align: bottom;font-size: 11px;">
                            </td>
                            <td>
                            <span>Magelang, <br>Dokter Pemeriksa</span>
                            <img :src="rowPemaparanRM.ausipr_ttd ? rowPemaparanRM.ausipr_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                            <br/>
                            ({{rowReg.bu_full_name||"-"}})
                            </td>
                        </tr>
                        </tbody></table>
                    </div>
                    </div>
                    -->
                    <!-- 	Surat Keterangan Sehat Dokter --> 
                </div>
                </div>
            </div>
        </div>
        
        <div :class="rowPemaparanRM.ausipr_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    components: {
      Datepicker
    },
    data(){
        return {
            rowPemaparanRM: {},
            rowPasien: {},
            rowData: {},
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        getConfigDynamic(master, value) {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index]['text']
                }
            }
            return text
        },
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowPemaparanRM[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowPemaparanRM[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success && this.rowPemaparanRM.ausipr_is_ttd == 'Digital') {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowPemaparanRM.ausipr_is_ttd == 'Digital'){
                    if(!this.rowPemaparanRM.ausipr_ttd){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }



                if (success && this.rowPemaparanRM.ausipr_is_ttd == 'Digital') {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            this.rowPemaparanRM.ausipr_template = $(".document-framer").html()

                            let data = this.rowPemaparanRM
                            data.type = 'submit-data-ttd-pemaparan-rm'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDPemaparanRM(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-pemaparan-rm',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDPemaparanRM()
        setTimeout(()=>{
            if(this.$refs['ttdDokter']){
                this.$refs['ttdDokter'].fromDataURL(this.rowPemaparanRM.ausipr_ttd)
            }
        },1500)
    },
}      
</script>
