<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{rowReg.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                        title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong
                                        class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong
                                        class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>

                      
                        <div class="d-flex flex-column">
                            <a href="javascript:;" @click="toDetailKajianPerawatUGD()" data-toggle="modal" data-target="#modalKajianAwalPerawat" class="btn btn-outline-light">Lihat Kajian Perawat</a>
                            <a href="javascript:;" v-if="row.have_rajal" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1" @click="doOpenRiwayatRMV2(row.kajianRajal)">Lihat Riwayat Rekam Medis Rajal</a>
                            <a href="javascript:;" @click="doOpenRiwayatRMUGD(row)" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis UGD</a>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
                <i class="icon-arrow-up5 align-middle"></i>
                <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <div class="card mt-2">
                <div class="card-header bg-info">
                    <h5 class="card-title font-weight-semibold">
                    {{!isDetailKeluarUGD ? 'Kajian Keluar UGD': 'Dokumen Pasien UGD'}}
                    </h5>
                </div>
                <template v-if="!isDetailKeluarUGD">
                    <validation-observer ref="VForm">
                        <b-form @submit.prevent="doSubmit" class="card">
                        <div class="card-body">
                            <div class="card border shadow-0">
                                <div class="card-header bg_head_panel">
                                    <h5 class="card-title font-weight-semibold">Kondisi Pasien Keluar dari UGD</h5>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-group">
                                        <label for="ugdGeneral">Kondisi Umum<strong
                                                class="text-danger">*</strong></label>
                                        <textarea v-model="row.autl_kondisi_umum" name="ugdGeneral" id="ugdGeneral"
                                            rows="4" class="form-control"
                                            placeholder="Kondisi Umum Pasien setelah Keluar dari UGD"></textarea>
                                        <VValidate name="Kondisi Umum" v-model="row.autl_kondisi_umum"
                                            :rules="toValidate(mrValidation.autl_kondisi_umum)" />
                                    </div>

                                    <div class="card mb-0">
                                        <div class="card-header bg_head_panel">
                                            <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Tekanan Darah</label>
                                                        <div class="input-group">
                                                            <b-form-input :formatter="$parent.number"
                                                                v-model="row.autl_ttv_tekanan_darah_min" type="text"
                                                                name="name" class="form-control"
                                                                placeholder="Systole" />

                                                            <div class="input-group-append input-group-prepend">
                                                                <span class="input-group-text">/</span>
                                                            </div>

                                                            <b-form-input :formatter="$parent.number"
                                                                placeholder="Diastole"
                                                                v-model="row.autl_ttv_tekanan_darah_max" type="text"
                                                                class="form-control" />
                                                            <div class="input-group-append"><span
                                                                    class="input-group-text">mmHG</span>
                                                            </div>
                                                        </div>
                                                        <VValidate name="Tekanan Darah Min"
                                                            v-model="row.autl_ttv_tekanan_darah_min"
                                                            :rules="toValidate(mrValidation.autl_ttv_tekanan_darah_min)" />
                                                        <VValidate name="Tekanan Darah Max"
                                                            v-model="row.autl_ttv_tekanan_darah_max"
                                                            :rules="toValidate(mrValidation.autl_ttv_tekanan_darah_max)" />
                                                    </div>
                                                </div>

                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label>Nadi</label>
                                                        <div class="form-row">
                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input :formatter="$parent.number"
                                                                        v-model="row.autl_ttv_nadi" type="text"
                                                                        class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">x/mnt</span>
                                                                    </div>
                                                                    <div class="input-group-append">
                                                                        <div style="width: 140px;">
                                                                            <v-select placeholder="Pilih Label"
                                                                                v-model="row.autl_ttv_label"
                                                                                :options="Config.mr.StatusRegular"
                                                                                label="text" :clearable="true"
                                                                                :reduce="v=>v.value"></v-select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VValidate name="Nadi" v-model="row.autl_ttv_nadi"
                                                            :rules="toValidate(mrValidation.autl_ttv_nadi)" />

                                                        <VValidate name="Label" v-model="row.autl_ttv_label"
                                                            :rules="toValidate(mrValidation.autl_ttv_label)" />
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Gula Darah</label>
                                                        <div class="form-row">
                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input :formatter="$parent.alphanum"
                                                                        v-model="row.autl_ttv_gula_darah" type="text"
                                                                        class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">mg/dL</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <VValidate name="Gula Darah" v-model="row.autl_ttv_gula_darah"
                                                            :rules="toValidate(mrValidation.autl_ttv_gula_darah)" />
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Pernafasan</label>
                                                        <div class="input-group">
                                                            <b-form-input :formatter="$parent.number"
                                                                v-model="row.autl_ttv_pernafasan" type="text"
                                                                class="form-control" />
                                                            <div class="input-group-append"><span
                                                                    class="input-group-text">x/mnt</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <VValidate name="Pernafasan" v-model="row.autl_ttv_pernafasan"
                                                        :rules="toValidate(mrValidation.autl_ttv_pernafasan)" />
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>SPO2</label>
                                                        <div class="form-row">
                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input :formatter="$parent.number"
                                                                        v-model="row.autl_ttv_spo2" type="text"
                                                                        class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">%</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <VValidate name="SPO2" v-model="row.autl_ttv_spo2"
                                                            :rules="toValidate(mrValidation.autl_ttv_spo2)" />
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label>Suhu</label>
                                                        <div class="form-row">

                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input :formatter="$parent.number"
                                                                        v-model="row.autl_ttv_suhu" type="text"
                                                                        class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">C</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VValidate name="Suhu" v-model="row.autl_ttv_suhu"
                                                            :rules="toValidate(mrValidation.autl_ttv_suhu)" />
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label>Berat Badan</label>
                                                        <div class="form-row">

                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input @input="hitungBMI()"
                                                                        :formatter="$parent.number"
                                                                        v-model="row.autl_ttv_weight" type="text"
                                                                        class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">kg</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VValidate name="Berat Badan" v-model="row.autl_ttv_weight"
                                                            :rules="toValidate(mrValidation.autl_ttv_weight)" />
                                                    </div>
                                                </div>


                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label>Tinggi</label>
                                                        <div class="form-row">

                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input @input="hitungBMI()"
                                                                        :formatter="$parent.number"
                                                                        v-model="row.autl_ttv_height" type="text"
                                                                        class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">cm</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <VValidate name="Tinggi Badan" v-model="row.autl_ttv_height"
                                                            :rules="toValidate(mrValidation.autl_ttv_height)" />
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Lingkar Kepala</label>
                                                        <div class="form-row">

                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input :formatter="$parent.number"
                                                                        v-model="row.autl_ttv_lingkar_kepala"
                                                                        type="text" class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">cm</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VValidate name="Lingkar Kepala"
                                                            v-model="row.autl_ttv_lingkar_kepala"
                                                            :rules="toValidate(mrValidation.autl_ttv_lingkar_kepala)" />
                                                    </div>
                                                </div>

                                                <div class="col-md-3" v-if="row.ap_usia <= 15">
                                                    <div class="form-group">
                                                        <label>Luas Permukaan Tubuh Anak</label>
                                                        <div class="input-group">
                                                            <b-form-input :formatter="$parent.number"
                                                                v-model="row.autl_ttv_luas_permukaan_anak" type="text"
                                                                class="form-control" />
                                                            <div class="input-group-append"><span
                                                                    class="input-group-text">m<sup>2</sup></span></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3" v-if="row.ap_usia >= 1">
                                                    <div class="form-group">
                                                        <label>BMI</label>
                                                        <div class="form-row">
                                                            <div class="col-md-12">
                                                                <div class="input-group">
                                                                    <b-form-input disabled v-model="row.autl_ttv_bmi"
                                                                        type="text" class="form-control" />
                                                                    <div class="input-group-append">
                                                                        <span class="input-group-text">m2</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <VValidate name="BMI" v-model="row.autl_ttv_bmi"
                                                            :rules="toValidate(mrValidation.autl_ttv_bmi)" />
                                                    </div>
                                                </div>

                                                <!--
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>IMT</label>
                                                        <div class="col-md-12">
                                                            <b-form-input v-model="row.autl_ttv_imt" type="text"
                                                            class="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                -->

                                                <!--
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Kesadaran</label>
                                                        <div class="form-row">
                                                            <div class="col-md-12">
                                                                <v-select placeholder="Pilih Kesadaran"
                                                                    v-model="row.autl_ttv_kesadaran"
                                                                    :options="mKesadaran" label="text" :clearable="true"
                                                                    :reduce="v=>v.value">
                                                                    <template slot="selected-option"
                                                                        slot-scope="option">
                                                                        <span v-b-tooltip.hover
                                                                            :title="option.text">{{ option.text }}</span>
                                                                    </template>
                                                                    <template slot="option" slot-scope="option">
                                                                        <span v-b-tooltip.hover
                                                                            :title="option.text">{{ option.text }}</span>
                                                                    </template>
                                                                </v-select>
                                                            </div>
                                                        </div>
                                                        <VValidate name="Kesadaran" v-model="row.autl_ttv_kesadaran"
                                                            :rules="toValidate(mrValidation.autl_ttv_kesadaran)" />
                                                    </div>
                                                </div>
                                                -->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card border shadow-0" v-if="((rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1) || ((rowReg.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && (rowReg.aur_tindak_lanjut_rajal == 'KM' || rowReg.aur_tindak_lanjut_rajal == 'MU'))">
                                <div class="card-header bg_head_panel">
                                    <h5 class="card-title font-weight-semibold">Tindak Lanjut</h5>
                                </div>
                                <div class="card-body p-3">
                                    <!-- Apabila pasien rawat inap / partus -->
                                    <template v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="">Pasien Masuk ke Ruangan <strong
                                                        class="text-danger">*</strong></label>
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.pasienMasukKeRuangan"
                                                        v-model="row.autl_pasien_masuk_ruangan" />
                                                    <VValidate name="Pasien Ke Ruangan" v-model="row.autl_ttv_kesadaran"
                                                        :rules="toValidate(mrValidation.autl_ttv_kesadaran)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </template>
                                    
                                    <!-- End -->
                                    <div class="row">
                                        <template v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="ranapRuang">Masuk Ruang Rawat Inap <strong
                                                        class="text-danger">*</strong></label>
                                                <b-form-input v-model="row.autl_masuk_ranap" name="ranapRuang"
                                                    id="ranapRuang" class="form-control" />

                                                <VValidate name="Masuk Rawat Inap" v-model="row.autl_masuk_ranap"
                                                    :rules="toValidate(mrValidation.autl_masuk_ranap)" />
                                            </div>
                                        </div>
                                        </template>

                                        <template v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && (rowReg.aur_tindak_lanjut_rajal == 'KM' || rowReg.aur_tindak_lanjut_rajal == 'MU')">
                                        
                                        <div class="col-md-4 col-lg-3 col-xl-2">
                                            <div class="form-group">
                                                <label for="ranapDipulangkan">Dipulangkan Pukul <strong
                                                        class="text-danger">*</strong></label>
                                                <vue-timepicker manual-input format="HH:mm" input-class="form-control custom-timepicker"
                                                    v-model="row.autl_dipulangkan_pukul">
                                                </vue-timepicker>

                                                <VValidate name="Dipulangkan Pukul" v-model="row.autl_dipulangkan_pukul"
                                                    :rules="toValidate(mrValidation.autl_dipulangkan_pukul)" />

                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Transportasi Pulang <strong
                                                        class="text-danger">*</strong></label>
                                                <v-select placeholder="Pilih Item" v-model="row.autl_transportasi"
                                                    :options="Config.mr.transportasiPulangUGD" label="text"
                                                    :reduce="v=>v.value">
                                                </v-select>
                                                <VValidate name="Transportasi Pulang" v-model="row.autl_transportasi"
                                                    :rules="toValidate(mrValidation.autl_transportasi)" />
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="">Pendidikan Kesehatan saat Pasien Pulang</label>

                                                <v-select multiple class="vs__multiple" placeholder="Pilih Item" v-model="row.autl_pendidikan_pasien"
                                                    :options="Config.mr.pendidikanPasienPulang" label="text"
                                                    :reduce="v=>v.value">
                                                </v-select>

                                                <VValidate name="Pendidikan Kesehatan" v-model="row.autl_pendidikan_pasien"
                                                    :rules="toValidate(mrValidation.autl_pendidikan_pasien)" />

                                            </div>
                                        </div>
                                        <div class="col-md-3" v-if="((row.autl_pendidikan_pasien||[]).indexOf('Lainnya') != -1)">
                                            <div class="form-group">
                                                <label for="">Pendidikan Kesehatan saat Pasien Pulang</label>
                                                <b-form-input :formatter="$parent.normalText"
                                                    v-model="row.autl_pendidikan_pasien_text" type="text" name="name"
                                                    class="form-control" />
                                                <VValidate name="Pendidikan Kesehatan Lainnya"
                                                    v-model="row.autl_pendidikan_pasien_text"
                                                    :rules="toValidate(mrValidation.autl_pendidikan_pasien_text)" />

                                            </div>
                                        </div>

                                        
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label for="">Kasus Penyakit <strong
                                                        class="text-danger">*</strong></label>
                                                <v-select placeholder="Pilih Item" v-model="row.autl_kasus_penyakit"
                                                    :options="Config.mr.mrKasusPenyakit" label="text"
                                                    :reduce="v=>v.value">
                                                </v-select>
                                                <VValidate name="Kasus Penyakit" v-model="row.autl_kasus_penyakit"
                                                    :rules="toValidate(mrValidation.autl_kasus_penyakit)" />

                                            </div>
                                        </div>

                                        </template>
                                        
                                        <template v-if="!((rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1) && !((rowReg.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && (rowReg.aur_tindak_lanjut_rajal == 'KM' || rowReg.aur_tindak_lanjut_rajal == 'MU'))">
                                            <p>Tidak ada Tindak Lanjut</p>
                                        </template>

    
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-info mb-0">
                                <div class="d-flex align-items-center">
                                    <i class="icon-info22"></i>
                                    <span class="ml-2">Pastikan untuk melanjutkan ke pengisian dokumen</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="text-right">
                                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                            </div>
                        </div>
                        </b-form>
                    </validation-observer>
                </template>
                <template v-else>
                    <ul class="nav nav-tabs nav-justified nav-tabs-bottom mb-0">
                        <li class="nav-item"><a href="javascript:;" :class="activeTabInformasi == 1 ? 'nav-link active' : 'nav-link'" @click="changeTabData(1)" data-toggle="tab">Informasi Tindak Lanjut Pasien</a></li>
                        <li class="nav-item"><a href="javascript:;" :class="activeTabInformasi == 2 ? 'nav-link active' : 'nav-link'" @click="changeTabData(2)" data-toggle="tab">Dokumen Tindak Lanjut Pasien</a></li>
                    </ul>
                    <div class="tab-content">
                        <div :class="activeTabInformasi == 1 ? 'tab-pane fade active show' : 'tab-pane fade'">
                            <div class="card">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Kondisi Pasien Keluar dari UGD</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="result_tab">
                                                        <h4>Kondisi Umum</h4>
                                                        <p>{{row.autl_kondisi_umum||"-"}}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Tekanan Darah</h4>
                                                <p v-if="row.autl_ttv_tekanan_darah_min || row.autl_ttv_tekanan_darah_max">{{row.autl_ttv_tekanan_darah_min}} / {{row.autl_ttv_tekanan_darah_max}} mmHG</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Nadi</h4>
                                                <p v-if="row.autl_ttv_nadi">{{row.autl_ttv_nadi||"-"}}/mnt</p>
                                                <p v-else> - </p>
                                                
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Gula Darah</h4>
                                                <p v-if="row.autl_ttv_gula_darah">{{row.autl_ttv_gula_darah||"-"}}mg/dL</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Pernafasan</h4>
                                                <p v-if="row.autl_ttv_pernafasan">{{row.autl_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>SPO2</h4>
                                                <p v-if="row.autl_ttv_spo2">{{row.autl_ttv_spo2||"-"}}%</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Suhu</h4>
                                                <p v-if="row.autl_ttv_suhu">{{row.autl_ttv_suhu||"-"}}C</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Berat Badan</h4>
                                                <p v-if="row.autl_ttv_weight">{{row.autl_ttv_weight||"-"}}kg</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Tinggi</h4>
                                                <p v-if="row.autl_ttv_height">{{row.autl_ttv_height||"-"}}Cm</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="result_tab">
                                                <h4>Lingkar Kepala</h4>
                                                <p v-if="row.autl_ttv_lingkar_kepala">{{row.autl_ttv_lingkar_kepala||"-"}}cm</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td v-if="rowReg.ap_usia >= 2">
                                                <div class="result_tab">
                                                <h4>BMI</h4>
                                                <p v-if="row.autl_ttv_bmi">{{row.autl_ttv_bmi}}m2</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            <td v-if="rowReg.ap_usia <= 15">
                                                <div class="result_tab">
                                                <h4>Luas Permukaan Tubuh Anak</h4>
                                                <p v-if="row.autl_ttv_luas_permukaan_anak">{{row.autl_ttv_luas_permukaan_anak}}kg/m2</p>
                                                <p v-else> - </p>
                                                </div>
                                            </td>
                                            
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                            <template v-if="((rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1) || ((rowReg.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && (rowReg.aur_tindak_lanjut_rajal == 'KM' || rowReg.aur_tindak_lanjut_rajal == 'MU'))">
                            <div class="card">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Tindak Lanjut</h6>
                                </div>
                            </div>
                            <div class="card-body p-3">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1">
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Pasien Masuk ke Ruangan</h4>
                                                    <p>{{getConfigDynamic(Config.mr.pasienMasukKeRuangan,row.autl_pasien_masuk_ruangan)||"-"}}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Masuk Ruang Rawat Inap</h4>
                                                    <p>{{row.autl_masuk_ranap||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            <template v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && (rowReg.aur_tindak_lanjut_rajal == 'KM' || rowReg.aur_tindak_lanjut_rajal == 'MU')">
                                            <tr>
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Dipulangkan Pukul</h4>
                                                    <p>{{row.autl_dipulangkan_pukul||"-"}}</p>
                                                    </div>
                                                </td>
                                                
                                                <td>
                                                    <div class="result_tab">
                                                    <h4>Transportasi Pulang </h4>
                                                    <p>{{getConfigDynamic(Config.mr.transportasiPulangUGD,row.autl_transportasi)||"-"}}</p>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="result_tab">
                                                        <h4>Pendidikan Kesehatan saat Pasien Pulang</h4>
                                                        <template v-if="(row.autl_pendidikan_pasien||[]).length">
                                                        <ul>
                                                            <li v-for="(v,k) in (row.autl_pendidikan_pasien||[])" :key="k+'lain'">
                                                            <span v-if="v=='Lainnya'">Lainnya : {{row.autl_pendidikan_pasien_text||"-"}}</span>                       <span v-else>{{v||"-"}}</span>
                                                            </li>
                                                        </ul>
                                                        </template>
                                                        <template v-else>
                                                        <p> - </p>
                                                        </template>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div class="result_tab">
                                                        <label for="">Kasus Penyakit</label>
                                                        <p>{{getConfigDynamic(Config.mr.mrKasusPenyakit,row.autl_kasus_penyakit)||"-"}}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                            </template>

                                            <template v-if="!((rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1) && !((rowReg.aur_tindak_lanjut||[]).indexOf('RJ') != -1 && (rowReg.aur_tindak_lanjut_rajal == 'KM' || rowReg.aur_tindak_lanjut_rajal == 'MU'))">
                                                <tr>
                                                    <td>Tidak ada Tindak Lanjut</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </template>
                        </div>
                        <div :class="activeTabInformasi == 2 ? 'tab-pane fade active show' : 'tab-pane fade'">
                            <div class="card">
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="card-title font-weight-semibold">Persetujuan Tindakan Kedokteran (Informed Consent)</h6>
                                    </div>
                                    <div class="card-body">
                                        <div class="row row-gutter">
                                        <div class="col-md-4" v-for="(v,k) in (informConcent||[])" :key="k">
                                            <div class="wrap_line mb-0 h-100">
                                                <div class="mb-2">
                                                    <h3 class="pb-0">{{v.auic_name||"-"}}</h3>
                                                </div>
                                                <span class="badge badge-primary">{{v.auic_created_on}}</span>
                                                <div class="d-flex align-items-center justify-content-between mt-2">
                                                    <span v-if="v.auic_status == 'DONE'" class="badge badge-success">Selesai</span>
                                                    <span v-else class="badge badge-info">Unggah TTD</span>
                                                    <div>
                                                        <a v-if="v.auic_file" :href="$parent.uploader(v.auic_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>

                                                    </div>
                                                </div>
                                                <span v-if="v.auic_is_pasien_setuju == 'Y'" class="badge badge-success">Tindakan Disetujui</span>
                                                <span v-else class="badge badge-danger">Tindakan Ditolak</span>

                                            </div>
                                        </div>
                                        <div class="col-md-4" v-if="!(informConcent||[]).length">
                                            <p>Tidak ada Data Inform Consent</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex align-items-center">
                                            <h6 class="card-title font-weight-semibold mr-auto">Dokumen Terkait Pasien</h6>
                                        </div>
                                    </div>
                                    <table class="table table-bordered table-sm patient-table">
                                        <thead>
                                            <tr>
                                                <th>Nama Dokumen</th>
                                                <th>Status</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-if="rowReg.aur_dnr_status == 'Y'">
                                            <td>Form Do Not Resuscitate</td>
                                            <td>
                                                <span v-if="rowReg.aur_dnr_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                                -
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_is_intra_penunjang == 'Y' && rowReg.aur_intra_rs_status == 'Y'">
                                            <td>Form Transfer Intra RS (Penunjang)</td>
                                            <td>
                                                <span v-if="rowReg.aur_intra_rs_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>

                                            <a href="javascript:;" @click="toForm('IntraRS',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                            </td>
                                        </tr>
                                        <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && rowReg.aur_intra_ranap_status == 'Y'">
                                            <td>Form Transfer Intra RS (Rawat Inap)</td>
                                            <td>
                                                <span v-if="rowReg.aur_intra_ranap_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                            <a href="javascript:;" @click="toForm('IntraRanap',true)"  class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_skd_status == 'Y'">
                                            <td>Surat Keterangan Sehat Dokter</td>
                                            <td>
                                                <span v-if="rowReg.aur_skd_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_skd_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else-if="rowReg.aur_skd_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>

                                            <template v-if="rowReg.aur_skd_status == 'Y'">
                                                <a :href="$parent.uploader(rowReg.aur_skd_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                            </template>
                                            <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_surat_sakit_status == 'Y'">
                                            <td>Surat Izin Sakit</td>
                                            <td>
                                                <span v-if="rowReg.aur_surat_sakit_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_surat_sakit_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else-if="rowReg.aur_surat_sakit_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_surat_sakit_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_surat_sakit_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_pemaparan_rm_status == 'Y'">
                                            <td>Surat Izin Pemaparan Isi Rekam Medis</td>
                                            <td>
                                                <span v-if="rowReg.aur_pemaparan_rm_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_pemaparan_rm_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else-if="rowReg.aur_pemaparan_rm_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                            <template v-if="rowReg.aur_pemaparan_rm_status == 'Y'">
                                                <a :href="$parent.uploader(rowReg.aur_pemaparan_rm_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                            </template>
                                            <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <tr v-if="(rowReg.aur_tindak_lanjut_rajal == 'DOA' || rowReg.aur_tindak_lanjut_rajal == 'MU') && rowReg.aur_surat_kematian_status == 'Y'">
                                            <td>Surat Keterangan Kematian</td>
                                            <td>
                                                <span v-if="rowReg.aur_surat_kematian_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_surat_kematian_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else class="badge badge-info">Unggah TTD</span>
                                            <td>
                                            
                                            <template v-if="rowReg.aur_surat_kematian_status == 'Y'">
                                                <a :href="$parent.uploader(rowReg.aur_surat_kematian_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                            </template>
                                            <span v-else> - </span>

                                            </td>
                                        </tr>
                                        <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RU') != -1 && rowReg.aur_rujukan_status == 'Y'">
                                            <td>Surat Rujukan</td>
                                            <td>
                                                <span v-if="rowReg.aur_rujukan_status == 'Y'" class="badge badge-success">Selesai Dicetak</span>
                                                <span v-else class="badge badge-warning">Belum Tercetak</span></td>
                                            <td>
                                            <a href="javascript:;" @click="generateFile(rowReg.aur_id,'SuratRujukan')" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen Akhir"><i class="icon-download"></i></a>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_permintaan_darah_status == 'Y'">
                                            <td>Form Permintaan Darah</td>
                                            <td>
                                                <span v-if="rowReg.aur_permintaan_darah_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_permintaan_darah_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else-if="rowReg.aur_permintaan_darah_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_permintaan_darah_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_permintaan_darah_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && rowReg.aur_surat_dpjp_status == 'Y'">
                                            <td>Surat Penetapan DPJP</td>
                                            <td>
                                                <span v-if="rowReg.aur_surat_dpjp_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_surat_dpjp_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else class="badge badge-info">Unggah TTD</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_surat_dpjp_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_surat_dpjp_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <!--  -->
                                        <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && rowReg.aur_kriteria_icu_status == 'Y'">
                                            <td>Kriteria Masuk ICU, PICU, NICU</td>
                                            <td>
                                                <span v-if="rowReg.aur_kriteria_icu_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_kriteria_icu_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else-if="rowReg.aur_kriteria_icu_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_kriteria_icu_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_kriteria_icu_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <!--
                                        
                                        -->
                                        <tr v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 && rowReg.aur_kriteria_isolasi_status == 'Y'">
                                            <td>Kriteria Masuk Isolasi</td>
                                            <td>
                                                <span v-if="rowReg.aur_kriteria_isolasi_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else-if="rowReg.aur_kriteria_isolasi_status == 'T'" class="badge badge-primary">TTD Dokumen</span>
                                                <span v-else-if="rowReg.aur_kriteria_isolasi_status == 'U'" class="badge badge-info">Unggah TTD</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_kriteria_isolasi_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_kriteria_isolasi_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>

                                        <template>
                                        <tr v-if="rowReg.aur_pemeriksaan_egd_status == 'Y'" >
                                            <td>Pemeriksaan EKG</td>
                                            <td>
                                                <span v-if="rowReg.aur_pemeriksaan_egd_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-info">Unggah Berkas</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_pemeriksaan_egd_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_pemeriksaan_egd_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_pemeriksaan_ctg_status == 'Y'" >
                                            <td>Pemeriksaan CTG</td>
                                            <td>
                                                <span v-if="rowReg.aur_pemeriksaan_ctg_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-info">Unggah Berkas</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_pemeriksaan_ctg_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_pemeriksaan_ctg_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                        </template>

                                        <template v-if="(rowReg.aur_tindak_lanjut||[]).indexOf('RI') != -1 || (rowReg.aur_tindak_lanjut||[]).indexOf('P') != -1">
                                        <tr v-if="rowReg.aur_pemeriksaan_partograf_status == 'Y'">
                                            <td>Partograf</td>
                                            <td>
                                                <span v-if="rowReg.aur_pemeriksaan_partograf_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-info">Unggah Berkas</span>
                                            </td>
                                            <td>
                                                <template v-if="rowReg.aur_pemeriksaan_partograf_status == 'Y'">
                                                    <a :href="$parent.uploader(rowReg.aur_pemeriksaan_partograf_file)" target="_blank" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen"><i class="icon-download"></i></a>
                                                </template>
                                                <span v-else> - </span>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_partus_status == 'Y'">
                                            <td>Catatan Partus</td>
                                            <td>
                                                <span v-if="rowReg.aur_partus_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                            <a href="javascript:;" @click="toForm('Partus',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_bbl_status == 'Y'">
                                            <td>Form Bayi Baru Lahir</td>
                                            <td>
                                                <span v-if="rowReg.aur_bbl_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                            </td>
                                            <td>
                                            <a href="javascript:;" @click="toForm('BBL',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                            </td>
                                        </tr>

                                        <template v-if="$parent.isUGDAdjPersalinan">
                                        <tr v-if="rowReg.aur_laporan_nifas_status == 'Y'" >
                                            <td>Laporan Nifas</td>
                                            <td>
                                                <span v-if="rowReg.aur_laporan_nifas_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                                <p v-if="rowReg.aur_laporan_nifas_status_by" class="mt-1 mb-0 text-success">Telah Diselesaikan oleh : {{rowReg.aur_laporan_nifas_status_by||"-"}}</p>
                                            </td>
                                            <td>
                                            <a href="javascript:;" @click="toForm('LaporanNifas',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                            </td>
                                        </tr>
                                        <tr v-if="rowReg.aur_laporan_persalinan_status == 'Y'">
                                            <td>Laporan Persalinan</td>
                                            <td>
                                                <span v-if="rowReg.aur_laporan_persalinan_status == 'Y'" class="badge badge-success">Selesai</span>
                                                <span v-else class="badge badge-warning">Tidak Diisi</span>
                                                
                                                <p v-if="rowReg.aur_laporan_persalinan_status_by" class="mt-1 mb-0 text-success">Telah Diselesaikan oleh : {{rowReg.aur_laporan_persalinan_status_by||"-"}}</p>
                                            </td>
                                            <td>

                                            <a href="javascript:;" @click="toForm('LaporanPersalinan',true)" class="btn btn-sm alpha-indigo border-indigo btn-icon rounded-round text-indigo-700" v-b-tooltip.hover.right title="Lihat Detail"><i class="icon-file-eye"></i></a>
                                            </td>
                                        </tr>
                                        </template>
                                        </template>
                                        
                                        <tr v-if="row.surat_pengantar">
                                            <td>Surat Pengantar Rawat Inap</td>
                                            <td>
                                                <span class="badge badge-info">Unduh Berkas</span>
                                            </td>
                                            <td>
                                                <a href="javascript:;" @click="doOpenPengantar()" class="btn btn-sm alpha-success border-success btn-icon rounded-round text-success-700" v-b-tooltip.hover.right title="Unduh Dokumen Surat Pengantar Rawat Inap"><i class="icon-download"></i></a>
                                            </td>
                                        </tr>

                                        <tr v-if="isTindakLanjutEmpty">
                                            <td colspan="99" class="text-center">Tidak ada Tindak lanjut</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="card-footer">
                    <div class="text-right">
                        <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default">Kembali</button>
                    </div>
                </div>
            </div>
        <!-- /dashboard content -->
        </div>

        
        <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
            <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
            <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
        </b-modal>
        
            
        <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
            <RiwayatRM v-bind="passToSubRM" />
        </b-modal>        
        
        <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
            <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
        </b-modal>
        
        <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
            <RiwayatRMUGD v-bind="passToSubRMUGD" />
        </b-modal>

        <b-modal v-model="openPengantar" @ok.prevent="doSubmitPengantar" title="Surat Pengantar Rawat Inap" size="lg">
            <div class="card-body">
                <table class="table table-bordered table-sm text-uppercase">
                    <tbody>
                    <tr>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Nama Pasien</h4>
                            <p>{{pengantarRanap.ap_fullname||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Tanggal Lahir</h4>
                            <p>{{pengantarRanap.ap_dob | moment("DD MMMM YYYY")}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Jenis Kelamin </h4>
                            <p>{{pengantarRanap.ap_gender == 1 ? "Laki-Laki":"Perempuan"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>No. Rekam Medis</h4>
                            <p>{{pengantarRanap.ap_code||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>DR. Jaga/DPJP </h4>
                            <p>{{pengantarRanap.bu_full_name||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Diagnosa Awal</h4>
                            <p>{{pengantarRanap.aranspr_diagnosa_awal||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="result_tab">
                                <h4>Tanggal Masuk/Jam</h4>
                                <div class="input-group">
                                    <datepicker input-class="form-control transparent h-38" placeholder="Tanggal"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="pengantarRanap.aranspr_tanggal_masuk">
                                    </datepicker>
                                    <vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="pengantarRanap.aranspr_jam_masuk">
                                    </vue-timepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Tindakan Medis</h4>
                                <b-form-textarea v-model="pengantarRanap.aranspr_tindakan_medis" rows="4" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="result_tab">
                                <h4>Rencana Tanggal Masuk/Jam</h4>
                                <div class="input-group">
                                    <datepicker input-class="form-control transparent h-38" placeholder="Tanggal"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="pengantarRanap.aranspr_rencana_tanggal_masuk">
                                    </datepicker>
                                    <vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="pengantarRanap.aranspr_rencana_jam_masuk">
                                    </vue-timepicker>
                                    <div class="input-group-append calendar-group">
                                        <span class="input-group-text" id="basic-addon2"><i
                                            class="icon-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="result_tab">
                                <h4>Persiapan Pre Operasi</h4>
                                <b-form-textarea v-model="pengantarRanap.aranspr_pre_operasi" rows="4" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <div class="result_tab">
                                <h4>Keterangan</h4>
                                <b-form-checkbox-group
                                    class="checkbox-inline mt-2"
                                    v-model="pengantarRanap.aranspr_keterangan"
                                    :options="Config.mr.keteranganFormulir"
                                    name="Keterangan"
                                ></b-form-checkbox-group>
                                <template v-if="(pengantarRanap.aranspr_keterangan||[]).indexOf('Lainnya') != -1">
                                    <b-form-group>
                                        <b-form-input placeholder="Keterangan Lainnya" type="text" class="form-control" v-model="pengantarRanap.aranspr_keterangan_lain"/>
                                    </b-form-group>
                                </template>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import $ from 'jquery'
    const _ = global._
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import Gen from '@/libs/Gen.js'
    const moment = require('moment')

    import DetailKajianAwal from '@/components/RiwayatPOLI/DetailKajianAwal.vue'
    import DetailKajianLanjutan from '@/components/RiwayatPOLI/DetailKajianLanjutan.vue'
    import RiwayatRM from '@/components/RiwayatPOLI/RiwayatRM.vue'

    import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD2.vue'
    import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD2.vue'

    export default {
        props: {
            row: Object,
            mrValidation: Object,
            Config: Object,
            rowReg: Object,
            mKesadaran: Array,
            informConcent: Array
        },
            
        data() {
            return {            
                openDetailKajian: false,

                openDetailKajianPerawat: false,
                openRwiayatRM: false,
                dataRiwayatRMDokter: {},
                dataRiwayatRMDokterTerapi: {},
                dataRiwayatRMDokterGizi: {},
                dataRiwayatRMFisio: {},
                dataRiwayatRMRadiologi: {},
                dataRiwyatRMPerawat: {},
                dataRiwayatRMLab: {},
                dataRiwyatRMPerawatIS: '',
                historyKajianPerawat: [],
                historyKajianDokter: [],
                historyKajianDokterTerapi: [],
                historyKajianDokterGizi: [],
                historyKajianFisio: [],
                historyKajianRadiologi: [],
                historyKajianLab: [],
                dataRiwayatRMFollowUp: {},

                openDetailKajianPerawatUGD: false,
                openRiwayatRMUGD: false,

                dataKajianPerawat: {},
                dataKajianDokter: {},

                historiKajianPerawat: [],
                historiKajianDokter: [],

                masterKajianPerawat: {},
                masterKajianDokter: {},

                masterTriase: {},
                dataKajianTriase: {},

                historiKajianRadiologi: [],
                historiKajianLab: [],
                historiKajianTriase: [],

                dataKajianRadiologi: {},
                dataKajianLab: {},

                dataKajianFarmasi: [],
                dataUGDReservasi: {},

                activeTabInformasi: 1,    
                resepDokter: [],     
                
                openPengantar: false,
                pengantarRanap: {}       
            }
        },
        
        components: {
            VueTimepicker,
            DetailKajianAwal,
            DetailKajianLanjutan,
            RiwayatRM,
            KajianPerawatUGD,
            RiwayatRMUGD
        },
        computed: {
            passToSub() {
                return _.assign({
                isAdd: this.isAdd,
                mrValidation: this.mrValidation,
                Config: this.Config,
                row: this.row,
                rowReg: this.rowReg,
                mPekerjaan: this.mPekerjaan,
                mAlatBantu: this.mAlatBantu,
                mSkrininGizi: this.mSkrininGizi,
                mSkrininGiziV2: this.mSkrininGiziV2,
                diagnosa: this.diagnosa,
                mSDKI: this.mSDKI,
                mSLKI: this.mSLKI,
                mSIKI: this.mSIKI,
                mPoli: this.mPoli,
                mDokter: this.mDokter,
                mKesadaran: this.mKesadaran,
                resepDokter: this.resepDokter,
                historyKajianPerawat: this.historyKajianPerawat
                })
            },
            passToSubLanjutan() {
                return _.assign({
                isAdd: this.isAdd,
                mrValidation: this.mrValidation,
                Config: this.Config,
                row: this.row,
                rowReg: this.rowReg,
                diagnosa: this.diagnosa,
                mSDKI: this.mSDKI,
                mSIKI: this.mSIKI,
                mKesadaran: this.mKesadaran,
                resepDokter: this.resepDokter,
                historyKajianPerawat: this.historyKajianPerawat
                })
            },
            passToSubRM() {
                return _.assign({
                dataRiwayatRMDokter: this.dataRiwayatRMDokter,
                dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
                dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
                dataRiwayatRMFisio: this.dataRiwayatRMFisio,
                dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
                dataRiwayatRMLab: this.dataRiwayatRMLab,
                dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,

                historyKajianPerawat: this.historyKajianPerawat,
                historyKajianDokter: this.historyKajianDokter,
                historyKajianDokterTerapi: this.historyKajianDokterTerapi,
                historyKajianDokterGizi: this.historyKajianDokterGizi,
                historyKajianFisio: this.historyKajianFisio,
                historyKajianRadiologi: this.historyKajianRadiologi,
                historyKajianLab: this.historyKajianLab,

                row: this.row,
                rowReg: this.rowReg,
                dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
                })
            },

            
            passToSubPerawatUGD(){
                return _.assign({
                    isAdd:this.isAdd,
                    mrValidation:this.mrValidation,
                    Config:this.Config,
                    row: this.row,
                    rowReg: this.rowReg,
                    dataKajianPerawat: this.dataKajianPerawat,
                    historiKajianPerawat: this.historiKajianPerawat,
                    masterKajianPerawat: this.masterKajianPerawat,
                })
            },

            passToSubRMUGD(){
                return _.assign({
                    isAdd:this.isAdd,
                    mrValidation:this.mrValidation,
                    Config:this.Config,
                    row: this.row,
                    rowReg: this.rowReg,
                    dataKajianDokter: this.dataKajianDokter,
                    dataKajianTriase: this.dataKajianTriase,
                    historiKajianDokter: this.historiKajianDokter,
                    masterKajianDokter: this.masterKajianDokter,
                    masterTriase: this.masterTriase,
                    historiKajianRadiologi: this.historiKajianRadiologi,
                    historiKajianLab: this.historiKajianLab,
                    historiKajianTriase: this.historiKajianTriase,
                    dataKajianRadiologi: this.dataKajianRadiologi,
                    dataKajianLab: this.dataKajianLab,
                    dataKajianFarmasi: this.dataKajianFarmasi,
                    dataUGDReservasi: this.dataUGDReservasi
                })
            },

            isDetailKeluarUGD(){
                return this.$route.query.isDetailKeluarUGD
            },

            isTindakLanjutEmpty(){
                return (this.rowReg.aur_dnr_status != 'Y' &&
                this.rowReg.aur_intra_rs_status != 'Y' &&
                this.rowReg.aur_intra_ranap_status != 'Y' &&
                this.rowReg.aur_skd_status != 'Y' &&
                this.rowReg.aur_surat_sakit_status != 'Y' &&
                this.rowReg.aur_pemaparan_rm_status != 'Y' &&
                this.rowReg.aur_surat_kematian_status != 'Y' &&
                this.rowReg.aur_rujukan_status != 'Y' &&
                this.rowReg.aur_permintaan_darah_status != 'Y' &&
                this.rowReg.aur_surat_dpjp_status != 'Y' &&
                this.rowReg.aur_kriteria_icu_status != 'Y' &&
                this.rowReg.aur_kriteria_isolasi_status != 'Y' &&
                this.rowReg.aur_pemeriksaan_egd_status != 'Y' &&
                this.rowReg.aur_pemeriksaan_ctg_status != 'Y' &&
                this.rowReg.aur_pemeriksaan_partograf_status != 'Y' &&
                this.rowReg.aur_partus_status != 'Y' &&
                this.rowReg.aur_bbl_status != 'Y')
            }
        },
        methods: {
            doSubmitPengantar(){
                this.loadingOverlay = true                        
                let data = this.pengantarRanap
                data.type = "submit-pengantar",
            
                Gen.apiRest(
                    "/do/"+'UGDAssesment', 
                    {
                        data: data
                    }, "POST"
                ).then(()=>{
                    this.loadingOverlay = false   

                    let self = this
                    let dataPengantar = {
                        exptype: 'pdf',
                        type: "export",
                        id: this.pengantarRanap.aranspr_id
                    }
                    // self.loadingOverlay = true
                    $.ajax({
                        type: "POST",
                        url: process.env.VUE_APP_API_URL + `/report/${'suratPengantarRanap'}?token=IXs1029102asoaksoas102901290`,
                            data: dataPengantar,
                            cache: false,
                            xhrFields: {
                            responseType: 'blob'
                        },
                        success: data => {
                            self.loadingOverlay = false
                            self.openPengantar = false
                            self.openReport = false

                            var link = document.createElement('a')
                            link.href = window.URL.createObjectURL(data)
                            link.download = `${'Surat-Pengantar-Ranap'}-${moment().format("YYYY-MM-DD")}.pdf`
                            link.click()
                        },
                        fail: data => {
                            self.loadingOverlay = false
                            alert('Not downloaded')
                        }
                    })
                })
            },
            doOpenPengantar(){
                this.openPengantar=true
                this.pengantarRanap = this.row.pengantarRanap
            },
            generateFile(id,val){
                let data = {exptype: 'xlsx', type: "export", id: id}
                let self = this
                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/dokumen-ttd/${val}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `${val}-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            },
            toForm(isForm,isDetailForm){
                if(isDetailForm){
                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug, typeDokumen: isForm},query: {isDetail: true, isDetailForm: true, regId: this.$route.query.regId}})
                }else{
                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug, typeDokumen: isForm},query: {isDetail: true, regId: this.$route.query.regId}})
                }
                window.scrollTo(0,0)        
            },
            changeTabData(val){
                this.activeTabInformasi = val
            },
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            doOpenRiwayatRMUGD(v){
                this.$parent.loadingOverlay = true
                
                if(v.kajianDokter){
                    Gen.apiRest(
                        "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id, 
                    ).then(res=>{
                        this.openRiwayatRMUGD = true    
                        this.dataKajianDokter = res.data
                        this.$parent.loadingOverlay = false
                    })

                    Gen.apiRest(
                        "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id+'&master=1', 
                    ).then(res=>{
                        this.masterKajianDokter = res.data
                    })     
                }
               

                if(v.triase_id){                
                    Gen.apiRest(
                        "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aur_id, 
                    ).then(res=>{
                        this.dataKajianTriase = res.data
                    })
                }
                          
                Gen.apiRest(
                    "/get/"+'UGDTriase'+'/'+'master', 
                ).then(res=>{
                    this.masterTriase = res.data
                })

                
                if(v.kajianLab){
                    Gen.apiRest(
                        "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aur_id, 
                    ).then(res=>{
                        this.dataKajianLab = res.data
                    })
                }
                
                if(v.kajianRadiologi){
                    Gen.apiRest(
                        "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aur_id, 
                    ).then(res=>{
                        this.dataKajianRadiologi = res.data
                    })
                }

                Gen.apiRest(
                    "/do/" + 'UGDAssesment', {
                        data: {
                        type: 'get-obat-farmasi',
                        regId: v.aur_id
                        }
                    }
                ).then(res => {
                    let resp = res.data
                    this.dataKajianFarmasi = resp.data
                    this.dataUGDReservasi = resp.dataUGDReservasi
                    this.$parent.loadingOverlay = false
                })
                
                
                this.openRiwayatRMUGD = true
            },
            toDetailKajianPerawatUGD(){
                this.$parent.loadingOverlay = true
                Gen.apiRest(
                    "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id, 
                ).then(res=>{
                    this.openDetailKajianPerawatUGD = true    
                    this.dataKajianPerawat = res.data
                    this.$parent.loadingOverlay = false
                })
                Gen.apiRest(
                    "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id+'&master=1', 
                ).then(res=>{
                    this.masterKajianPerawat = res.data
                })
            },
            doOpenRiwayatRMV2(v) {
                this.$parent.loadingOverlay = true
                if (v.kajianDokter) {
                Gen.apiRest(
                    "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.ar_id,
                ).then(res => {
                    this.$parent.loadingOverlay = false
                    this.dataRiwayatRMDokter = res.data
                    this.dataRiwayatRMDokter.Config = this.Config
                    this.openRwiayatRM = true
                })
                } else {
                this.dataRiwayatRMDokter = {}
                }

                if (v.kajianDokterTerapi) {
                Gen.apiRest(
                    "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.ar_id,
                ).then(res => {
                    this.$parent.loadingOverlay = false
                    this.dataRiwayatRMDokterTerapi = res.data
                    this.dataRiwayatRMDokterTerapi.Config = this.Config
                    this.openRwiayatRM = true
                })
                } else {
                this.dataRiwayatRMDokterTerapi = {}
                }

                if (v.kajianGizi) {
                Gen.apiRest(
                    "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + v.ar_id,
                ).then(res => {
                    this.$parent.loadingOverlay = false
                    this.dataRiwayatRMDokterGizi = res.data
                    this.dataRiwayatRMDokterGizi.Config = this.Config
                    this.openRwiayatRM = true
                })
                } else {
                this.dataRiwayatRMDokterGizi = {}
                }

                if (v.kajianFisio) {
                Gen.apiRest(
                    "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + v.ar_id,
                ).then(res => {
                    this.$parent.loadingOverlay = false
                    this.dataRiwayatRMFisio = res.data
                    this.dataRiwayatRMFisio.Config = this.Config
                    this.openRwiayatRM = true
                })
                } else {
                this.dataRiwayatRMFisio = {}
                }

                if (v.kajianRadiologi) {
                Gen.apiRest(
                    "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + v.ar_id,
                ).then(res => {
                    this.$parent.loadingOverlay = false
                    this.dataRiwayatRMRadiologi = res.data
                    this.dataRiwayatRMRadiologi.Config = this.Config
                    this.openRwiayatRM = true
                })
                } else {
                this.dataRiwayatRMRadiologi = {}
                }

                if (v.kajianLab) {
                Gen.apiRest(
                    "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + v.ar_id,
                ).then(res => {
                    this.$parent.loadingOverlay = false
                    this.dataRiwayatRMLab = res.data
                    this.dataRiwayatRMLab.Config = this.Config
                    this.openRwiayatRM = true
                })
                } else {
                this.dataRiwayatRMLab = {}
                }

                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: {
                    type: 'get-obat-farmasi',
                    regId: v.ar_id
                    }
                }
                ).then(res => {
                let resp = res.data
                this.dataRiwayatRMFarmasi = resp.data
                this.$parent.loadingOverlay = false
                })

                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: {
                    type: 'get-riwayat-rekam-medis',
                    ar_id: v.ar_id
                    }
                }
                ).then(res => {
                let resp = res.data
                // non ppa
                this.historyKajianPerawat = resp.historyKajianPerawat
                this.historyKajianDokter = resp.historyKajianDokter
                this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
                
                this.$parent.loadingOverlay = false
                })

                Gen.apiRest(
                "/do/" + 'RekamMedis', {
                    data: {
                    type: 'get-riwayat-rekam-medis',
                    ar_id: v.ar_id
                    }
                }
                ).then(res => {
                let resp = res.data
                this.$parent.loadingOverlay = false
                
                this.historyKajianDokterGizi = resp.historyKajianDokterGizi
                this.historyKajianFisio = resp.historyKajianFisio
                this.historyKajianRadiologi = resp.historyKajianRadiologi
                this.historyKajianLab = resp.historyKajianLab
                })


                Gen.apiRest(
                "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.ar_id,
                ).then(res => {
                this.$parent.loadingOverlay = false
                this.dataRiwayatRMFollowUp = res.data
                this.dataRiwayatRMFollowUp.Config = this.Config
                this.openRwiayatRM = true
                })


                setTimeout(() => {
                this.$parent.loadingOverlay = false
                this.openRwiayatRM = true
                }, 2000)
            },
            toValidate(val) {
                return {
                    ...val
                }
            },
            hitungBMI(){
                if (this.row.autl_ttv_weight && this.row.autl_ttv_height) {
                    this.row.autl_ttv_bmi = this.row.autl_ttv_weight / 
                    ((this.row.autl_ttv_height/100)*(this.row.autl_ttv_height/100))
                    this.row.autl_ttv_bmi = this.row.autl_ttv_bmi.toFixed(2)
                }
            },
            initSticky() {
                const PageHeaderSticky = document.querySelector('.page-header-sticky')
                if (PageHeaderSticky) {
                    const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                    if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
                        PageHeaderToggle.addEventListener('click', function (e) {
                            // e.preventDefault()
                            PageHeaderSticky.classList.toggle('page-header-sticky-open')
                            if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                                PageHeaderToggle.querySelector('span').innerText =
                                    'Sembunyikan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                            } else {
                                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                            }
                        })
                    }
                }
            },
            doSubmit(){
                this.$refs['VForm'].validate().then(success => {
                    if (!success) {
                        return this.$swal({
                            icon: 'error',
                            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                        }).then(result => {
                            if (result.value) {
                                setTimeout(() => {
                                    let inv = []
                                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                    for (let i = 0; i < (el || []).length; i++) {
                                        if (el[i].style.display !== 'none') {
                                        inv.push(el[i].id)
                                        }
                                    }
                                },250)
                            }
                        })
                    }
                            
                    if (success) {
                        this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                        }).then(result => {
                            if (result.value) {
                                let data = this.row
                                data.type = 'submit-data'
                                this.$parent.loadingOverlay = true
                                Gen.apiRest(
                                "/do/" + 'UGDTindakLanjut', {
                                    data: data
                                },
                                "POST"
                                ).then(res => {
                                    this.$parent.loadingOverlay = false
                                    let resp = res.data
                                    resp.statusType = 200
                                    this.$swal({
                                        title: resp.message,
                                        icon: resp.status,
                                        confirmButtonText: 'Ok',
                                        allowOutsideClick: false,
                                        allowEscapeKey: false
                                    }).then(result => {
                                        this.$router.push({name: 'UGDTindakLanjut'}).catch(() => {})
                                    })
                                }).catch(err => {
                                    this.$parent.loadingOverlay = false
                                    if (err) {
                                        err.statusType = err.status
                                        err.status = "error"
                                        err.title = err.response?.data?.title
                                        err.message = err.response?.data?.message
                                        err.messageError = err.message
                                    }
                                    this.$parent.doSetAlertForm(err)
                                })
                            }
                        })
                    }
                })
            },

        },
        mounted() {
            setTimeout(() => {
                this.initSticky()
            }, 1000)
        },

    }
</script>