<template>
  <div class="content">
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
        <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Laporan Persalinan</h6>
        </div>
      </div>

      <template v-if="!isDetail">
        <validation-observer ref="VForm">
          <b-form @submit.prevent="doSubmit" class="card">
            <div class="row">
              <div class="col-md-12 ">
                <div class="table-responsive">
                  <table class="table table-sm  table-bordered" style="width: 2000px;">
                    <tbody>
                      <tr>
                        <th class="bg-primary">Tanggal & Jam </th>
                        <th class="bg-primary">Tensi </th>
                        <th class="bg-primary" width="320">Nadi </th>
                        <th class="bg-primary" width="170">Suhu </th>
                        <th class="bg-primary">HIS </th>
                        <th class="bg-primary">DJJ </th>
                        <th class="bg-primary">VT </th>
                        <th class="bg-primary">Keterangan </th>
                        <th class="bg-primary">Aksi </th>
                      </tr>
                      <tr v-for="(v, k) in dataLaporanPersalinan" :key="`laporan-${k}`">
                        <td>
                          <div class="input-group">
                              <datepicker input-class="form-control transparent h-38" placeholder="Tanggal"
                                  class="my-datepicker" calendar-class="my-datepicker_calendar"
                                  v-model="v.aulp_date">
                              </datepicker>
                              <vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="v.aulp_time">
                              </vue-timepicker>
                              <div class="input-group-append calendar-group">
                                  <span class="input-group-text" id="basic-addon2"><i
                                      class="icon-calendar"></i></span>
                              </div>
                          </div>    
                          
                          <VValidate
                            name="Tanggal & Waktu"
                            :value="v.aulp_date && v.aulp_time"
                            :rules="{ required: 1 }"
                          />
                                    
                        </td>
                        <td>
                          <div class="input-group">
                            <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="v.aulp_sistolik" :formatter="Formatter.number"/>
                            <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                            <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="v.aulp_diastolik" :formatter="Formatter.number"/>
                            <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                          </div>

                          <VValidate
                            name="Tekanan Darah"
                            :value="v.aulp_sistolik && v.aulp_diastolik"
                            :rules="{ required: 1 }"
                          />
                        </td>
                        <td>
                          <div class="input-group">
                            <b-form-input type="text" class="form-control" v-model="v.aulp_nadi" :formatter="Formatter.number"/>

                            <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            <div class="input-group-append">
                              <v-select 
                                :options="MonitoringOptions.mrNadi" 
                                :reduce="(v) => v.value" 
                                label="label" placeholder="-- Pilih --" 
                                style="min-width: 104px;" 
                                v-model="v.aulp_nadi_type"
                              
                              />
                            </div>
                          </div>

                          <VValidate
                            name="Nadi"
                            :value="v.aulp_nadi && v.aulp_nadi_type"
                            :rules="{ required: 1 }"
                          />
                        </td>
                        <td>
                          <div class="input-group">
                            <b-form-input type="text" v-model="v.aulp_suhu" :formatter="Formatter.decimalNumber" maxlength="5"/>
                            <div class="input-group-append"><span class="input-group-text">C</span>
                            </div>
                          </div>

                          <VValidate 
                            name="Suhu"
                            :vid="`suhu-${k}`"
                            v-model="v.aulp_suhu"
                            :rules="{ required: 1 }"
                          />
                        </td>

                        <td>
                          <b-form-input type="text" v-model="v.aulp_his"/>
                        </td>

                        <td>
                          <b-form-input type="text" v-model="v.aulp_djj"/>
                        </td>

                        <td>
                          <b-form-textarea rows="4" type="text" v-model="v.aulp_vt"/>
                        </td>
                        
                        <td>
                          <b-form-input type="text" v-model="v.aulp_keterangan"/>
                        </td>

                        <td>
                          <a href="javascript:;" @click="deleteRow(v, k)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800" v-b-tooltip.hover data-popup="tooltip" title="Hapus" v-if="dataLaporanPersalinan.length > 1"><i class="icon-trash"></i></a>
                        </td>
                      </tr>

                    </tbody>

                    <tfoot>
                      <tr>
                        <td colspan="7" class="text-center">
                          <a href="javascript:;" @click="addLaporan()" class="btn btn-sm alpha-info border-info"><i class="icon-plus2 mr-1"></i>Tambah</a>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          
            <div class="card-footer">
              <div class="text-right">
                <button type="button" class="btn" @click="$router.back()">Kembali</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
      <template v-else> 
        <div class="card">
          <div class="row">
            <div class="col-md-12 table-responsive">
              <table class="table table-sm  table-bordered" style="width: 2000px;">
                <tbody>
                  <tr>
                    <th class="bg-primary">Tanggal & Jam </th>
                    <th class="bg-primary">Tensi </th>
                    <th class="bg-primary" width="320">Nadi </th>
                    <th class="bg-primary" width="170">Suhu </th>
                    <th class="bg-primary">HIS </th>
                    <th class="bg-primary">DJJ </th>
                    <th class="bg-primary">VT </th>
                    <th class="bg-primary">Keterangan </th>
                  </tr>
                  <tr v-for="(v, k) in dataLaporanPersalinan" :key="`laporan-det-${k}`">
                    <td>
                      {{v.aulp_date | moment("DD MMMM YYYY")}}, {{v.aulp_time}}
                    </td>
                    <td>
                      {{v.aulp_sistolik}}/{{v.aulp_diastolik}} mmHG
                    </td>
                    <td>
                      {{v.aulp_nadi||"-"}} x/mnt {{aulp_nadi_type == 'REGULER' ? "Reguler":"Irreguler"}} 
                    </td>
                    <td>
                      {{v.aulp_suhu||"-"}} C
                    </td>
                    <td>
                      {{v.aulp_his||"-"}}
                    </td>
                    <td>
                      {{v.aulp_djj||"-"}}
                    </td>
                    <td>
                      {{v.aulp_vt||"-"}}
                    </td>
                    <td>
                      {{v.aulp_keterangan||"-"}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
const moment = require('moment')

import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'

export default{
  extends: GlobalVue, 
  data(){
    return {
      dataLaporanPersalinan: [],
      mrValidationLaporanPersalinan: {},
    }
  },
  components: {
    Datepicker,
    VueTimepicker
  },   
  mounted() {
    this.apiGetLaporanNifas()
  },
  methods: {
    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
                setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})

                },500)
            }
          })
        }
                
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
                let data = this.isParent.rowReg
                data.type = 'submit-data-laporan-persalinan'
                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'UGDTindakLanjut', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.loadingOverlay = false
                    let resp = res.data
                    resp.statusType = 200
                    this.$swal({
                        title: resp.message,
                        icon: resp.status,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                    })
                }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
            }
          })
        }
      })
    },

    autoSaveData: _.debounce(function (data) {
      let dataPost = {
          type: 'auto-save-data-laporan-persalinan',
          data: data
      }
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: dataPost
        },
        "POST"
      )
    }, 1000),
    addLaporan(){
      let data = {
        aulp_aur_id: this.$route.params.pageSlug,
        aulp_nadi_type: null,
        aulp_vt: 'pembukaan .... cm, efismen ....cm, KK +/-, hodge 1-4, LD+/-',
        type: 'add-laporan-persalinan-data',
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.dataLaporanPersalinan.push(res.data.data)
      }) 
    },
    deleteRow(data, key){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: "remove-laporan-persalinan-data",
            id_registrasi: this.$route.params.pageSlug, 
            id: data.aulp_id
          }
        },
        "POST"
      ).then(() => {
        this.dataLaporanPersalinan.splice(key, 1)
      })
    },
    apiGetLaporanNifas(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'UGDTindakLanjut', {
        data: {
          type: 'get-data-laporan-persalinan',
          id: this.$route.params.pageSlug,
        }
      }, 'POST').then(res => {
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
        this.loadingOverlay = false            
      })
    },
  },
  computed: {
    isParent(){
      return this.$parent
    },
    isDetail(){
      return this.$route.query.isDetailForm
    },
    now(){
      return moment().format('DD MMM YYYY')
    },
    
    Formatter(){
      return Formatter
    },

    MonitoringOptions(){
      return MonitoringOptions
    }
  },
  watch: {  
    dataLaporanPersalinan: {
      handler(v) {
        this.autoSaveData(v)
      },
      deep: true
    },
  }
}

</script>