<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">

        <div class="row">
            <div class="col-md-3">  
                <div class="form-group">
                    <label for="">Tanggal kematian Pasien <span class="text-danger">*</span></label> 
                    <div class="input-group mb-3">
                        <datepicker input-class="form-control transparent"
                            placeholder="Pilih Tanggal" class="my-datepicker"
                            calendar-class="my-datepicker_calendar" v-model="rowReg.aur_tanggal_kematian">
                        </datepicker>
                        <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                        </div>
                    </div>
                    
                    <VValidate 
                        name="Tanggal kematian Pasien" 
                        v-model="rowReg.aur_tanggal_kematian" 
                        :rules="{required : 1}"
                    />
                </div>
            </div>
            <div class="col-md-6">  
                <div class="form-group">
                    <label for="">Jam kematian Pasien <span class="text-danger">*</span></label> 
                    <div class="input-group">
                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                        <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowReg.aur_jam_kematian">
                        </vue-timepicker>
                    </div>
                    
                    <VValidate 
                        name="Jam kematian Pasien" 
                        v-model="rowReg.aur_jam_kematian" 
                        :rules="{required : 1}"
                    />
                </div>
            </div>
        </div>    
        
        <div class="row" v-if="!rowReg.aur_surat_kematian_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen <span class="text-danger">*</span></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowReg['aur_surat_kematian_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowReg.aur_surat_kematian_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowReg.aur_surat_kematian_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="form-group">
                <label for="">Tanda Tangan Petugas</label>
                <div class="signing-element" data-target-id="ttdPetugas">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPetugas"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPetugas','aur_surat_kematian_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPetugas','aur_surat_kematian_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                <div class="df-content">
                    <!-- Copas Template dari sini -->
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                    <div>
                        <table style="border-collapse: collapse;width: 100%;font-size: 13px;">
                        <tbody><tr>
                            <td>
                            <div style="float: left;width: 10%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                            </div>
                            <div style="text-transform: uppercase;float: left;width: 90%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                                <h1 style="margin:0;font-size: 16px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan Magelang</h1>
                                <p style="margin:0;">Jl.P Senopati No 11, Magelang 56123</p>
                                <p style="margin:0;">Telp. (0293)-364033 s/d 364035</p>
                                <p style="margin:0;">Fax. (0293-364037)</p>
                            </div>
                            </td>
                            <td style="text-align: right;">
                            <span>Magelang, {{now}}</span>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="3" style="padding: 4px 8px;">
                            <h2 style="text-decoration: underline;font-size: 16px;text-transform: uppercase;padding-top: 16px;display: inline-block;">Surat Keterangan Kematian</h2>
                            </th>
                        </tr>
                        <tr>
                            <td colspan="3" style="padding: 4px 8px;padding-left: 64px;">
                            <p style="text-indent: 64px;">Yang bertanda tangan dibawah ini, Dokter Rumah Sakit Harapan Magelang menyatakan bahwa seorang penderita:</p>
                            <div style="padding-left: 56px;">
                                <table style="border-collapse: collapse;font-size: 13px;">
                                <tbody><tr>
                                    <td style="padding: 2px 8px">Nama</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_fullname||"-"}}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Jenis Kelamin</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_gender == 1 ? 'Laki-Laki':'Perempuan'}}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Tempat, Tgl. Lahir</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_pob||"-"}}, {{ rowPasien.ap_dob | moment("DD MMMM YYYY")}}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">NIK</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_nik||"-"}}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Pekerjaan</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.mp_name||"-"}}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Alamat</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_address||"-"}}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Nomor RM.</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowPasien.ap_code||"-"}}</td>
                                </tr>
                                </tbody></table>
                            </div>
                            <p style="margin-top: 8px;">Orang tersebut telah dirawat dan telah meninggal dunia di Rumah Sakit Harapan Magelang pada:</p>
                            <div style="padding-left: 56px;">
                                <table style="border-collapse: collapse;font-size: 13px;">
                                <tbody><tr>
                                    <td style="padding: 2px 8px">Hari</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">
                                        <span v-if="rowReg.aur_tanggal_kematian">
                                            {{rowReg.aur_tanggal_kematian | moment("dddd")}}
                                        </span>
                                        <span v-else> - </span>
                                    </td>

                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Tanggal</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">
                                        <span v-if="rowReg.aur_tanggal_kematian">
                                            {{rowReg.aur_tanggal_kematian | moment("DD MMMM YYYY") }}
                                        </span>
                                        <span v-else> - </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 2px 8px">Jam</td>
                                    <td>:</td>
                                    <td style="padding: 2px 8px">{{rowReg.aur_jam_kematian||"-"}}</td>
                                </tr>
                                </tbody></table>
                            </div>
                            <p style="text-indent: 64px;padding-bottom: 16px;">Surat keterangan ini dibuat sesuai dengan kenyataan untuk dapat dipergunakan sebagaimana mestinya.</p>
                            </td>
                        </tr>
                        <tr>
                            <td style="vertical-align: bottom;font-size: 11px;">
                            </td>
                            <td style="text-align: center;">
                            <span>Dokter Rumah Sakit Harapan<br>Magelang</span><br/>
                            <span v-if="rowReg.aur_surat_kematian_is_ttd == 'Digital'">
                            <img :src="rowReg.aur_surat_kematian_ttd ? rowReg.aur_surat_kematian_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                            <br/>
                            </span>
                            <span v-else>
                            <br/>
                            <br/>
                            <br/>
                            <br/>         
                            </span>                            
                            ({{rowReg.bu_full_name||"-"}})
                            </td>
                            <td style="padding-left: 16px;vertical-align: bottom;font-size: 11px;"></td>
                        </tr>
                        </tbody></table>
                    </div>
                    </div>
                    <!-- End Copas Template -->
                </div>
                </div>
            </div>
        </div>
        
        <div :class="rowReg.aur_surat_kematian_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

const moment = require('moment')
export default{
    extends: GlobalVue, 
    components: {
      Datepicker,VueTimepicker
    },
    data(){
        return {
            rowPasien: {},
            rowData: {},
            rowReg: {}
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowReg[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowReg[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowReg.aur_surat_kematian_is_ttd == 'Digital'){
                    if(!this.rowReg.aur_surat_kematian_ttd){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }

                this.rowReg.aur_surat_kematian_template = $(".document-framer").html()


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowReg
                            data.type = 'submit-data-ttd-surat-kematian'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDSuratKematian(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-surat-kematian',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDSuratKematian()
        setTimeout(()=>{
            if(this.$refs['ttdPetugas']){
                this.$refs['ttdPetugas'].fromDataURL(this.rowReg.aur_surat_kematian_ttd)
            }
        },1500)
    },
}      
</script>
