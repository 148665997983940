<template>
  <div class="content">
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
          <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Form Izin Pulang Sementara</h6>
          </div>
      </div>

      <template v-if="!isDetail">
        <validation-observer ref="VForm">
          <b-form @submit.prevent="doSubmit" class="card">
            <div class="form-underlying card-body p-3">
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <label for="">Keperluan Pulang<strong class="text-danger">*</strong></label>
                  <b-form-input type="text" v-model="rowSuratIzinPulang.ausip_keperluan_pulang" class="form-control"/>
                  <VValidate 
                      :name="'Keperluan Pulang'" 
                      v-model="rowSuratIzinPulang.ausip_keperluan_pulang" 
                      :rules="{required : 1}"
                  />
                </div>
                <div class="col-md-4 col-lg-4">
                  <label for="">Jam<strong class="text-danger">*</strong></label>
                  <div class="input-group">
                    <b-form-input type="text" :formatter="numberOnly" v-model="rowSuratIzinPulang.ausip_jam" class="form-control"/>
                    <div class="input-group-append"><div class="input-group-text">Jam</div></div>
                  </div>
                  <VValidate 
                      :name="'Jam Pulang'" 
                      v-model="rowSuratIzinPulang.ausip_jam" 
                      :rules="{required : 1}"
                  />
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
      <template v-else>
        <div class="form-underlying card-body p-3">
          <div class="wrap_line" id="formIntra1">
            <h2>Detail Pelayanan Kerohanian</h2>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
  extends: GlobalVue, 
  computed: {
    isParent(){
      return this.$parent
    },
    isDetail(){
      return this.$route.query.isDetailForm
    },
    now(){
      return moment().format('DD MMM YYYY')
    },
  },
  data(){
    return {
      rowSuratIzinPulang: {},
      mrValidationSuratIzinPulang: {},
    }
  },
  methods: {
    apiGetSuratIzin(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'UGDTindakLanjut', {
          data: {
              type: 'get-data-surat-izin-pulang',
              id: this.$route.params.pageSlug,
          }
      }, 'POST').then(res => {
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          this.loadingOverlay = false            
      })
    },
    toValidate(val){
      return {...val}
    },
      
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save-data-surat-izin-pulang'
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: data
        },
        "POST"
      )
    }, 1000),

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
                setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                },250)
            }
          })
        }

        if (success) {
          this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
                let data = this.rowSuratIzinPulang
                data.type = 'submit-data-surat-izin-pulang'
                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'UGDTindakLanjut', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.loadingOverlay = false
                    let resp = res.data
                    resp.statusType = 200
                    this.$swal({
                        title: resp.message,
                        icon: resp.status,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                    })
                }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
            }
          })
        }
      })
    },
  },  
  mounted() {
    this.apiGetSuratIzin()
  },
  watch: {
    rowSuratIzinPulang: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  }
}

</script>
