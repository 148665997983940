<template>
<div>
  <div class="page-header page-header-sticky" :class="{'page-header-sticky-open': isOpen}">
    <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
      <div class="page-header-info flex-1 py-3 text-uppercase">
        <div class="d-flex justify-content-between">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                <span>{{rowReg.ap_fullname||"-"}}</span>
                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
              </h5>
            </div>
            <div class="phi-sub-info d-flex">
              <div class="phi-info d-flex flex-column">
                <small>Tanggal Lahir</small>
                <strong class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Usia</small>
                <strong class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Jenis Kelamin</small>
                <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Opsi Pembayaran</small>
                <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
              </div>
              <div class="phi-info d-flex flex-column pl-2 ml-3">
                <small>Agama</small>
                <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle" @click="clickInfo()">
      <template v-if="isOpen">
        <i class="icon-arrow-up5 align-middle"></i>
        <span class="align-middle">Sembunyikan Informasi Pasien</span>
      </template>

      <template v-else>
        <i class="icon-arrow-down5 align-middle"></i>
        <span class="align-middle">Tampilkan Informasi Pasien</span>
      </template>
    </a>
  </div>

  <div class="content">
    <form-data ref="formBalanceCairan" />
  </div>
</div>
</template>

<script>
import FormData from './FormData.vue'
import Gen from '@/libs/Gen'

const _ = global._

export default {
  components: {
    FormData,
  },
  props: {
    rowReg: Object,
  },
  data() {
    return {
      isOpen: true,
      tekananDarah: {
        data: {}
      },
      balanceCairan: {},
      ews: {},
      hermodinamy: {
        data: {},
      },

      tHistoryData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },

      pageNoTData: 1,

      row: {},
      mrValidation: {},
      mrCairan: [],
      mrDarahBalanceCairan: [],

      loading: {
        patientInfo: false
      },

      patientData: {},
      formData: {},
    }
  },

  mounted() {
    this.getFormData()
  },

  methods: {
    clickInfo(){
      this.$set(this, 'isOpen', !this.isOpen)
    },

    getFormData() {
      Gen.apiRest(
        "/get/" + 'RanapBalanceCairan', {
          params: {
            type: 'get-form-ugd',
            id_ugd: this.$route.query.regId
          }
        },
      ).then(res => {
        const {
          row,
          mrCairan,
          mrDarahBalanceCairan,
          mrValidation
        } = res.data

        this.$set(this, 'formData', row)
        this.$set(this, 'mrCairan', mrCairan)
        this.$set(this, 'mrDarahBalanceCairan', mrDarahBalanceCairan)
        this.$set(this, 'mrValidation', mrValidation)
        const compFormBalanceCairan = this.$refs.formBalanceCairan
        compFormBalanceCairan.setFormData(row)
      })
    },

    doSubmitBalanceCairan(data) {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      setTimeout(() => { 
        this.$swal.close()
        this.$swal({
          title: 'Berhasil menyimpan data balance cairan',
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.back()
          }
        })
       }, 700)

      // Gen.apiRest(
      //   "/do/" + 'RanapBalanceCairan', {
      //     data: {
      //       ...data,
      //       id_registrasi: this.$route.query.regId
      //     }
      //   },
      //   "POST"
      // ).then(resp => {
      //   this.$swal.close()
      //   this.$swal({
      //     title: resp.data.message,
      //     icon: 'success',
      //     confirmButtonText: 'Ok'
      //   }).then(result => {
      //     if (result.value) {
      //       this.$router.back()
      //     }
      //   })
      // }).catch(() => {
      //   this.$swal.close()

      //   return this.$swal({
      //     icon: 'error',
      //     text: 'Terjadi Kesalahan Yang Tidak Diketahui'
      //   })
      // })
    },

    changePage(page) {
      this.$set(this, 'pageNoTData', page)
    }
  },

  computed: {
    parentData() {
      return this.$parent
    },
    moduleRole() {
      return this.parentData.moduleRole
    },
    isBayi(){
      let arrUsia = (this.rowReg.ap_usia_with_ket||'').split(" ")
      if((arrUsia||[]).length){
        arrUsia = arrUsia.map(usia => (+usia)).filter(usia => (!isNaN(usia)))

        if(arrUsia[0] == 0 && arrUsia[1] == 0 && arrUsia[2] <= 28){
          return true
        }

        return false
      } else {
        return false
      }
    },
  },

  watch: {
    'parentData.viewType'(v) {
      this.getFormData()
    },
    'pageNoTData'(v) {
      this.getHistoryData(v)
    }
  }
}
</script>
