<template>  
  <div class="content-wrapper overflow-visible">
    <div class="page-header page-header-sticky page-header-sticky-open">
        <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">
            <div class="page-header-info flex-1 py-3 text-uppercase">
                <div class="d-flex justify-content-between">
                    <div>
                        <div class="d-flex justify-content-between align-items-center">
                            <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                <span>{{rowReg.ap_fullname||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No Rekam Medis">{{rowReg.ap_code||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No KTP">{{rowReg.ap_nik||"-"}}</span>
                                <span class="ml-2 pl-2 border-left" v-b-tooltip.hover
                                    title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                            </h5>
                        </div>
                        <div class="phi-sub-info d-flex">
                            <div class="phi-info d-flex flex-column">
                                <small>Tanggal Lahir</small>
                                <strong
                                    class="font-weight-semibold">{{rowReg.ap_dob | moment("DD MMM YYYY")}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Usia</small>
                                <strong
                                    class="font-weight-semibold">{{rowReg.ap_usia_with_ket || "-"}}<br />({{rowReg.ap_gol_usia||"-"}})</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Jenis Kelamin</small>
                                <strong class="font-weight-semibold">{{rowReg.cg_label}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Opsi Pembayaran</small>
                                <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                            </div>
                            <div class="phi-info d-flex flex-column pl-2 ml-3">
                                <small>Agama</small>
                                <strong class="font-weight-semibold">{{rowReg.ap_agama_text || "-"}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column">
                        <a href="javascript:;" @click="toDetailKajianPerawatUGD()" data-toggle="modal" data-target="#modalKajianAwalPerawat" class="btn btn-outline-light">Lihat Kajian Perawat</a>
                        <a href="javascript:;" v-if="row.have_rajal" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1" @click="doOpenRiwayatRMV2(row.kajianRajal)">Lihat Riwayat Rekam Medis Rajal</a>
                        <a href="javascript:;" @click="doOpenRiwayatRMUGD(row)" data-toggle="modal" data-target="#modalRiwayatRM" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis UGD</a>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
        </a>
    </div>

    <div class="content">
      <template v-if="isListDetail">
        <div class="page-title pt-2 pb-3">
          <a href="javascript:;" @click="back()" class="text-dark">
            <i class="icon-arrow-left52 mr-1"></i>
            <span class="font-weight-semibold">Kembali</span>
          </a>
        </div>
        
        <div class="row g-2">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <div class="d-flex align-items-center">
                  <h5 class="card-title font-weight-semibold mr-auto">Monitoring Kajian Fibrinolisis</h5>
                  <div class="form-group mb-0 mr-1">
                    <button @click="apiGet()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                      class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>
                  </div>

                  <a href="javascript:;" @click="addKajian" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                    <b><i class="icon-plus2"></i></b>Buat Kajian Fibrinolisis
                  </a>
                </div>
              </div>
              <div class="card-body">
                <table class="table table-bordered table-sm patient-table">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Tanggal/Jam</th>
                      <th>Diagnosa</th>
                      <th>Perawat</th>
                      <th>Status</th>
                      <th style="width:15%">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in (dataKajian||[])" :key="k" 
                    :class="v.aukpks_is_parent == 'Y' ? 'table-info' : ''">
                      <td>
                        <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{k+1}}</span>
                      </td>
                      <td>{{v.aukpks_date | moment('DD MMMM YYYY')}}, {{v.aukpks_jam}}
                        <span v-if="v.aukpks_is_parent == 'Y'" class="badge bg-success ml-2">Assesmen Awal </span>  
                        <span v-if="v.aukpks_is_parent != 'Y'" class="badge bg-success ml-2">Monitoring </span>  
                        
                        <p class="mt-1" style="font-size: 11px;" v-if="v.aukpks_updated_date"> <b>Terakhir Diupdate pada : </b> <br/>{{v.aukpks_updated_date | moment('DD MMMM YYYY HH:mm:ss')}} </p>                    
                      </td>
                      <td>{{v.aukpks_diagnosa||"-"}}</td>
                      <td>{{v.name_petugas||"-"}}</td>
                      <td>
                        <span v-if="v.aukpks_is_draft == 'Y'" class="badge bg-warning">Draft </span>
                        <template v-else>
                          <span v-if="v.aukpks_status == 'DONE'" class="badge bg-success">Selesai Monitoring</span>
                          <span v-else class="badge bg-info">Dalam Monitoring </span>
                        </template>                      
                      </td>
                      <td>
                        <a href="javascript:;" @click="toModal(v,'xl',(v.aukpks_is_parent=='Y'?'Detail Kajian Fibrinolisis':'Detail Kajian Fibrinolisis Monitoring'),(v.aukpks_is_parent=='Y'?'DetailKajian':'DetailKajianMonitoring'))" v-if="v.aukpks_is_draft != 'Y'"
                          class="btn btn-icon ml-1 mb-1 alpha-blue border-blue" v-b-tooltip.hover title="Lihat Kajian Fibrinolisis"><i class="icon-file-eye"></i></a>

                        <template>
                          <a href="javascript:;" @click="toForm(v)" v-if="!v.aukpks_created_by" class="btn btn-icon alpha-success border-success ml-1 mb-1" v-b-tooltip.hover title="Tambah Monitoring"><i class="icon-plus2"></i></a>
                          <a href="javascript:;" @click="toForm(v)" v-else
                            class="btn btn-icon ml-1 mb-1 alpha-info border-info" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></a>
                        </template>
                        
                        <a href="javascript:;" @click="hapusTindakan(v)" v-if="v.aukpks_is_draft == 'Y' && v.aukpks_is_parent == 'Y'"
                          class="btn btn-icon ml-1 mb-1 alpha-danger border-danger" v-b-tooltip.hover title="Hapus"><i class="icon-trash"></i></a>
                      </td>
                    </tr>
                    <tr v-if="!(dataKajian||[]).length">
                      <td colspan="99" class="text-center">Data Tidak ada</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        
        <b-modal
          v-model="openModal"
          :size="modalConfig.size"
          no-close-on-backdrop
          hide-footer
          :title="modalConfig.title" 
        >
          <DetailKajian v-if="isModal == 'DetailKajian'" />     
          <DetailKajianMonitoring v-if="isModal == 'DetailKajianMonitoring'" />
        </b-modal>

      </template>
      <template v-else>
        <FormMonitoring v-if="typeForm == 'monitoring'" /> 
        <Form v-else />
      </template>
    </div>

  
    <b-modal v-model="openDetailKajian" :title="row.arm_is_kajian_awal == 'Y' ? 'Detail Kajian Awal': 'Detail Kajian Lanjutan'" size="xl" hide-footer>
        <DetailKajianAwal v-bind="passToSub" v-if="row.arm_is_kajian_awal == 'Y'" />
        <DetailKajianLanjutan v-bind="passToSubLanjutan" v-else />
    </b-modal>
    
        
    <b-modal v-model="openRwiayatRM" :title="'Detail Rekam Medis'" size="xl" hide-footer>
        <RiwayatRM v-bind="passToSubRM" />
    </b-modal>        
    
    <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
        <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
    </b-modal>
    
    <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
        <RiwayatRMUGD v-bind="passToSubRMUGD" />
    </b-modal>'

</div>
</template>


<script>
  import $ from 'jquery'
  const _ = global._
  import Gen from '@/libs/Gen.js'

  import Form from './Form.vue'
  import FormMonitoring from './FormMonitoring.vue'
  import DetailKajian from './DetailKajian.vue'
  import DetailKajianMonitoring from './DetailKajianMonitoring.vue'

  const moment = require('moment')

  import DetailKajianAwal from '@/components/RiwayatPOLI/DetailKajianAwal.vue'
  import DetailKajianLanjutan from '@/components/RiwayatPOLI/DetailKajianLanjutan.vue'
  import RiwayatRM from '@/components/RiwayatPOLI/RiwayatRM.vue'

  import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD2.vue'
  import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD2.vue'
  
  export default {
    props: {
        row: Object,
        mrValidation: Object,
        Config: Object,
        rowReg: Object,
        mKesadaran: Array,
        informConcent: Array
    },
    data() {
      return {
        openDetailKajian: false,
        openDetailKajianPerawat: false,
        openRwiayatRM: false,
        dataRiwayatRMDokter: {},
        dataRiwayatRMDokterTerapi: {},
        dataRiwayatRMDokterGizi: {},
        dataRiwayatRMFisio: {},
        dataRiwayatRMRadiologi: {},
        dataRiwyatRMPerawat: {},
        dataRiwayatRMLab: {},
        dataRiwyatRMPerawatIS: '',
        historyKajianPerawat: [],
        historyKajianDokter: [],
        historyKajianDokterTerapi: [],
        historyKajianDokterGizi: [],
        historyKajianFisio: [],
        historyKajianRadiologi: [],
        historyKajianLab: [],
        dataRiwayatRMFollowUp: {},
        openDetailKajianPerawatUGD: false,
        openRiwayatRMUGD: false,
        dataKajianPerawat: {},
        dataKajianDokter: {},
        historiKajianPerawat: [],
        historiKajianDokter: [],
        masterKajianPerawat: {},
        masterKajianDokter: {},
        masterTriase: {},
        dataKajianTriase: {},
        historiKajianRadiologi: [],
        historiKajianLab: [],
        historiKajianTriase: [],
        dataKajianRadiologi: {},
        dataKajianLab: {},
        dataKajianFarmasi: [],
        dataUGDReservasi: {},
        activeTabInformasi: 1,    
        resepDokter: [],     
        openPengantar: false,
        pengantarRanap: {},

        dataKajian: [],
        modalConfig: {
          title: '',
          size: 'sm'
        },
        isModal: '',
        rowEdit: {},
        openModal: false,

        patientData: {},
        loading: {
            patientInfo: false,
        }
      }
    },
    computed: {
      isListDetail(){ return !this.$route.query.kajianNo },  
      typeForm(){ return this.$route.query.typeForm },
      
      passToSub() {
          return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          mPekerjaan: this.mPekerjaan,
          mAlatBantu: this.mAlatBantu,
          mSkrininGizi: this.mSkrininGizi,
          mSkrininGiziV2: this.mSkrininGiziV2,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSLKI: this.mSLKI,
          mSIKI: this.mSIKI,
          mPoli: this.mPoli,
          mDokter: this.mDokter,
          mKesadaran: this.mKesadaran,
          resepDokter: this.resepDokter,
          historyKajianPerawat: this.historyKajianPerawat
          })
      },
      passToSubLanjutan() {
          return _.assign({
          isAdd: this.isAdd,
          mrValidation: this.mrValidation,
          Config: this.Config,
          row: this.row,
          rowReg: this.rowReg,
          diagnosa: this.diagnosa,
          mSDKI: this.mSDKI,
          mSIKI: this.mSIKI,
          mKesadaran: this.mKesadaran,
          resepDokter: this.resepDokter,
          historyKajianPerawat: this.historyKajianPerawat
          })
      },
      passToSubRM() {
          return _.assign({
          dataRiwayatRMDokter: this.dataRiwayatRMDokter,
          dataRiwayatRMDokterTerapi: this.dataRiwayatRMDokterTerapi,
          dataRiwayatRMDokterGizi: this.dataRiwayatRMDokterGizi,
          dataRiwayatRMFisio: this.dataRiwayatRMFisio,
          dataRiwayatRMRadiologi: this.dataRiwayatRMRadiologi,
          dataRiwayatRMLab: this.dataRiwayatRMLab,
          dataRiwayatRMFarmasi: this.dataRiwayatRMFarmasi,

          historyKajianPerawat: this.historyKajianPerawat,
          historyKajianDokter: this.historyKajianDokter,
          historyKajianDokterTerapi: this.historyKajianDokterTerapi,
          historyKajianDokterGizi: this.historyKajianDokterGizi,
          historyKajianFisio: this.historyKajianFisio,
          historyKajianRadiologi: this.historyKajianRadiologi,
          historyKajianLab: this.historyKajianLab,

          row: this.row,
          rowReg: this.rowReg,
          dataRiwayatRMFollowUp: this.dataRiwayatRMFollowUp,
          })
      },

      
      passToSubPerawatUGD(){
          return _.assign({
              isAdd:this.isAdd,
              mrValidation:this.mrValidation,
              Config:this.Config,
              row: this.row,
              rowReg: this.rowReg,
              dataKajianPerawat: this.dataKajianPerawat,
              historiKajianPerawat: this.historiKajianPerawat,
              masterKajianPerawat: this.masterKajianPerawat,
          })
      },

      passToSubRMUGD(){
          return _.assign({
              isAdd:this.isAdd,
              mrValidation:this.mrValidation,
              Config:this.Config,
              row: this.row,
              rowReg: this.rowReg,
              dataKajianDokter: this.dataKajianDokter,
              dataKajianTriase: this.dataKajianTriase,
              historiKajianDokter: this.historiKajianDokter,
              masterKajianDokter: this.masterKajianDokter,
              masterTriase: this.masterTriase,
              historiKajianRadiologi: this.historiKajianRadiologi,
              historiKajianLab: this.historiKajianLab,
              historiKajianTriase: this.historiKajianTriase,
              dataKajianRadiologi: this.dataKajianRadiologi,
              dataKajianLab: this.dataKajianLab,
              dataKajianFarmasi: this.dataKajianFarmasi,
              dataUGDReservasi: this.dataUGDReservasi
          })
      },
    },
    components: {
      Form,
      FormMonitoring,
      DetailKajian,
      DetailKajianMonitoring,

      DetailKajianAwal,
      DetailKajianLanjutan,
      RiwayatRM,
      KajianPerawatUGD,
      RiwayatRMUGD
    },
    methods: {
      back(){
        this.$router.push({name : 'UGDTindakLanjut'}).catch({})
      },
      initSticky() {
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if (PageHeaderSticky) {
          const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
          if ($(".page-header-sticky").length && $(".page-header-sticky-toggle").length) {
            PageHeaderToggle.addEventListener('click', function (e) {
              // e.preventDefault()
              PageHeaderSticky.classList.toggle('page-header-sticky-open')
              if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                PageHeaderToggle.querySelector('span').innerText =
                    'Sembunyikan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
              } else {
                PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
              }
            })
          }
        }
      },
      getConfigDynamic(master,value){
          let text = ''
          if(value){
              let index = (master||[]).findIndex(x => x.value == value)
              if(index !== -1){
                  text = master[index]['text']
              }
          }
          return text
      },
      doOpenRiwayatRMUGD(v){
          this.$parent.loadingOverlay = true
          
          if(v.kajianDokter){
              Gen.apiRest(
                  "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.openRiwayatRMUGD = true    
                  this.dataKajianDokter = res.data
                  this.$parent.loadingOverlay = false
              })

              Gen.apiRest(
                  "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aur_id+'&master=1', 
              ).then(res=>{
                  this.masterKajianDokter = res.data
              })     
          }
          

          if(v.triase_id){                
              Gen.apiRest(
                  "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianTriase = res.data
              })
          }
                    
          Gen.apiRest(
              "/get/"+'UGDTriase'+'/'+'master', 
          ).then(res=>{
              this.masterTriase = res.data
          })

          
          if(v.kajianLab){
              Gen.apiRest(
                  "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianLab = res.data
              })
          }
          
          if(v.kajianRadiologi){
              Gen.apiRest(
                  "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aur_id, 
              ).then(res=>{
                  this.dataKajianRadiologi = res.data
              })
          }

          Gen.apiRest(
              "/do/" + 'UGDAssesment', {
                  data: {
                  type: 'get-obat-farmasi',
                  regId: v.aur_id
                  }
              }
          ).then(res => {
              let resp = res.data
              this.dataKajianFarmasi = resp.data
              this.dataUGDReservasi = resp.dataUGDReservasi
              this.$parent.loadingOverlay = false
          })
          
          
          this.openRiwayatRMUGD = true
      },
      toDetailKajianPerawatUGD(){
          this.$parent.loadingOverlay = true
          Gen.apiRest(
              "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id, 
          ).then(res=>{
              this.openDetailKajianPerawatUGD = true    
              this.dataKajianPerawat = res.data
              this.$parent.loadingOverlay = false
          })
          Gen.apiRest(
              "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+this.row.kajianPerawat+'?regId='+this.row.aur_id+'&master=1', 
          ).then(res=>{
              this.masterKajianPerawat = res.data
          })
      },
      doOpenRiwayatRMV2(v) {
          this.$parent.loadingOverlay = true
          if (v.kajianDokter) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokter + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokter = res.data
              this.dataRiwayatRMDokter.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokter = {}
          }

          if (v.kajianDokterTerapi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianDokterTerapi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokterTerapi = res.data
              this.dataRiwayatRMDokterTerapi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokterTerapi = {}
          }

          if (v.kajianGizi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianGizi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMDokterGizi = res.data
              this.dataRiwayatRMDokterGizi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMDokterGizi = {}
          }

          if (v.kajianFisio) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianFisio + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMFisio = res.data
              this.dataRiwayatRMFisio.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMFisio = {}
          }

          if (v.kajianRadiologi) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianRadiologi + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMRadiologi = res.data
              this.dataRiwayatRMRadiologi.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMRadiologi = {}
          }

          if (v.kajianLab) {
          Gen.apiRest(
              "/get/" + 'RekamMedis' + '/' + this.row.ap_id + '/' + v.kajianLab + '?regId=' + v.ar_id,
          ).then(res => {
              this.$parent.loadingOverlay = false
              this.dataRiwayatRMLab = res.data
              this.dataRiwayatRMLab.Config = this.Config
              this.openRwiayatRM = true
          })
          } else {
          this.dataRiwayatRMLab = {}
          }

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-obat-farmasi',
              regId: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          this.dataRiwayatRMFarmasi = resp.data
          this.$parent.loadingOverlay = false
          })

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          // non ppa
          this.historyKajianPerawat = resp.historyKajianPerawat
          this.historyKajianDokter = resp.historyKajianDokter
          this.historyKajianDokterTerapi = resp.historyKajianDokterTerapi
          
          this.$parent.loadingOverlay = false
          })

          Gen.apiRest(
          "/do/" + 'RekamMedis', {
              data: {
              type: 'get-riwayat-rekam-medis',
              ar_id: v.ar_id
              }
          }
          ).then(res => {
          let resp = res.data
          this.$parent.loadingOverlay = false
          
          this.historyKajianDokterGizi = resp.historyKajianDokterGizi
          this.historyKajianFisio = resp.historyKajianFisio
          this.historyKajianRadiologi = resp.historyKajianRadiologi
          this.historyKajianLab = resp.historyKajianLab
          })


          Gen.apiRest(
          "/get/" + 'FollowUp' + '/' + this.row.ap_id + '?regId=' + v.ar_id,
          ).then(res => {
          this.$parent.loadingOverlay = false
          this.dataRiwayatRMFollowUp = res.data
          this.dataRiwayatRMFollowUp.Config = this.Config
          this.openRwiayatRM = true
          })


          setTimeout(() => {
          this.$parent.loadingOverlay = false
          this.openRwiayatRM = true
          }, 2000)
      },
      toValidate(val) {
          return {
              ...val
          }
      },

      // kajian fibrinnolisis
      toModal(row,size,title,isModal){
        this.rowEdit = row
        this.modalConfig = {
          title: title,
          size: size
        }
        this.isModal = isModal
        this.openModal = true
      },
      
      hapusTindakan(row){
        this.$swal({
          icon: 'warning',
          title: 'Hapus Kajian Fibrinolisis?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'

        }).then(result => {
          if(result.value){
            this.loadingOverlay = true
            let data = {
              id : row.aukpks_id
            }
            data.type = 'hapus-kajian'
            Gen.apiRest(
                "/do/"+"UGDKajianStriptokinase",
                {data: data}, 
                "POST"
            ).then(res=>{
              this.loadingOverlay = false
              this.$swal({
                title: "Kajian Fibrinolisis Berhasil DiHapus",
                icon: 'success',
                confirmButtonText: 'Ok'
              })
              this.apiGet()
            })
          }
        })
      },
      addKajian(){
        this.loadingOverlay = true
        let data = {
          regId : this.rowReg.aur_id
        }
        data.type = 'init-kajian'
        Gen.apiRest(
            "/do/"+"UGDKajianStriptokinase",
            {data: data}, 
            "POST"
        ).then(res=>{
            let resp = res.data
            this.loadingOverlay = false
            this.$router.push({ 
              name: this.modulePage, 
              params: {pageSlug: this.rowReg.aur_id},
              query : {kajianNo: resp.aukpks_id, regId: this.rowReg.aur_id, isStreptokinase: true}
            })
            .catch(()=>{})
        })
      },
      toForm(row,form=''){
        if(form){
          this.$router.push({ 
            name: this.modulePage, 
            params: {
              pageSlug: this.rowReg.aur_id, 
            },
            query: {form: form, isStreptokinase: true}
          })
          .catch(()=>{})
        }else{
          if(row.aukpks_is_parent !='Y'){
            this.$router.push({ 
              name: this.modulePage, 
              params: {pageSlug: this.rowReg.aur_id},
              query:{typeForm:'monitoring', kajianNo: row.aukpks_id, isStreptokinase: true}
            })
            .catch(()=>{})          
          }else{  
            this.$router.push({ 
              name: this.modulePage, 
              params: {pageSlug: this.rowReg.aur_id},
              query:{kajianNo: row.aukpks_id, isStreptokinase: true}
            })
            .catch(()=>{})            
          }
        }
      },
      apiGet(){
        Gen.apiRest(
            "/get/"+'UGDKajianStriptokinase'+'/'+this.$route.params.pageSlug, 
        ).then(res=>{
            this.dataKajian = res.data.dataKajian
            this.$parent.loadingOverlay = false
        })
      }
    },
    mounted() {
      setTimeout(() => {
          this.initSticky()
      }, 1000)
      this.apiGet()
    },
    watch:{
      $route(){
        this.apiGet()
      }
    }
  }

</script>