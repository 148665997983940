<template>
  <div class="content">
    
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
        <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Kajian Awal BBL</h6>
        </div>
      </div>
    </div>
    <template v-if="!isDetail">
      <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit()">
          <div class="card">
            <div class="card-body"> 
                <div class="wrap_line">
                    <h3> <span>Identitas</span></h3>
                    <div class="mb-3">
                    <table class="table table-bordered">
                        <tr>
                        <td width="16%">
                        <div class="result_tab">
                            <h4>Nama Bayi</h4>
                            <p>{{rowPasien.ap_fullname||"-"}}</p>
                        </div>
                        </td>
                        <td width="16%">
                        <div class="result_tab">
                            <h4>Tanggal Lahir</h4>
                            <p>{{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                        </div>
                        </td>
                        <td width="16%">
                        <div class="result_tab">
                            <h4>Jenis Kelamin</h4>
                            <p>{{getConfigDynamic(Config.mr.StatusKelamin,rowPasien.ap_gender)||"-"}}</p>
                        </div>
                        </td>
                        <td width="16%">
                        <div class="result_tab">
                            <h4>Nama Ayah</h4>
                            <p>{{rowPasien.arpdo_ayah_nama||"-"}}</p>
                        </div>
                        </td>
                        <td width="16%">
                            <div class="result_tab">
                            <h4>Anak Ke- <strong class="text-danger">*</strong></h4>
                            <b-form-input v-model="rowMedisBBL.aukmb_anak_ke" :formatter="numberOnly" type="text"
                            class="form-control" />
                            <VValidate name="Anak Ke- " v-model="rowMedisBBL.aukmb_anak_ke"
                            :rules="toValidate(mrValidationKAMedis.aukmb_anak_ke)" />
                            </div>
                        </td>
                        <td width="16%">
                            <div class="result_tab">
                            <h4>Lahir dengan cara</h4>
                            <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_lahir_dengan_cara"
                                :options="Config.mr.mCaraLahir" label="text" :reduce="v=>v.value">
                            </v-select>
                            </div>
                        </td>
                        </tr>
                    </table>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-sm table-bordered mt-3">
                            <thead>
                                <tr>
                                <th class="bg-success" colspan="2">Biodata Ibu</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Nama Ibu</td>
                                <td>{{rowPasien.arpdo_ibu_nama||"-"}}</td>
                                </tr>
                                <tr>
                                <td>Tanggal Lahir</td>
                                <td>
                                    <span v-if="rowPasien.arpdo_ibu_dob">
                                    {{rowPasien.arpdo_ibu_dob | moment("DD MMMM YYYY")}}
                                    </span>
                                    <span v-else> - </span>
                                </td>
                                </tr>
                                <tr>
                                <td>Umur</td>
                                <td>
                                    <span v-if="rowPasien.arpdo_ibu_dob">
                                    {{ rowPasien.arpdo_ibu_dob | moment("from", "now", true) }}
                                    </span>
                                    <span v-else> - </span>
                                </td>
                                
                                </tr>
                                <tr>
                                <td>Suku/bangsa </td>
                                <td>{{rowMedisBBL.ibu_suku||"-"}}</td>
                                </tr>
                                <tr>
                                <td>Agama </td>
                                <td>{{rowMedisBBL.ibu_agama||"-"}}
                                <span v-if="rowMedisBBL.ibu_agama == 'Lain-lain'">: {{rowPasien.arpdo_ibu_agama_text||"-"}}</span>
                                </td>
                                </tr>
                                <tr>
                                <td>Pendidikan </td>
                                <td>{{rowMedisBBL.ibu_pendidikan||"-"}}
                                <span v-if="rowMedisBBL.ibu_pendidikan == 'Lain-lain'">: {{rowPasien.arpdo_ibu_pendidikan_text||"-"}}</span>
                                </td>
                                
                                </tr>
                                <tr>
                                <td>Pekerjaan</td>
                                <td>{{rowMedisBBL.ibu_pekerjaan||"-"}}
                                <span v-if="rowMedisBBL.ibu_pekerjaan == 'Lain-lain'">: {{rowPasien.arpdo_ibu_pekerjaan_text||"-"}}</span>
                                </td>
                                </tr>
                                <tr>
                                <td>Alamat </td>
                                <td>{{rowPasien.arpdo_ibu_alamat_ktp||"-"}}</td>
                                </tr>
                                <tr>
                                <td>Status Perkawinan </td>
                                <td>{{getConfigDynamic(Config.mr.StatusPernikahan,rowPasien.arpdo_ibu_status_perkawinan)||"-"}}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="col-md-6">
                            <table class="table table-sm table-bordered mt-3">
                            <thead>
                                <tr>
                                <th class="bg-primary" colspan="2">Biodata Ayah</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>Nama Ayah</td>
                                <td>{{rowPasien.arpdo_ayah_nama||"-"}}</td>
                                </tr>
                                <tr>
                                <td>Tanggal Lahir</td>
                                <td>
                                    <span v-if="rowPasien.arpdo_ayah_dob">
                                    {{rowPasien.arpdo_ayah_dob | moment("DD MMMM YYYY")}}
                                    </span>
                                    <span v-else> - </span>
                                </td>
                                </tr>
                                <tr>
                                <td>Umur</td>
                                <td>
                                    <span v-if="rowPasien.arpdo_ayah_dob">
                                    {{ rowPasien.arpdo_ayah_dob | moment("from", "now", true) }}
                                    </span>
                                    <span v-else> - </span>
                                </td>
                                
                                </tr>
                                <tr>
                                <td>Suku/bangsa </td>
                                <td>{{rowMedisBBL.ayah_suku||"-"}}</td>
                                </tr>
                                <tr>
                                <td>Agama </td>
                                <td>{{rowMedisBBL.ayah_agama||"-"}}
                                <span v-if="rowMedisBBL.ayah_agama == 'Lain-lain'">: {{rowPasien.arpdo_ayah_agama_text||"-"}}</span>
                                </td>
                                </tr>
                                <tr>
                                <td>Pendidikan </td>
                                <td>{{rowMedisBBL.ayah_pendidikan||"-"}}
                                <span v-if="rowMedisBBL.ayah_pendidikan == 'Lain-lain'">: {{rowPasien.arpdo_ayah_pendidikan_text||"-"}}</span>
                                </td>
                                </tr>
                                <tr>
                                <td>Pekerjaan</td>
                                <td>{{rowMedisBBL.ayah_pekerjaan||"-"}}
                                <span v-if="rowMedisBBL.ayah_pekerjaan == 'Lain-lain'">: {{rowPasien.arpdo_ayah_pekerjaan_text||"-"}}</span>
                                </td>
                                </tr>
                                <tr>
                                <td>Alamat </td>
                                <td>{{rowPasien.arpdo_ayah_alamat_ktp||"-"}}</td>
                                </tr>
                                <tr>
                                <td>Status Perkawinan </td>
                                <td>{{getConfigDynamic(Config.mr.StatusPernikahan,rowPasien.arpdo_ayah_status_perkawinan)||"-"}}</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="wrap_line">
                    <h3><span>Riwayat Kesehatan</span></h3>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                            <label for="">Riwayat Ibu</label>
                                <div>
                                <div class="custom-control custom-radio custom-control-inline pl-0">
                                    <b-form-radio-group :options="Config.mr.mConfigRiwayat"
                                    v-model="rowMedisBBL.aukmb_riwayat_ibu" />
                                    <div class="ml-2 col-md-4" v-if="rowMedisBBL.aukmb_riwayat_ibu == 'Y'">
                                    <b-form-input :formatter="normalText" v-model="rowMedisBBL.aukmb_riwayat_ibu_text"
                                        type="text" class="form-control" />
                                    <VValidate name="Riwayat Ibu" v-model="rowMedisBBL.aukmb_riwayat_ibu_text"
                                        :rules="{required: 1, min:3, max:128}" />
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    <!--<div class="col-md-3">
                        <div class="form-group">
                        <label for="">Riwayat Keluarga</label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline"><input type="radio"
                                name="RiwayatKeluarga1" id="RiwayatKeluarga1" class="custom-control-input"><label
                                for="RiwayatKeluarga1" class="custom-control-label">Tidak Ada</label></div>
                            <div class="custom-control custom-radio custom-control-inline"><input type="radio"
                                name="RiwayatKeluarga2" id="RiwayatKeluarga2" class="custom-control-input"><label
                                for="RiwayatKeluarga2" class="custom-control-label">Ada</label></div>
                        </div>
                        </div>
                    </div>-->
                    </div>
                </div>

                <div class="wrap_line">
                    <h3><span>Pemeriksaan Fisik</span></h3>
                    <div class="card mb-2">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Status Praesens</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                            <label for="JenisKelamin">Jenis Kelamin <strong class="text-danger">*</strong> </label>
                            <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_jenis_kelamin"
                                :options="Config.mr.genderText" label="text" :reduce="v=>v.value">
                            </v-select>
                            <VValidate name="Jenis Kelamin" v-model="rowMedisBBL.aukmb_jenis_kelamin"
                            :rules="toValidate(mrValidationKAMedis.aukmb_jenis_kelamin)" />
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                                <label for="bayiBeratBadan">Berat Badan <strong class="text-danger">*</strong></label>
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="rowMedisBBL.aukmb_berat_badan" type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">gram</span></div>
                                </div>
                                <VValidate name="Berat Badan" v-model="rowMedisBBL.aukmb_berat_badan"
                                :rules="toValidate(mrValidationKAMedis.aukmb_berat_badan)" />
                            </div>
                        </div>

                        <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                            <label for="bayiPanjang">Panjang Badan<strong class="text-danger">*</strong></label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="rowMedisBBL.aukmb_panjang" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span></div>
                            </div>
                            <VValidate name="Panjang Badan" v-model="rowMedisBBL.aukmb_panjang"
                            :rules="toValidate(mrValidationKAMedis.aukmb_panjang)" />
                            </div>
                        </div>

                        <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                            <label for="LingkarKepala">Lingkar Kepala <strong class="text-danger">*</strong></label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="rowMedisBBL.aukmb_lingkar_kepala" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span></div>
                            </div>
                            <VValidate name="Lingkar Kepala" v-model="rowMedisBBL.aukmb_lingkar_kepala"
                            :rules="toValidate(mrValidationKAMedis.aukmb_lingkar_kepala)" />
                            </div>
                        </div>

                        <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                            <label for="bayiPanjang">Lingkar Dada <strong class="text-danger">*</strong></label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="rowMedisBBL.aukmb_lingkar_dada" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">cm</span></div>
                            </div>
                            <VValidate name="Lingkar Dada" v-model="rowMedisBBL.aukmb_lingkar_dada"
                            :rules="toValidate(mrValidationKAMedis.aukmb_lingkar_dada)" />
                            </div>
                        </div>


                        <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                            <label>Nadi<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="rowMedisBBL.aukmb_nadi" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            </div>
                            <VValidate name="Nadi" v-model="rowMedisBBL.aukmb_nadi"
                            :rules="toValidate(mrValidationKAMedis.aukmb_nadi)" />
                            </div>
                        </div>

                        <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                            <label>RR<small class="txt_mandatory">*</small></label>
                            <div class="input-group">
                                <b-form-input :formatter="number" v-model="rowMedisBBL.aukmb_rr" type="text" class="form-control" />
                                <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                            </div>
                            <VValidate name="RR" v-model="rowMedisBBL.aukmb_rr"
                            :rules="toValidate(mrValidationKAMedis.aukmb_rr)" />
                            </div>
                        </div>

                        <div class="col-md-3 col-lg-2">
                            <div class="form-group">
                                <label>Suhu Badan<small class="txt_mandatory">*</small></label>
                                <div class="input-group">
                                    <b-form-input :formatter="number"
                                    v-model="rowMedisBBL.aukmb_suhu" type="text"
                                    class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">Celcius</span></div>
                                </div>
                                <VValidate name="Suhu Badan" v-model="rowMedisBBL.aukmb_suhu"
                                :rules="toValidate(mrValidationKAMedis.aukmb_suhu)" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="card mb-2">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                            <div class="mb-2">
                                <label for="Kesan">Kesan </label>
                                <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_kesan"
                                    :options="Config.mr.mRanapBBLMedisKesan" label="text" :reduce="v=>v.value">
                                </v-select>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                            <div class="mb-2">
                                <label for="Warna">Warna </label>
                                <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_warna"
                                    :options="Config.mr.mRanapBBLMedisWarna" label="text" :reduce="v=>v.value">
                                </v-select>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                            <div class="mb-2">
                                <label for="Pernafasan">Pernafasan </label>
                                <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_pernafasan"
                                    :options="Config.mr.mRanapBBLMedisNafas" label="text" :reduce="v=>v.value">
                                </v-select>
                            </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                            <div class="mb-2">
                                <label for="Tonus">Tonus </label>
                                <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_tonus"
                                    :options="Config.mr.mRanapBBLMedisTonus" label="text" :reduce="v=>v.value">
                                </v-select>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="wrap_line">
                    <h3><span>Pemeriksaan Khusus</span></h3>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-2">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Kepala <span class="text-danger">*</span></h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row g-0">
                                <div class="col-md-6">
                                    
                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="bentukOpen = !bentukOpen" :class="bentukOpen ? null : 'collapsed'"
                                        :aria-expanded="bentukOpen ? 'true' : 'false'" aria-controls="bentukOpen">
                                        <div class="font-weight-semibold">Bentuk</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'bentukOpen'" v-model="bentukOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLBentuk"
                                            v-model="rowMedisBBL.aukmb_kepala_bentuk" />
                                        </div>
                                        <div class="gen-input mt-3">
                                            <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <textarea v-model="rowMedisBBL.aukmb_kepala_bentuk_text" class="form-control"
                                                placeholder="Tuliskan" rows="2"></textarea>
                                            </div>
                                            </div>
                                        </div>
                                                    
                                        <VValidate name="Tipe Bentuk" v-model="rowMedisBBL.aukmb_kepala_bentuk"
                                        :rules="{required: 1}" />
                                        
                                        <!--
                                        <VValidate name="Bentuk" v-model="rowMedisBBL.aukmb_kepala_bentuk_text"
                                        :rules="{required: 1}" />
                                        -->
                                        
                                        </div>
                                    </b-collapse>
                                    </div>


                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="suturaeOpen = !suturaeOpen" :class="suturaeOpen ? null : 'collapsed'"
                                        :aria-expanded="suturaeOpen ? 'true' : 'false'" aria-controls="suturaeOpen">
                                        <div class="font-weight-semibold">Suturae</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'suturaeOpen'" v-model="suturaeOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mNormalTidakNormalText"
                                            v-model="rowMedisBBL.aukmb_suturae" />
                                        </div>
                                        <div class="gen-input mt-3">
                                            <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <textarea v-model="rowMedisBBL.aukmb_suturae_text" class="form-control"
                                                placeholder="Tuliskan" rows="2"></textarea>
                                            </div>
                                            </div>
                                        </div>
                                        <VValidate name="Tipe Suturae" v-model="rowMedisBBL.aukmb_suturae"
                                        :rules="{required: 1}" />
                                        
                                        <!--
                                        <VValidate name="Suturae" v-model="rowMedisBBL.aukmb_suturae_text"
                                        :rules="{required: 1}" />
                                        -->
                                        </div>
                                    </b-collapse>
                                    </div>


                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="fontanelOpen = !fontanelOpen" :class="fontanelOpen ? null : 'collapsed'"
                                        :aria-expanded="fontanelOpen ? 'true' : 'false'" aria-controls="fontanelOpen">
                                        <div class="font-weight-semibold">Fontanel</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'fontanelOpen'" v-model="fontanelOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLFontanel"
                                            v-model="rowMedisBBL.aukmb_fontanel" />
                                        </div>
                                        
                                        <VValidate name="Fontanel" v-model="rowMedisBBL.aukmb_fontanel"
                                        :rules="{required: 1}" />

                                        </div>
                                    </b-collapse>
                                    </div>

                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="caputOpen = !caputOpen" :class="caputOpen ? null : 'collapsed'"
                                        :aria-expanded="caputOpen ? 'true' : 'false'" aria-controls="caputOpen">
                                        <div class="font-weight-semibold">Caput Succedaneum</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'caputOpen'" v-model="caputOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mAdaTidakAdaText"
                                            v-model="rowMedisBBL.aukmb_caput" />
                                        </div>
                                        
                                        <VValidate name="Caput" v-model="rowMedisBBL.aukmb_caput"
                                        :rules="{required: 1}" />
                                        </div>
                                    </b-collapse>
                                    </div>

                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="septalOpen = !septalOpen" :class="septalOpen ? null : 'collapsed'"
                                        :aria-expanded="septalOpen ? 'true' : 'false'" aria-controls="septalOpen">
                                        <div class="font-weight-semibold">Septal hematoma</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'septalOpen'" v-model="septalOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mAdaTidakAdaText"
                                            v-model="rowMedisBBL.aukmb_septal_hematoma" />
                                        </div>
                                        <VValidate name="Septal" v-model="rowMedisBBL.aukmb_septal_hematoma"
                                        :rules="{required: 1}" />
                                        </div>
                                    </b-collapse>
                                    </div>

                                    <!--
                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="mataOpen = !mataOpen" :class="mataOpen ? null : 'collapsed'"
                                        :aria-expanded="mataOpen ? 'true' : 'false'" aria-controls="mataOpen">
                                        <div class="font-weight-semibold">Mata</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div
                                    <b-collapse :id="'mataOpen'" v-model="mataOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mAdaTidakAdaText"
                                            v-model="rowMedisBBL.aukmb_mata" />
                                        </div>
                                        <div class="gen-input mt-3">
                                            <div class="form-row">
                                            <div class="form-group col-md-12">
                                                <textarea v-model="rowMedisBBL.aukmb_mata_text" class="form-control"
                                                placeholder="Tuliskan" rows="2"></textarea>
                                            </div>
                                            </div>

                                            <VValidate name="Tipe Suturae" v-model="rowMedisBBL.aukmb_suturae"
                                            :rules="{required: 1}" />
                                            
                                            <VValidate name="Suturae" v-model="rowMedisBBL.aukmb_suturae_text"
                                            :rules="{required: 1}" />

                                        </div>
                                        </div>
                                    </b-collapse>
                                    </div>
                                    -->
                                    
                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="palatumOpen = !palatumOpen" :class="palatumOpen ? null : 'collapsed'"
                                        :aria-expanded="palatumOpen ? 'true' : 'false'" aria-controls="palatumOpen">
                                        <div class="font-weight-semibold">Palatum</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'palatumOpen'" v-model="palatumOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLBibir"
                                            v-model="rowMedisBBL.aukmb_palatum" />
                                        </div>
                                        <VValidate name="Palatum" v-model="rowMedisBBL.aukmb_palatum"
                                        :rules="{required: 1}" />
                                        </div>
                                    </b-collapse>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="telingaOpen = !telingaOpen" :class="telingaOpen ? null : 'collapsed'"
                                        :aria-expanded="telingaOpen ? 'true' : 'false'" aria-controls="telingaOpen">
                                        <div class="font-weight-semibold">Telinga</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'telingaOpen'" v-model="telingaOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLTelinga"
                                            v-model="rowMedisBBL.aukmb_telinga" />
                                        </div>
                                        <VValidate name="Tipe Telinga" v-model="rowMedisBBL.aukmb_telinga"
                                        :rules="{required: 1}" />
                                        
                                        </div>
                                    </b-collapse>
                                    </div>

                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="hidungOpen = !hidungOpen" :class="hidungOpen ? null : 'collapsed'"
                                        :aria-expanded="hidungOpen ? 'true' : 'false'" aria-controls="hidungOpen">
                                        <div class="font-weight-semibold">Hidung</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'hidungOpen'" v-model="hidungOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLHidung"
                                            v-model="rowMedisBBL.aukmb_hidung" />
                                        </div>
                                        <VValidate name="Hidung" v-model="rowMedisBBL.aukmb_hidung"
                                        :rules="{required: 1}" />
                                        </div>
                                    </b-collapse>
                                    </div>

                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="mulutOpen = !mulutOpen" :class="mulutOpen ? null : 'collapsed'"
                                        :aria-expanded="mulutOpen ? 'true' : 'false'" aria-controls="mulutOpen">
                                        <div class="font-weight-semibold">Mulut</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'mulutOpen'" v-model="mulutOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLMulut"
                                            v-model="rowMedisBBL.aukmb_mulut" />
                                        </div>
                                        <VValidate name="Mulut" v-model="rowMedisBBL.aukmb_mulut"
                                        :rules="{required: 1}" />
                                        </div>
                                    </b-collapse>
                                    </div>

                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="bibirOpen = !bibirOpen" :class="bibirOpen ? null : 'collapsed'"
                                        :aria-expanded="bibirOpen ? 'true' : 'false'" aria-controls="bibirOpen">
                                        <div class="font-weight-semibold">Bibir</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'bibirOpen'" v-model="bibirOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLBibir"
                                            v-model="rowMedisBBL.aukmb_bibir" />
                                        </div>
                                        <VValidate name="Bibir" v-model="rowMedisBBL.aukmb_bibir"
                                        :rules="{required: 1}" />
                                        </div>
                                    </b-collapse>
                                    </div>

                                    <div class="gen-part">
                                    <div class="d-flex align-items-center justify-content-between gen-part-head"
                                        @click="lidahOpen = !lidahOpen" :class="lidahOpen ? null : 'collapsed'"
                                        :aria-expanded="lidahOpen ? 'true' : 'false'" aria-controls="lidahOpen">
                                        <div class="font-weight-semibold">Lidah</div>
                                        <i class="icon-arrow-down22"></i>
                                    </div>

                                    <b-collapse :id="'lidahOpen'" v-model="lidahOpen">
                                        <div class="gen-part-body mt-2">
                                        <div>
                                            <b-form-radio-group :options="Config.mr.mRanapBBLLidah"
                                            v-model="rowMedisBBL.aukmb_lidah" />
                                        </div>
                                        <VValidate name="Lidah" v-model="rowMedisBBL.aukmb_lidah"
                                        :rules="{required: 1}" />
                                        </div>
                                    </b-collapse>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                            <div class="col-md-12">
                                <div class="card mb-2">
                                <div class="card-header bg_head_panel">
                                    <h6 class="card-title font-weight-semibold">Leher <span class="text-danger">*</span></h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                        <div class="mb-2">
                                            <label for="Bentuk">Bentuk </label>
                                            <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_bentuk_leher"
                                                :options="Config.mr.mRanapBBLLeher" label="text" :reduce="v=>v.value">
                                            </v-select>
                                            <VValidate name="Bentuk" v-model="rowMedisBBL.aukmb_bentuk_leher"
                                            :rules="{required: 1}" />
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card mb-2">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Dada</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Pemeriksaan">Pemeriksaan </label>
                                        <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_dada_pemeriksaan"
                                            :options="Config.mr.mRanapBBLPemeriksaan" label="text" :reduce="v=>v.value">
                                        </v-select>
                                        
                                        <!--
                                        <VValidate name="Dada" v-model="rowMedisBBL.aukmb_dada_pemeriksaan"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Paruparu">Paru-paru </label>
                                        <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_dada_paru"
                                            :options="Config.mr.mRanapBBLParu" label="text" :reduce="v=>v.value">
                                        </v-select>
                                        
                                        <!--
                                        <VValidate name="Paru-Paru" v-model="rowMedisBBL.aukmb_dada_paru"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Jantung">Jantung</label>
                                        <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_dada_jantung"
                                            :options="Config.mr.mRanapBBLJantung" label="text" :reduce="v=>v.value">
                                        </v-select>
                                        
                                        <!--
                                        <VValidate name="Jantung" v-model="rowMedisBBL.aukmb_dada_jantung"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Perut">Perut</label>
                                        <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_dada_perut"
                                            :options="Config.mr.mRanapBBLPerut" label="text" :reduce="v=>v.value">
                                        </v-select>

                                        <!--
                                        <VValidate name="Perut" v-model="rowMedisBBL.aukmb_dada_perut"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="TaliPusat">Tali Pusat</label>
                                        <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_dada_tali_pusat"
                                            :options="Config.mr.mRanapBaikLayu" label="text" :reduce="v=>v.value">
                                        </v-select>
                                        
                                        <!--                                                
                                        <VValidate name="Tali Pusat" v-model="rowMedisBBL.aukmb_dada_tali_pusat"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="AnusRectum">Anus Rectum</label>
                                        <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_dada_anus_rectum"
                                            :options="Config.mr.mAnusLubang" label="text" :reduce="v=>v.value">
                                        </v-select>

                                        <!--                                                  
                                        <VValidate name="Anus Rectum" v-model="rowMedisBBL.aukmb_dada_anus_rectum"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="AnggotaGerak">Anggota Gerak</label>
                                        <v-select placeholder="--Pilih Item--" v-model="rowMedisBBL.aukmb_dada_anggota_gerak"
                                            :options="Config.mr.mRanapAnggotaGerak" label="text" :reduce="v=>v.value">
                                        </v-select>
                                        
                                        <!--                                                  <VValidate name="Anggota Gerak" v-model="rowMedisBBL.aukmb_dada_anggota_gerak"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Kelainan">Kelainan lain-lain</label>
                                        <b-form-input v-model="rowMedisBBL.aukmb_dada_kelainan_lain" :formatter="normalText" type="text"
                                        class="form-control" />
                                        
                                        <!--
                                        <VValidate name="Kelainan lain-lain" v-model="rowMedisBBL.aukmb_dada_kelainan_lain"
                                        :rules="{required: 1}" />
                                        -->
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2">
                        <div class="col-md-8">
                        <div class="card border shadow-0">
                            <div class="bg-light card-header py-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                                <a href="javascript:;" v-if="riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                                <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                                </a>
                            </div>
                            </div>
                            <div class="card-body">
                            <div class="form-group mb-0">
                                <div class="input-group">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                    <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                        v-model="inputICD10"
                                        @search="filterICD10($event,0)"
                                        @input="selectICD10(inputICD10)"
                                        :options="mICD10" label="text"
                                        :clearable="true" :reduce="v=>v.value">
                                        
                                        <template slot="selected-option">
                                        <span v-b-tooltip.hover.right :title="inputICD10Name">
                                            {{inputICD10Name||"-"}}
                                        </span>
                                        </template>
                                        
                                        <template slot="option" slot-scope="option">
                                        <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                        </template>
                                        <template #list-footer v-if="(mICD10||[]).length >= 1">
                                        <li class="list-paging">
                                            <div class="d-flex align-items-center justify-content-between">
                                            <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                                size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                class="icon-arrow-left22 text-white"></i></b-button>
                                            <small>Halaman {{mICD10[0].currentPage}} dari
                                                {{ceilData(mICD10[0].totalRows/5)}}</small>
                                            <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                                size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                class="icon-arrow-right22 text-white"></i></b-button>
                                            </div>
                                        </li>
                                        </template>
                                    </v-select>
                                </div>
                                </div>
                            </div>
                            </div>
                            <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                            <thead>
                                <tr>
                                <th>Item ICD-10 dipilih</th>
                                <th width="64">Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                                <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                                <td>
                                    <a href="javascript:;" @click="removedataICD10(v.audb_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                    <i class="icon-trash-alt"></i>
                                    </a>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                            <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                            </div>
                            <div class="card-body pt-2">
                            <div class="form-group mb-0">
                                <label for="inputManualDiagnose">
                                Diagnosa Lainnya <strong class="txt_mandatory" v-if="!(dataICD10||[]).length">*</strong>
                                <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                                </label>
                                <b-textarea v-model="rowMedisBBL.aukmb_diagnosa_text" name=""
                                id="" rows="3" class="form-control"></b-textarea>
                                
                            <VValidate v-if="!(dataICD10||[]).length" :name="`Diagnosa`" v-model="rowMedisBBL.aukmb_diagnosa_text"
                                :rules="{required:1, min:2, max:512}" />
                            </div>
                            </div>
                        </div>
                        </div>
                        
                    </div>

                    <div class="row mt-2">
                        <div class="col-md-8">
                            <div class="card border shadow-0">
                                <div class="bg-light card-header py-2">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="card-title mb-0 font-weight-semibold">RENCANA PENGOBATAN DAN TINDAKAN</h6>
                                    </div>
                                </div>
                                <div class="form-group mb-0 p-3">
                                    <b-textarea v-model="rowMedisBBL.aukmb_rencana_tindakan" name=""
                                    id="" rows="3" class="form-control"></b-textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
            <div class="card-footer">
                <div class="text-right">
                    <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                    <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </template>
    <template v-else>
        <div class="card">
            <div class="mt-2">
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Identitas</h6>
                </div>
                <div class="card-body p-3">
                    <table class="table table-bordered">
                    <tr>
                        <td width="20%">
                        <div class="result_tab">
                            <h4>Nama Bayi</h4>
                            <p>{{rowPasien.ap_fullname||"-"}}</p>
                        </div>
                        </td>
                        <td width="20%">
                        <div class="result_tab">
                            <h4>Tanggal Lahir</h4>
                            <p>
                            <span v-if="rowPasien.ap_dob">{{rowPasien.ap_dob|moment('DD MMMM YYYY')}}</span>
                            <span v-else> - </span>
                            </p>
                        </div>
                        </td>
                        <td width="20%">
                        <div class="result_tab">
                            <h4>Jenis Kelamin</h4>
                            <p>{{getConfigDynamic(Config.mr.StatusKelamin,rowPasien.ap_gender)||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Nama Ayah</h4>
                            <p>{{rowPasien.arpdo_ayah_nama||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Anak Ke-</h4>
                            <p>{{rowMedisBBL.aukmb_anak_ke||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Lahir dengan cara</h4>
                            <p>{{rowMedisBBL.aukmb_lahir_dengan_cara||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    </table>
                    <div class="row">
                    <div class="col-md-6">
                        <table class="table table-sm table-bordered mt-3">
                        <thead>
                            <tr>
                            <th class="bg-success" colspan="2">Biodata Ibu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Nama Ibu</td>
                            <td>{{rowPasien.arpdo_ibu_nama||"-"}}</td>
                            </tr>
                            <tr>
                            <td>Tanggal Lahir</td>
                            <td>
                                <span v-if="rowPasien.arpdo_ibu_dob">
                                {{rowPasien.arpdo_ibu_dob | moment("DD MMMM YYYY")}}
                                </span>
                                <span v-else> - </span>
                            </td>
                            </tr>
                            <tr>
                            <td>Umur</td>
                            <td>
                                <span v-if="rowPasien.arpdo_ibu_dob">
                                {{ rowPasien.arpdo_ibu_dob | moment("from", "now", true) }}
                                </span>
                                <span v-else> - </span>
                            </td>
                            
                            </tr>
                            <tr>
                            <td>Suku/bangsa </td>
                            <td>{{rowPasien.ibu_suku||"-"}}</td>
                            </tr>
                            <tr>
                            <td>Agama </td>
                            <td>{{rowPasien.ibu_agama||"-"}}
                            <span v-if="rowPasien.ibu_agama == 'Lain-lain'">: {{rowPasien.arpdo_ibu_agama_text||"-"}}</span>
                            </td>
                            </tr>
                            <tr>
                            <td>Pendidikan </td>
                            <td>{{rowPasien.ibu_pendidikan||"-"}}
                            <span v-if="rowPasien.ibu_pendidikan == 'Lain-lain'">: {{rowPasien.arpdo_ibu_pendidikan_text||"-"}}</span>
                            </td>
                            
                            </tr>
                            <tr>
                            <td>Pekerjaan</td>
                            <td>{{rowPasien.ibu_pekerjaan||"-"}}
                            <span v-if="rowPasien.ibu_pekerjaan == 'Lain-lain'">: {{rowPasien.arpdo_ibu_pekerjaan_text||"-"}}</span>
                            </td>
                            </tr>
                            <tr>
                            <td>Alamat </td>
                            <td>{{rowPasien.arpdo_ibu_alamat_ktp||"-"}}</td>
                            </tr>
                            <tr>
                            <td>Status Perkawinan </td>
                            <td>{{getConfigDynamic(Config.mr.StatusPernikahan,rowPasien.arpdo_ibu_status_perkawinan)||"-"}}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="col-md-6">
                        <table class="table table-sm table-bordered mt-3">
                        <thead>
                            <tr>
                            <th class="bg-primary" colspan="2">Biodata Ayah</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>Nama Ayah</td>
                            <td>{{rowPasien.arpdo_ayah_nama||"-"}}</td>
                            </tr>
                            <tr>
                            <td>Tanggal Lahir</td>
                            <td>
                                <span v-if="rowPasien.arpdo_ayah_dob">
                                {{rowPasien.arpdo_ayah_dob | moment("DD MMMM YYYY")}}
                                </span>
                                <span v-else> - </span>
                            </td>
                            </tr>
                            <tr>
                            <td>Umur</td>
                            <td>
                                <span v-if="rowPasien.arpdo_ayah_dob">
                                {{ rowPasien.arpdo_ayah_dob | moment("from", "now", true) }}
                                </span>
                                <span v-else> - </span>
                            </td>
                            
                            </tr>
                            <tr>
                            <td>Suku/bangsa </td>
                            <td>{{rowPasien.ayah_suku||"-"}}</td>
                            </tr>
                            <tr>
                            <td>Agama </td>
                            <td>{{rowPasien.ayah_agama||"-"}}
                            <span v-if="rowPasien.ayah_agama == 'Lain-lain'">: {{rowPasien.arpdo_ayah_agama_text||"-"}}</span>
                            </td>
                            </tr>
                            <tr>
                            <td>Pendidikan </td>
                            <td>{{rowPasien.ayah_pendidikan||"-"}}
                            <span v-if="rowPasien.ayah_pendidikan == 'Lain-lain'">: {{rowPasien.arpdo_ayah_pendidikan_text||"-"}}</span>
                            </td>
                            </tr>
                            <tr>
                            <td>Pekerjaan</td>
                            <td>{{rowPasien.ayah_pekerjaan||"-"}}
                            <span v-if="rowPasien.ayah_pekerjaan == 'Lain-lain'">: {{rowPasien.arpdo_ayah_pekerjaan_text||"-"}}</span>
                            </td>
                            </tr>
                            <tr>
                            <td>Alamat </td>
                            <td>{{rowPasien.arpdo_ayah_alamat_ktp||"-"}}</td>
                            </tr>
                            <tr>
                            <td>Status Perkawinan </td>
                            <td>{{getConfigDynamic(Config.mr.StatusPernikahan,rowPasien.arpdo_ayah_status_perkawinan)||"-"}}</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Riwayat Kesehatan</h6>
                </div>
                <div class="card-body p-3">
                    <table class="table table-bordered table-sm tr">
                    <td>
                        <div class="result_tab">
                        <h4>Riwayat Ibu</h4>
                        <p v-if="rowMedisBBL.aukmb_riwayat_ibu == 'Y'">Ada Riwayat Penyakit: {{rowMedisBBL.aukmb_riwayat_ibu_text||"-"}}</p>
                        <p v-else>Tidak ada Riwayat Penyakit</p>
                        </div>
                    </td>
                    </table>
                </div>
                </div>
                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
                </div>
                <div class="card-body p-3">
                    <div>
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th colspan="4">Status Praesens</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{getConfigDynamic(Config.mr.genderText,rowMedisBBL.aukmb_jenis_kelamin)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Berat Badan</h4>
                                <p v-if="rowMedisBBL.aukmb_berat_badan">{{rowMedisBBL.aukmb_berat_badan||"-"}} gram</p>
                                <p v-else> - </p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Panjang</h4>
                                <p v-if="rowMedisBBL.aukmb_panjang">{{rowMedisBBL.aukmb_panjang||"-"}} cm</p>
                                <p v-else> - </p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Lingkar Kepala</h4>
                                <p v-if="rowMedisBBL.aukmb_lingkar_kepala">{{rowMedisBBL.aukmb_lingkar_kepala||"-"}} cm</p>
                                <p v-else> - </p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Lingkar Dada</h4>
                                <p v-if="rowMedisBBL.aukmb_lingkar_dada">{{rowMedisBBL.aukmb_lingkar_dada||"-"}} cm</p>
                                <p v-else> - </p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Nadi</h4>
                                <p v-if="rowMedisBBL.aukmb_nadi">{{rowMedisBBL.aukmb_nadi||"-"}}x/mnt</p>
                                <p v-else> - </p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>RR</h4>
                                <p v-if="rowMedisBBL.aukmb_rr">{{rowMedisBBL.aukmb_rr||"-"}}x/mnt</p>
                                <p v-else> - </p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Suhu Badan</h4>
                                <p v-if="rowMedisBBL.aukmb_suhu">{{rowMedisBBL.aukmb_suhu||"-"}}&deg;C</p>
                                <p v-else> - </p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="mt-3">
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th colspan="4">Keadaan Umum</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Kesan</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisKesan,rowMedisBBL.aukmb_kesan)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Warna</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisWarna,rowMedisBBL.aukmb_warna)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Pernafasan</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisNafas,rowMedisBBL.aukmb_pernafasan)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Tonus</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLMedisTonus,rowMedisBBL.aukmb_tonus)||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>

                <div class="card">
                <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Pemeriksaan Khusus</h6>
                </div>
                <div class="card-body p-3">
                    <div>
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th colspan="4">Kepala</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Bentuk</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLBentuk,rowMedisBBL.aukmb_kepala_bentuk)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Telinga</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLTelinga,rowMedisBBL.aukmb_telinga)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Suturae</h4>
                                <p>{{getConfigDynamic(Config.mr.mNormalTidakNormalText,rowMedisBBL.aukmb_suturae_text)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Hidung</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLHidung,rowMedisBBL.aukmb_hidung)||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Fontanel</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLFontanel,rowMedisBBL.aukmb_fontanel)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Mulut</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLMulut,rowMedisBBL.aukmb_mulut)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Caput Succedaneum</h4>
                                <p>{{getConfigDynamic(Config.mr.mAdaTidakAdaText,rowMedisBBL.aukmb_caput)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Bibir</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLBibir,rowMedisBBL.aukmb_bibir)||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Septal Hematoma</h4>
                                <p>{{getConfigDynamic(Config.mr.mAdaTidakAdaText,rowMedisBBL.aukmb_septal_hematoma)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Lidah</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLLidah,rowMedisBBL.aukmb_lidah)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Mata</h4>
                                <p>{{getConfigDynamic(Config.mr.mAdaTidakAdaText,rowMedisBBL.aukmb_mata)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Palatum</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLBibir,rowMedisBBL.aukmb_palatum)||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="mt-3">
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th colspan="4">Leher</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Bentuk</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLLeher,rowMedisBBL.aukmb_bentuk_leher)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%"></td>
                            <td width="25%"></td>
                            <td width="25%"></td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                    <div class="mt-3">
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th colspan="4">Dada</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Pemeriksaan</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLPemeriksaan,rowMedisBBL.aukmb_dada_pemeriksaan)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Paru-paru</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLParu,rowMedisBBL.aukmb_dada_paru)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Jantung</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLJantung,rowMedisBBL.aukmb_dada_jantung)||"-"}}</p>
                            </div>
                            </td>
                            <td width="25%">
                            <div class="result_tab">
                                <h4>Perut</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLPerut,rowMedisBBL.aukmb_dada_perut)||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Tali Pusat</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBaikLayu,rowMedisBBL.aukmb_dada_tali_pusat)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Anus Rectum</h4>
                                <p>{{getConfigDynamic(Config.mr.mAnusLubang,rowMedisBBL.aukmb_dada_anus_rectum)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Anggota Gerak</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapAnggotaGerak,rowMedisBBL.aukmb_dada_anggota_gerak)||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Bibir</h4>
                                <p>{{getConfigDynamic(Config.mr.mRanapBBLBibir,rowMedisBBL.aukmb_bibir)||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                            <div class="result_tab">
                                <h4>Kelainan lain-lain</h4>
                                <p>{{rowMedisBBL.aukmb_dada_kelainan_lain||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
                <div class="row mt-2">
                <div class="col-md-6">
                    <div class="card border shadow-0 mb-0">
                    <div class="bg-light card-header py-2">
                        <div class="d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                        </div>
                    </div>
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th>Item ICD-10 dipilih</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                            <td><span>{{v.mi10_name||"-"}} <span
                                class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                        </tr>
                        <tr v-if="!(dataICD10||[]).length">
                            <td colspan="99" class="text-center">Tidak ada data</td>
                        </tr>
                        </tbody>
                        
                        <template v-if="rowMedisBBL.aukmb_diagnosa_text">
                        <thead>
                        <tr>
                            <th>Diagnosa Lainnya</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{rowMedisBBL.aukmb_diagnosa_text||"-"}}</td>
                        </tr>
                        </tbody>
                        </template>
                    </table>
                    </div>
                </div>
                </div>
                <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
                    <table class="table table-bordered table-sm table-input">
                    <thead>
                        <tr>
                        <td colspan="3">
                            <div class="result_tab">
                                <h4>Nama Tindakan / ICD-9-CM</h4>
                                <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                            </div>
                        </td>
                        </tr>
                        <tr>
                        <th width="24">No.</th>
                        <th>Jenis Informasi</th>
                        <th>Isi Informasi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>1</td>
                        <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                        <td>
                        {{rowIC.aranic_diagnosis||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>2</td>
                        <td>Dasar Diagnosis</td>
                        <td>
                        {{rowIC.aranic_dasar_diagnosis||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>3</td>
                        <td>Tindakan Kedokteran</td>
                        <td>
                        {{rowIC.aranic_tindakan_dokter||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>4</td>
                        <td>Indikasi Tindakan</td>
                        <td>
                        {{rowIC.aranic_indikasi_tindakan||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>5</td>
                        <td>Tata Cara</td>
                        <td>
                        {{rowIC.aranic_tata_cara||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>6</td>
                        <td>Tujuan</td>
                        <td>
                        {{rowIC.aranic_tujuan||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>7</td>
                        <td>Risiko</td>
                        <td>
                        {{rowIC.aranic_risiko||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>8</td>
                        <td>Komplikasi</td>
                        <td>
                        {{rowIC.aranic_kompilasi||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>9</td>
                        <td>Prognosis</td>
                        <td>
                        {{rowIC.aranic_prognosis||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>10</td>
                        <td>Alternatif & Risiko</td>
                        <td>
                        {{rowIC.aranic_alternatif||"-"}}
                        </td>
                        </tr>
                        <tr>
                        <td>11</td>
                        <td>Lain-lain</td>
                        <td>
                        {{rowIC.aranic_lainnya||"-"}}
                        </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                        <td width="50%" colspan="2">
                            <div class="result_tab">
                            <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                            <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                            <p v-else>Tindakan ditolak</p>
                            </div>
                        </td>
                        <td width="50%">
                            <div class="result_tab">
                            <h4>Persetujuan tindakan diberikan kepada</h4>
                            <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                            <p v-else>Penanggung Jawab</p>
                            </div>
                        </td>
                        </tr>
                    </tfoot>
                    </table>
                </b-modal>
            </div>
        </div>
    </template>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue, 
  
  computed: {
    isParent(){
      return this.$parent
    },
    isDetail(){
      return this.$route.query.isDetailForm
    },
    now(){
      return moment().format('DD MMM YYYY')
    },
  },
  data(){
    return {
      rowMedisBBL: {},
      rowPasien: {},
      mrValidationKAMedis: {},

      bentukOpen: false,
      suturaeOpen: false,
      fontanelOpen: false,
      caputOpen: false,
      septalOpen: false,
      mataOpen: false,

      telingaOpen: false,
      hidungOpen: false,
      mulutOpen: false,
      bibirOpen: false,
      lidahOpen: false,
      palatumOpen: false,

      dataICD10: [],
      riwayatICD10: [],
      mICD10:[], 
      openriwayatICD10: false,
      selectedICD10: '',
      searchICD10: '',
      inputICD10: null,
      inputICD10Name: null,

      keadaanUmum: false,
      activeAnat: 0,  
      openModalICDetail: false,
      rowIC: {}
    }
  },
  methods: {
    toValidate(val){
        return {...val}
    },   
    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    apiGetKAMedisBBL(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'UGDTindakLanjut', {
        data: {
          type: 'get-data-ka-medis-bbl',
          id: this.$route.params.pageSlug,
        }
      }, 'POST').then(res => {
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
        this.loadingOverlay = false            
      })
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save-data-ka-medis-bbl'
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: data
        },
        "POST"
      )
    }, 1000),

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
                setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})

                },500)
            }
          })
        }
          
        if (success) {
          this.$swal({
              icon: 'warning',
              title: 'Apakah Anda Yakin akan menyimpan data ini?',
              showCancelButton: true,
              confirmButtonText: 'Ya',
              cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowMedisBBL
              data.type = 'submit-data-ka-medis-bbl'
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + 'UGDTindakLanjut', {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                  })
              }).catch(err => {
                  this.loadingOverlay = false
                  if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },

    ceilData(value) {
      return Math.ceil(value)
    },

    selectICD10(e) {
      let index = this.mICD10.findIndex(x => x.value == e)
      if (index !== -1) {
          this.inputICD10Name =  this.mICD10[index]['text']
          this.searchICD10Name = null
      }else{
          this.inputICD10Name =  null
      }
      this.addNewICD10()
    },

    filterICD10: _.debounce(function (e, k) {
      let id = []
      for (let i = 0; i < (this.dataICD10 || []).length; i++) {
          if (this.dataICD10[i]['audb_icd_10']) {
              id.push(this.dataICD10[i]['audb_icd_10'])
          }
      }
      
      this.searchICD10Name = e

      Gen.apiRest('/do/' + this.modulePage, {
          data: {
              type: 'select-icd-10',
              search: e,
              id: id
          }
          }, 'POST').then(res => {
          this.mICD10 = res.data.data
      })
    }, 10),

    changePageICD10(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }
      
      let data = {
        type: 'select-paging-icd-10',
        page: page,
        search: this.searchICD10Name
      }
      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.mICD10 = res.data.data
        this.getmICD10(this.dataICD10, k)
      })
    },

    
    addNewICD10() {
      if(this.inputICD10){
      let data = {
          audb_aur_id: this.$route.params.pageSlug,
          audb_icd_10: this.inputICD10,
          type: 'add-icd-10'
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
          let resp = res.data
          this.dataICD10.push(resp.row)
          this.inputICD10 = null
      })
      }
    },
    removedataICD10(id, k) {
        let data = {
        id: id,
        type: 'remove-icd-10'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(() => {
        this.dataICD10.splice(k, 1)
        })
    },

    getmICD10(diagnosa, k) {
        const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
        }), v2 => {
        return v2.api10_icd_10
        })
        return _.filter(this.mICD10, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
        })
    },
    
    searchICD10Data: _.debounce(function () {
        let data = {
            search: this.searchICD10,
            aranr_ap_id: this.rowReg.aranr_ap_id,
            type: 'search-icd-10'
        }
        Gen.apiRest(
        "/do/" + this.modulePage, {
            data: data
        },
        "POST"
        ).then(res => {
            let resp = res.data
            this.riwayatICD10 = resp.data
        })
    }, 500),

    saveICD10() {
        if (this.selectedICD10) {
        let data = {
            id: this.selectedICD10,
            aur_id: this.$route.params.pageSlug,
            type: 'save-riwayat-icd-10'
        }
        Gen.apiRest(
            "/do/" + this.modulePage, {
            data: data
            },
            "POST"
        ).then(() => {
            this.openriwayatICD10 = false
            this.resetData()
        })
        }
    },

    resetData() {
        this.searchICD10 = '',
        this.apiGet()
    },
  },
  mounted() {
    this.apiGetKAMedisBBL()
  },
  watch: {
    rowMedisBBL: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  }
}
</script>