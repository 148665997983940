<template>
  <div class="content">
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
        <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Laporan Nifas</h6>
        </div>
      </div>

      <template v-if="!isDetail">
        <validation-observer ref="VForm">
          <b-form @submit.prevent="doSubmit" class="card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table table-sm  table-bordered mb-2" style="width: 150%;">
                        <tbody>
                          <tr>
                            <th class="bg-primary">Tanggal / Jam </th>
                            <th class="bg-primary"> Keadaan umum </th>
                            <th class="bg-primary"> Mamae/Laktasi </th>
                            <th class="bg-primary">Uterus </th>
                            <th class="bg-primary">Lochea </th>
                            <th class="bg-primary">DC </th>
                            <th class="bg-primary">Lain-lain </th>
                            <th class="bg-primary">Aksi</th>
                          </tr>
                          <tr v-for="(v, k) in dataLaporanNifas" :key="`laporan-${k}`">
                            <td>
                              <div class="input-group">
                                  <datepicker input-class="form-control transparent h-38" placeholder="Tanggal"
                                      class="my-datepicker" calendar-class="my-datepicker_calendar"
                                      v-model="v.aulnd_date">
                                  </datepicker>
                                  <vue-timepicker manual-input style="height: 38px !important;" format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="v.aulnd_time">
                                  </vue-timepicker>
                                  <div class="input-group-append calendar-group">
                                      <span class="input-group-text" id="basic-addon2"><i
                                          class="icon-calendar"></i></span>
                                  </div>
                              </div>

                              <VValidate
                                name="Tanggal"
                                v-model="v.aulnd_date"
                                :vid="`tgl-${k}`"
                                rules="required"
                              />

                              <VValidate
                                name="Waktu"
                                v-model="v.aulnd_time"
                                :vid="`wwt-${k}`"
                                rules="required"
                              />

                            </td>
                            <td>
                              <b-form-group>
                                <b-form-radio v-model="v.aulnd_keadaan_umum" :value="val.label" v-for="(val, vk) in mrKeadaanUmum" :key="`keadaan-umum-${k}-${vk}`">
                                  {{ val.label }}
                                </b-form-radio>
                              </b-form-group>

                              <template v-if="v.aulnd_keadaan_umum == 'Lain-lain'">
                                <b-form-input v-model="v.aulnd_keadaan_umum_note" />
                                <VValidate
                                  name="Catatan"
                                  v-model="v.aulnd_keadaan_umum_note"
                                  :vid="`kun-${k}`"
                                  rules="required"
                                />
                              </template>

                              <VValidate
                                name="Keadaan Umum"
                                :vid="`keadaan-umum-${k}`"
                                v-model="v.aulnd_keadaan_umum"
                                :rules="mrValidationDataLaporanNifas.aulnd_keadaan_umum"
                              />
                            </td>

                            <td>
                              <b-form-group>
                                <b-form-radio v-model="v.aulnd_laktasi" :value="val.label" v-for="(val, vk) in mrLaktasi" :key="`laktasi-${k}-${vk}`">
                                  {{ val.label }}
                                </b-form-radio>
                              </b-form-group>

                              <template v-if="v.aulnd_laktasi == 'Lain-lain'">
                                <b-form-input v-model="v.aulnd_laktasi_note" />
                                <VValidate
                                  name="Catatan"
                                  v-model="v.aulnd_laktasi_note"
                                  :vid="`lakn-${k}`"
                                  rules="required"
                                />
                              </template>

                              <VValidate
                                name="Mamae/Laktasi"
                                :vid="`laktasi-${k}`"
                                :value="(v.aulnd_laktasi||[]).length > 0 ? true : null"
                                :rules="mrValidationDataLaporanNifas.aulnd_laktasi"
                              />
                            </td>

                            <td>
                              <b-form-group>
                                <b-form-radio v-model="v.aulnd_uterus" :value="val.label" v-for="(val, vk) in mrUterus" :key="`uterus-${k}-${vk}`">
                                  {{ val.label }}
                                </b-form-radio>
                              </b-form-group>

                              <VValidate
                                name="Uterus"
                                :vid="`uterus-${k}`"
                                :value="(v.aulnd_uterus||[]).length > 0 ? true : null"
                                :rules="mrValidationDataLaporanNifas.aulnd_uterus"
                              />
                            </td>
                            <td>
                              <b-form-group>
                                <b-form-radio v-model="v.aulnd_lochea" :value="val.label" v-for="(val, vk) in mrLochea" :key="`lochea-${k}-${vk}`">
                                  {{ val.label }}
                                </b-form-radio>
                              </b-form-group>
                              <VValidate
                                name="Lochea"
                                :vid="`lochea-${k}`"
                                :value="(v.aulnd_lochea||[]).length > 0 ? true : null"
                                :rules="mrValidationDataLaporanNifas.aulnd_lochea"
                              />
                            </td>

                            <td>
                              <b-form-group>
                                <b-form-radio v-model="v.aulnd_dc" :value="val.label" v-for="(val, vk) in mrDc" :key="`dc-${k}-${vk}`">
                                  {{ val.label }}
                                  
                                  <template v-if="val.has_note && v.aulnd_dc == val.label">
                                    <b-form-input v-model="v.aulnd_dc_note" />
                                    <VValidate
                                      name="Catatan"
                                      v-model="v.aulnd_dc_note"
                                      :vid="`dcn-${vk}`"
                                      rules="required"
                                    />
                                  </template>
                                </b-form-radio>
                              </b-form-group>


                              <VValidate
                                name="DC"
                                :vid="`dc-${k}`"
                                :value="(v.aulnd_dc||[]).length > 0 ? true : null"
                                :rules="mrValidationDataLaporanNifas.aulnd_dc"
                              />
                            </td>

                            <td>
                              <b-form-textarea v-model="v.aulnd_other" rows="2"></b-form-textarea>
                            </td>
                            <td>
                              <a href="javascript:;" @click="deleteRow(v, k)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800" v-b-tooltip.hover data-popup="tooltip" title="Hapus" v-if="dataLaporanNifas.length > 1"><i class="icon-trash"></i></a>
                            </td>
                          </tr>
                        </tbody>
                        <tr>
                          <td class="text-center" colspan="6">
                            <a href="javascript:;" class="btn btn-outline-info alpha-info text-blue-800 btn-sm" @click="addLaporan()">
                              <i class="mr-2 icon-plus2"></i>
                              <span>Tambah</span>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header bg_head_panel">
                        <div class="row">
                          <div class="col-md-4">
                            <h6 class="card-title font-weight-semibold">Catatan KB</h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body p-3">
                        <div class="row">
                          <div class="col-md-5">
                            <div class="form-group">
                              <label>Jenis KB </label>
                              <b-form-group>
                                <b-form-radio-group
                                  v-model="rowLaporanNifas.auln_jenis_kb"
                                  :options="mrJenisKB"
                                  value-field="value"
                                  text-field="label"
                                >
                                </b-form-radio-group>
                              </b-form-group>
                              <template v-if="rowLaporanNifas.auln_jenis_kb == 'OTHER'">
                                <b-form-input v-model="rowLaporanNifas.auln_jenis_kb_note" @change="updateRow(row)" />
                                <VValidate
                                  name="Catatan"
                                  v-model="rowLaporanNifas.auln_jenis_kb_note"
                                  :rules="mrValidationLaporanNifas.auln_jenis_kb_note"
                                />
                              </template>

                              <VValidate
                                name="Jenis KB"
                                v-model="rowLaporanNifas.auln_jenis_kb"
                                :rules="mrValidationLaporanNifas.auln_jenis_kb"
                              />
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label class="" for="">Tanggal Pemasangan</label>
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"><i class="icon-calendar22"></i></span>
                                </div>
                                <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="rowLaporanNifas.auln_install_date" @input="updateRow(row)">
                                </datepicker>
                              </div>

                              <VValidate
                                name="Tanggal Pemesangan"
                                v-model="rowLaporanNifas.auln_install_date"
                                :rules="mrValidationLaporanNifas.auln_install_date"
                              />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="text-right">
                  <button type="button" class="btn" @click="$router.back()">Kembali</button>
                  <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
      <template v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 pt-3">
                <div class="table-responsive">
                  <table class="table table-sm  table-bordered mb-2" style="width: 150%;">
                    <tbody>
                      <tr>
                        <th class="bg-primary">Tanggal / Jam </th>
                        <th class="bg-primary"> Keadaan umum </th>
                        <th class="bg-primary"> Mamae/Laktasi </th>
                        <th class="bg-primary">Uterus </th>
                        <th class="bg-primary">Lochea </th>
                        <th class="bg-primary">DC </th>
                        <th class="bg-primary">Lain-lain </th>
                      </tr>
                      <tr v-for="(v, k) in dataLaporanNifas" :key="`laporan-${k}`">
                        <td>
                          {{v.aulnd_date | moment("DD MMMM YYYY")}}, {{v.aulnd_time}}
                        </td>
                        <td>
                          {{v.aulnd_keadaan_umum||"-"}} 
                          <span v-if="v.aulnd_keadaan_umum == 'Lain-lain'">
                            : {{v.aulnd_keadaan_umum_note||"-"}}
                          </span>
                        </td>
                        <td>
                          {{v.aulnd_laktasi||"-"}} 
                          <span v-if="v.aulnd_laktasi == 'Lain-lain'">
                            : {{v.aulnd_laktasi_note||"-"}}
                          </span>
                        </td>
                        <td>
                          {{v.aulnd_uterus||"-"}} 
                        </td>
                        <td>
                          {{v.aulnd_lochea||"-"}} 
                        </td>
                        <td>
                          {{v.aulnd_dc||"-"}} 
                          <span v-if="v.aulnd_dc == 'Lain-lain'">
                            : {{v.aulnd_dc_note||"-"}}
                          </span>
                        </td>
                        <td>
                          {{v.aulnd_other||"-"}} 
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">

                <div class="card">
                  <div class="card-header bg_head_panel">
                    <div class="row">
                      <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Catatan KB</h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-3">
                    <table class="table table-bordered">
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Jenis KB</h4>
                            <span v-if="rowLaporanNifas.auln_jenis_kb !== 'OTHER'">
                              {{getConfigDynamic(mrJenisKB,rowLaporanNifas.auln_jenis_kb,'label')||"-"}}
                            </span>
                            <span v-else>{{rowLaporanNifas.auln_jenis_kb_note||"-"}}</span>
                          </div> 
                        </td>
                        
                        <td>
                          <div class="result_tab">
                            <h4>Tanggal Pemasangan</h4>
                            <span v-if="rowLaporanNifas.auln_install_date">{{rowLaporanNifas.auln_install_date | moment("DD MMMM YYYY")}}</span>
                            <span v-else> - </span>
                          </div> 
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="card-footer">
                    <div class="text-right">
                      <button type="button" class="btn" @click="$router.back()">Kembali</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
const moment = require('moment')

export default{
  extends: GlobalVue, 
  components: {
    Datepicker,
    VueTimepicker
  },   
  computed: {
    isParent(){
      return this.$parent
    },
    isDetail(){
      return this.$route.query.isDetailForm
    },
    now(){
      return moment().format('DD MMM YYYY')
    },
  },
  
  data(){
    return {
      rowLaporanNifas: {},
      dataLaporanNifas: [],
      mrValidationLaporanNifas: {},
      mrValidationDataLaporanNifas: {},
      
      mrJenisKB: [],
      mrKeadaanUmum: [],
      mrLaktasi: [],
      mrUterus: [],
      mrLochea: [],
      mrDc: [],
    }
  },
  methods: {
    addLaporan(){
      let data = {
        aulnd_aur_id: this.$route.params.pageSlug,
        aulnd_auln_id: this.rowLaporanNifas.auln_id,
        type: 'add-laporan-nifas-data',
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.dataLaporanNifas.push(res.data.data)
      }) 
    },
    apiGetLaporanNifas(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'UGDTindakLanjut', {
        data: {
          type: 'get-data-laporan-nifas',
          id: this.$route.params.pageSlug,
        }
      }, 'POST').then(res => {
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
        this.loadingOverlay = false            
      })
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save-data-laporan-nifas'
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: data
        },
        "POST"
      )
    }, 1000),
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
                setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})

                },500)
            }
          })
        }
                
        if (success) {
            this.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menyimpan data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {
                if (result.value) {
                  let data = this.rowLaporanNifas
                  data.type = 'submit-data-laporan-nifas'
                  this.loadingOverlay = true
                  Gen.apiRest(
                  "/do/" + 'UGDTindakLanjut', {
                      data: data
                  },
                  "POST"
                  ).then(res => {
                      this.loadingOverlay = false
                      let resp = res.data
                      resp.statusType = 200
                      this.$swal({
                          title: resp.message,
                          icon: resp.status,
                          confirmButtonText: 'Ok',
                          allowOutsideClick: false,
                          allowEscapeKey: false
                      }).then(result => {
                          this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                      })
                  }).catch(err => {
                      this.loadingOverlay = false
                      if (err) {
                          err.statusType = err.status
                          err.status = "error"
                          err.title = err.response?.data?.title
                          err.message = err.response?.data?.message
                          err.messageError = err.message
                      }
                      this.doSetAlertForm(err)
                  })
                }
            })
        }
      })
    },
    autoSaveData: _.debounce(function (data) {
      let dataPost = {
          type: 'auto-save-data-laporan-nifas-data',
          data: data
      }
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: dataPost
        },
        "POST"
      )
    }, 1000),
    
    deleteRow(data, key){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: "remove-laporan-nifas-data",
            id_registrasi: this.$route.params.pageSlug, 
            id: data.aulnd_id
          }
        },
        "POST"
      ).then(() => {
        this.dataLaporanNifas.splice(key, 1)
      })
    },
    getConfigDynamic(master, value, field='text') {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index][field]
            }
        }
        return text
    },
  },
  mounted() {
    this.apiGetLaporanNifas()
  },
  watch: {
    rowLaporanNifas: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    
    dataLaporanNifas: {
      handler(v) {
        this.autoSaveData(v)
      },
      deep: true
    },
  }
}

</script>