<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <!--
        <div class="row" v-if="!rowRujukan.aurujd_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowRujukan['aurujd_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowRujukan.aurujd_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        -->
        
        <div :class="'row'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
            <div class="col-md-8 offset-2 mt-2">
                <div class="document-framer">
                    <div
                        style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 9px;">
                        <div>
                            <table style="border-collapse: collapse;width: 100%;">
                            <tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                    <tr>
                                    <td style="padding:0 12px 8px;">
                                        <div
                                        style="float: left;width: 7%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                        <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="max-width: 100%;" alt="">
                                        </div>
                                        <div
                                        style="text-transform: uppercase;float: left; text-align:center; width: 86%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;">
                                        <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit
                                            Harapan Magelang</h1>
                                        <p style="margin:0;font-size: 11px;">Jl.P Senopati No 11, Magelang 56123</p>
                                        </div>
                                        <div style="display: block;clear: both;"></div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td colspan="2" style="padding: 4px 0;border-top: 1px solid #000;border-bottom: 1px solid #000;">
                                        <h2 style="margin:0;text-align: center;text-transform: uppercase;font-size: 14px;">Formulir Transfer Pasien Antar Rumah Sakit</h2>
                                    </td>
                                    </tr>
                                </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 10px 16px 12px;">
                                <table border="0" style="border-collapse: collapse;width: 100%;">
                                    <tr>
                                    <td style="vertical-align: top;padding: 4px 0;" width="150"><strong
                                    style="text-transform: uppercase;">Tanggal</strong></td>
                                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="1">
                                        {{now}}
                                    </td>
                                    </tr>
                                </table>
                                <table border="0" style="border-collapse: collapse;width: 100%;">
                                    <tr>
                                    <td style="vertical-align: top;padding: 4px 0;" width="150"><strong
                                    style="text-transform: uppercase;">Nama Pasien </strong></td>
                                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                    <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                        {{rowPasien.ap_fullname||"-"}}
                                    </td>
                                    </tr>
                      
                                    <tr>
                                        <td style="vertical-align: top;padding: 4px 0;" width="150"><strong
                                        style="text-transform: uppercase;">Tanggal Lahir </strong></td>
                                        <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                        <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                        
                                        <span v-if="rowPasien.ap_dob">
                                            {{rowPasien.ap_dob | moment("DD MMM YYYY")}}
                                        </span>
                                        <span v-else> - </span>
                                        
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td style="vertical-align: top;padding: 4px 0;" width="150"><strong
                                        style="text-transform: uppercase;">NO RM</strong></td>
                                        <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                        <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                        {{rowPasien['ap_code']}}
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td style="vertical-align: top;padding: 4px 0;" width="150"><strong
                                        style="text-transform: uppercase;">NIK </strong></td>
                                        <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                        <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                        {{rowPasien['ap_nik']}}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="vertical-align: top;padding: 4px 0;" width="96"><strong
                                        style="text-transform: uppercase;">Alamat Pasien </strong></td>
                                        <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                        <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                        {{rowPasien['ap_address']}}
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                    <td style="vertical-align: top;padding: 4px 0;" width="96"><strong
                                    style="text-transform: uppercase;">Jenis Kelamin </strong></td>
                                    <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                    <td style="vertical-align: middle; " colspan="3">
                                        <div style="display: inline-block; margin-top: 5px;">
                                        <div
                                            style="display: inline-block;text-align: center;margin-right:2px;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                            <img v-if="rowPasien.ap_gender == 1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                        </div>
                                        <div style="display: inline-block;vertical-align: middle; margin-right: 5px">
                                            Laki-Laki
                                        </div>

                                        <div
                                            style="display: inline-block;text-align: center;margin-right:2px;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                            <img v-if="rowPasien.ap_gender == 2" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                        </div>
                                        <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                            Perempuan
                                        </div>
                                        </div>
                                    </td>
                                    </tr>
                                </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;" border="1">
                                    <thead>
                                    <tr style="background-color: #d3d3d3; text-align: center;">
                                        <th colspan="2" style="text-align: center;padding: 2px 12px;">Ringkasan Pasien yang dilakukan
                                        Transfer/Rujuk ke Rumah Sakit</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th width="50%" style="padding: 5px 7px 5px 0;text-align:center">Dokter yang Merujuk<br>
                                            {{rowReg.bu_full_name||"-"}}
                                        </th>
                                        <th width="50%" style="padding: 5px 7px 5px 0;text-align:center">Ke Rumah Sakit/Dokter Rujukan <br>
                                            {{rowRujukan.aurujd_faskes_rujuk_sisrute_text||"-"}}
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                                </td>
                            </tr>
                            </table>
                            
                            <table border="0" style="border-collapse: collapse;width: 100%;">
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong
                                    style="text-transform: uppercase;">Alasan Rujuk </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                    ({{rowRujukan.aupd_alasan_rujuk}}) {{rowRujukan.aupd_alasan_rujuk_text||"-"}}
                                </td>
                                <td width="20"></td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong
                                    style="text-transform: uppercase;">Diagnosa Medis </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                    {{rowRujukan.auod_diagnosa_kode_bpjs_text||"-"}}
                                </td>
                                <td width="20"></td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong style="text-transform: uppercase;">Saat dipindahkan </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                    <p>{{rowRujukan.ausd_catatan_dokter||"-"}}</p>
                                </td>
                            </tr>

                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;">Tanda Vital </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3"><span
                                    style="margin-right:30px;">TD: {{rowRujukan.auod_ttv_tekanan_darah_min||"-"}}/{{rowRujukan.auod_ttv_tekanan_darah_max||"-"}}</span>
                                <span style="margin-right:30px;">SUHU: {{rowRujukan.auod_ttv_suhu||"-"}} C</span>
                                <span style="margin-right:30px;">NADI: {{rowRujukan.auod_ttv_nadi||"-"}}x permenit</span>
                                <span style="margin-right:30px;">RF: {{rowRujukan.auod_ttv_pernafasan||"-"}}x Permenit </span>
                                <span style="margin-right:30px;">SPO2: {{rowRujukan.auod_ttv_spo2||"-"}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;"> </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">

                                    <span style="margin-right:30px;">GCS: 
                                        {{rowRujukan.auod_gcs_total||0}}
                                        <span v-if="rowRujukan.auod_gcs_total >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 12 && rowRujukan.auod_gcs_total <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 10 && rowRujukan.auod_gcs_total <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 7 && rowRujukan.auod_gcs_total <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 5 && rowRujukan.auod_gcs_total <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 4 && rowRujukan.auod_gcs_total <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                        <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                    </span>
                                    
                                    <span style="margin-right:30px;">E: 
                                        {{rowRujukan.auod_response_mata||0}} 
                                    </span>
                                    <span style="margin-right:30px;">M: 
                                        {{rowRujukan.auod_response_motorik||0}}                                 
                                    </span>
                                    <span style="margin-right:30px;">V: 
                                        {{rowRujukan.auod_response_verbal||0}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;"> </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                <span style="margin-right:30px;">Reflek Pupil: {{rowRujukan.aurujd_reflek_pupil||"-"}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;"> </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                <span style="margin-right:30px;">Reflek Cahaya: {{rowRujukan.aurujd_reflek_cahaya||"-"}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong
                                    style="text-transform: uppercase;">Alergi </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                {{rowRujukan.aurujd_alergi||"-"}}
                                </td>
                                <td width="20"></td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="150"><strong
                                    style="text-transform: uppercase;">RIWAYAT PENYAKIT </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                {{rowRujukan.aurujd_riwayat_penyakit||"-"}}
                                </td>
                                <td width="20"></td>
                            </tr>
                            </table>
                            <hr style="margin: 16px 0;">
                            <table border="0" style="border-collapse: collapse;width: 100%;">
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="250"><strong
                                    style="text-transform: uppercase;">Tindakan yang telah dilakukan</strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0 4px;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                {{rowRujukan.aurujd_tindakan||"-"}}
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="250"><strong
                                    style="text-transform: uppercase;">Pemeriksaan yang telah dilakukan </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                {{rowRujukan.aurujd_pemeriksaan||"-"}}                                
                                </td>
                            </tr>

                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;">Pengobatan yang telah dilakukan  </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                {{rowRujukan.aurujd_pengobatan||"-"}}                                
                                </td>
                            </tr>

                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;">Peralatan Medis yang Terpasang </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="vertical-align: middle; " colspan="3">
                                <div style="display: inline-block; margin-top: 5px;">
                                    <div
                                    style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <img v-if="rowRujukan.aurujd_peralatan_is_terpasang != 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Tidak Ada 
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;"> </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;"></td>
                                <td style="vertical-align: middle; " colspan="3">
                                <div style="display: inline-block; margin-top: 5px; margin-right: 10px;" >
                                    <div style="display: inline-block;  text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <img v-if="rowRujukan.aurujd_peralatan_is_terpasang == 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Ada
                                    </div>
                                </div>

                                <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                                    <div style="display: inline-block;text-align: center; border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <img v-if="(rowRujukan.aurujd_peralatan_yang_terpasang||[]).indexOf('Infus') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Infus
                                    </div>
                                </div>

                                <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                                    <div style="display: inline-block;text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    
                                    <img v-if="(rowRujukan.aurujd_peralatan_yang_terpasang||[]).indexOf('NGT') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">

                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    NGT
                                    </div>
                                </div>

                                <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                                    <div style="display: inline-block;text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    
                                    <img v-if="(rowRujukan.aurujd_peralatan_yang_terpasang||[]).indexOf('DC') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">

                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    DC
                                    </div>
                                </div>

                                <div style="display: inline-block; margin-top: 5px;  margin-right: 10px;">
                                    <div style="display: inline-block;text-align: center;  border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    
                                    <img v-if="(rowRujukan.aurujd_peralatan_yang_terpasang||[]).indexOf('Oksigen') != -1" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">

                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Oksigen
                                    </div>
                                </div>
                                </td>
                            </tr>
                            
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;">Perawatan Pasien yang dibutuhkan  </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="border-bottom: 1px solid;vertical-align: top;" colspan="3">
                                    {{rowRujukan.aurujd_perawatan_pasien||"-"}}
                                </td>
                            </tr>
                            
                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;">Persetujuan biaya RS rujukan </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="vertical-align: middle; " colspan="3">
                                <div style="display: inline-block; margin-top: 5px;">
                                    <div
                                    style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Ada 
                                    </div>
                                    <div
                                    style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <span style="line-height: 16px;"></span>
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Tidak Ada
                                    </div>
                                </div>
                                </td>
                            </tr>

                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;">Persetujuan resiko perjalanan </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="vertical-align: middle; " colspan="3">
                                <div style="display: inline-block; margin-top: 5px;">
                                    <div
                                    style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <img v-if="rowRujukan.aurujd_persetujuan_risiko_jalan == 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Ada 
                                    </div>
                                    <div
                                    style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <img v-if="rowRujukan.aurujd_persetujuan_risiko_jalan != 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Tidak Ada
                                    </div>
                                </div>
                                </td>
                            </tr>


                            <tr>
                                <td style="vertical-align: top;padding: 4px 0 4px 20px;" width="96"><strong
                                    style="text-transform: uppercase;">Kejadian klinis selama dilakukan transfer </strong></td>
                                <td width="20" style="text-align:center;padding: 4px 0;vertical-align: top;">:</td>
                                <td style="vertical-align: middle; " colspan="3">
                                <div style="display: inline-block; margin-top: 5px;">
                                    <div
                                    style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <img v-if="rowRujukan.aurujd_kejadian_transfer == 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Ada
                                    </div>
                                    <div
                                    style="display: inline-block;text-align: center;border: 2px solid #000;font-size: 11px;width: 16px;height: 16px;box-sizing: border-box;line-height: 12px;vertical-align: middle;">
                                    <img v-if="rowRujukan.aurujd_kejadian_transfer != 'Y'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsSAAALEgHS3X78AAAArklEQVRIx+3WwQ3DIAyF4d9doBmFUTtKR2CUjtAN3AuqlNYlpjFBirCEOHD4kPCzEFVlRF0YVBM+Hywii4gsh8IikoAHcD8MLmgGrqsDVe22gAQ8AS17ep+NQLvBW2gX2IOGw140FG5Bw+BW9AsGbiVzqSdqwbnp1n+iFuxvjh2o+cauDO5EfzZXddQFoNWutoAodDNOBhSCunL8gYeg7gFS8Nya8dqS+a+e8OngF63DorDSG8v3AAAAAElFTkSuQmCC" width="8" height="12" alt="">
                                    </div>
                                    <div style="display: inline-block;vertical-align: middle;margin-left:3px;margin-right:3px">
                                    Tidak Ada <strong style="margin:0 4px;width: 120px;display: inline-block;border-bottom: 1px solid #000;">
                                        {{rowRujukan.aurujd_kejadian_transfer == 'Y'?rowRujukan.aurujd_kejadian_transfer_text:''}}
                                    </strong>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            </table>
                            <hr style="margin: 16px 0;">
                            <table style="width: 100%;text-align: center;margin-bottom: 8px;font-size: 12px;">
                            <tbody>
                                <tr>
                                <td>
                                </td>
                                <td>
                                    <span>DPJP</span><br/>
                                    <img :src="uploader(rowRujukan.aurujd_qr)" alt="signature" width="60" id="ttdPasien">
                                    <br/>
                                    <span>({{rowReg.bu_full_name||"-"}})</span>
                                </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                <td>
                                    <br/>
                                    <span>Petugas Yang Merujuk</span><br/>
                                    <br/>
                                    <br/>
                                    <br/> 
                                    <span>(. . . . . . . . . . . . . . . . . . . . . . .)</span>
                                </td>
                                <td>
                                    <br/>
                                    <span>Penerima Rujukan</span><br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <span>(. . . . . . . . . . . . . . . . . . . . . . .)</span>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowRujukan: {},
            rowPasien: {},
            rowReg: {},
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowRujukan[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowRujukan[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                this.rowRujukan.aurujd_is_ttd = 'Manual'
                this.rowRujukan.aurujd_template = $(".document-framer").html()

                console.log(this.rowRujukan)


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowRujukan
                            data.type = 'submit-data-ttd-surat-rujukan'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDSuratSakit(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-surat-rujukan',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDSuratSakit()
    }
}      
</script>
