<template>
  <div class="content">
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
          <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Form Pelayanan Kerohanian</h6>
          </div>
      </div>

      <template v-if="!isDetail">
        <validation-observer ref="VForm">
          <b-form @submit.prevent="doSubmit" class="card">
            <div class="form-underlying card-body p-3">
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <label>Bentuk Layanan <span class="text-danger">*</span></label>
                    <b-row>
                      <b-col md="8">
                        <b-form-radio-group name="bentuk_layanan" :options="mrLayanan" v-model="rowSuratPelayananKerohanian.auspr_bentuk_layanan"></b-form-radio-group>
                      </b-col>
                      <b-col md="4" v-if="rowSuratPelayananKerohanian.auspr_bentuk_layanan == 'Lainnya'">
                        <b-form-input v-model="rowSuratPelayananKerohanian.auspr_bentuk_layanan_lain"></b-form-input>
                        <VValidate name="Bentuk Layanan" :vid="'layanan_lain'" v-model="rowSuratPelayananKerohanian.auspr_bentuk_layanan_lain" rules="required" />
                      </b-col>
                    </b-row>
                    <VValidate name="Bentuk Layanan" v-model="rowSuratPelayananKerohanian.auspr_bentuk_layanan" rules="required" />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <label>Diberikan oleh <span class="text-danger">*</span></label>
                    <v-select :options="mrPemberiLayanan" :reduce="v => v.value" label="label" v-model="rowSuratPelayananKerohanian.auspr_diberikan_oleh" :clearable="true"></v-select>
                    <VValidate name="Diberikan oleh" v-model="rowSuratPelayananKerohanian.auspr_diberikan_oleh" rules="required"></VValidate>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label>Nama Pembimbing</label>
                    <b-form-input v-model="rowSuratPelayananKerohanian.auspr_nama_pembimbing"></b-form-input>
                    <VValidate name="Nama Pembimbing" v-model="rowSuratPelayananKerohanian.auspr_nama_pembimbing" rules="required" />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>            
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>
      <template v-else>
        <div class="form-underlying card-body p-3">
          <div class="wrap_line" id="formIntra1">
            <h2>Detail Pelayanan Kerohanian</h2>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'

// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
  extends: GlobalVue, 
  computed: {
    isParent(){
      return this.$parent
    },
    isDetail(){
      return this.$route.query.isDetailForm
    },
    now(){
      return moment().format('DD MMM YYYY')
    },
  },
  data(){
    return {
      rowSuratPelayananKerohanian: {},
      mrValidationSuratPelayananKerohanian: {},
      mrLayanan: [
        {'text' : 'Doa', 'value' : 'Doa'},
        {'text' : 'Sakramen Perminyakan', 'value' : 'Sakramen Perminyakan'},
        {'text' : 'Konsultasi', 'value' : 'Konsultasi'},
        {'text' : 'Pendampingan', 'value' : 'Pendampingan'},
        {'text' : 'Lainnya', 'value' : 'Lainnya'},
      ],
      mrPemberiLayanan: [
        {'label' : 'Pembimbing rohani dari Rumah Sakit Harapan', 'value' : 'Pembimbing rohani dari Rumah Sakit Harapan'},
        {'label' : 'Pembimbing rohani pribadi', 'value' : 'Pembimbing rohani pribadi'},
      ]
    }
  },
  methods: {
    apiGetSuratPelayananKerohanian(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'UGDTindakLanjut', {
          data: {
              type: 'get-data-surat-pelayanan-kerohanian',
              id: this.$route.params.pageSlug,
          }
      }, 'POST').then(res => {
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          this.loadingOverlay = false            
      })
    },
    toValidate(val){
      return {...val}
    },
      
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save-data-surat-pelayanan-kerohanian'
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: data
        },
        "POST"
      )
    }, 1000),

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
                setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                },250)
            }
          })
        }

        if (success) {
          this.$swal({
          icon: 'warning',
          title: 'Apakah Anda Yakin akan menyimpan data ini?',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
                let data = this.rowSuratPelayananKerohanian
                data.type = 'submit-data-surat-pelayanan-kerohanian'
                this.loadingOverlay = true
                Gen.apiRest(
                "/do/" + 'UGDTindakLanjut', {
                    data: data
                },
                "POST"
                ).then(res => {
                    this.loadingOverlay = false
                    let resp = res.data
                    resp.statusType = 200
                    this.$swal({
                        title: resp.message,
                        icon: resp.status,
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                    })
                }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
            }
          })
        }
      })
    },
  },  
  mounted() {
    this.apiGetSuratPelayananKerohanian()
  },
  watch: {
    rowSuratPelayananKerohanian: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  }
}

</script>
