<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row" v-if="!rowReg.aur_surat_dpjp_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowReg['aur_surat_dpjp_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowReg.aur_surat_dpjp_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowReg.aur_surat_dpjp_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="form-group">
                <label for="">Tanda Tangan Petugas</label>
                <div class="signing-element" data-target-id="ttdPetugas">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPetugas"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPetugas','aur_surat_dpjp_ttd_petugas')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPetugas','aur_surat_dpjp_ttd_petugas')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>

                <div class="form-group">
                <label for="">Tanda Pembuat Pernyataan</label>
                <div class="signing-element" data-target-id="ttdPembuatPernyataan">
                    <VueSignaturePad
                        class="sign-container"
                        width="100%"
                        height="160px"
                        ref="ttdPembuatPernyataan"
                    />
                    <div class="mt-2">
                    <button type="button" @click="undoTTD('ttdPembuatPernyataan','aur_surat_dpjp_ttd')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                        <b><i class="icon-cross3"></i></b>
                        <span>Clear</span>
                    </button>
                    
                    <button type="button" @click="output('ttdPembuatPernyataan','aur_surat_dpjp_ttd')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                        <b><i class="icon-checkmark2"></i></b>
                    <span>Simpan</span>
                    </button>
                    </div>
                </div>
                </div>

                <div class="form-group">
                    <label for="">Nama Penanggung Jawab <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowReg.aur_surat_dpjp_pj_name" type="text" name="NamaPenanggung" id="NamaPenanggung" class="form-control"
                    placeholder="Nama Penanggung Jawab"/>
                    <VValidate 
                        name="Nama Penanggung Jawab" 
                        v-model="rowReg.aur_surat_dpjp_pj_name" 
                        :rules="{required: 1}"
                    />
                </div>

                <div class="form-group">
                    <label for="">Tanggal Lahir Penanggung Jawab <small class="text-danger">*</small></label>
                    <div class="input-group">
                        <div class="input-group">
                            <datepicker input-class="form-control transparent"
                            placeholder="Tanggal Lahir Penanggung Jawab" class="my-datepicker"
                            calendar-class="my-datepicker_calendar"
                            v-model="rowReg.aur_surat_dpjp_pj_dob"
                            >
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                    <VValidate 
                        name="Tanggal Lahir Penanggung Jawab" 
                        v-model="rowReg.aur_surat_dpjp_pj_dob" 
                        :rules="{required: 1}"
                    />
                </div>

                <div class="form-group">
                    <label for="">Tempat Lahir Penanggung Jawab <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowReg.aur_surat_dpjp_pj_pob" type="text" name="TTLPenanggung" id="TTLPenanggung" class="form-control"
                    placeholder="Tempat Lahir Penanggung Jawab"/>
                    <VValidate 
                        name="Tempat Lahir Penanggung Jawab" 
                        v-model="rowReg.aur_surat_dpjp_pj_pob" 
                        :rules="{required: 1}"
                    />
                </div>
                
                <div class="form-group">
                    <label for="">No Handphone <small class="text-danger">*</small></label>
                    <b-form-input :formatter="numberOnly" v-model="rowReg.aur_surat_dpjp_pj_no_hp" type="text" name="NOHP" id="NOHP" class="form-control"
                    placeholder="No Handphone"/>
                    <VValidate 
                        name="No Handphone" 
                        v-model="rowReg.aur_surat_dpjp_pj_no_hp" 
                        :rules="{required: 1}"
                    />
                </div>
                
                <div class="form-group">
                    <label for="">Alamat Penanggung Jawab <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowReg.aur_surat_dpjp_pj_alamat" type="text" name="AlamatDgPJ" id="AlamatDgPJ" class="form-control"
                    placeholder="Alamat Penanggung Jawab"/>
                    <VValidate 
                        name="Alamat Penanggung Jawab" 
                        v-model="rowReg.aur_surat_dpjp_pj_alamat" 
                        :rules="{required: 1}"
                    />
                </div>

                <div class="form-group">
                    <label for="">Hubungan Penanggung Jawab dengan Pasien <small class="text-danger">*</small></label>
                    <b-form-input v-model="rowReg.aur_surat_dpjp_pj_hub_dengan_pasien" type="text" name="HubPasien" id="HubPasien" class="form-control"
                    placeholder="Hubungan Penanggung Jawab dengan Pasien"/>
                    <VValidate 
                        name="Hubungan Penanggung Jawab dengan Pasien" 
                        v-model="rowReg.aur_surat_dpjp_pj_hub_dengan_pasien" 
                        :rules="{required: 1}"
                    />
                </div>
                    
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                <div class="df-content">
                    <!-- Copas Template dari sini -->
                    <div style="height:100%;position: relative;font-family: serif;color: #333;line-height: 1.5;font-size: 12px;">
                        <div>
                            <table style="border-collapse: collapse;width: 100%;font-size: 12px;line-height: 1.5;">
                            <tbody><tr>
                                <td style="padding: 4px 8px;text-align: center;" colspan="2">
                                <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 1.5;">Formulir Penetapan DPJP</h2>
                                <h2 style="margin: 0;font-size: 16px;text-transform: uppercase;line-height: 1.5;">(Dokter Penanggung Jawab Pelayanan)</h2>
                                </td>
                            </tr>
                            </tbody></table>
                            <table border="0" style="border-collapse: collapse;width: 100%;border-color: #000;font-size: 12px;line-height: 1.5;margin-top: 24px;">
                            <tbody><tr>
                                <td style="padding: 4px 8px;" colspan="2">
                                <p style="margin: 0;margin-bottom: 4px;">Yang bertandatangan dibawah ini:</p>
                                <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                    <tbody><tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Nama</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowReg.aur_surat_dpjp_pj_name||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Umur</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%" 
                                    >
                                        <span v-if="rowReg.aur_surat_dpjp_pj_dob">
                                        {{rowReg.aur_surat_dpjp_pj_dob | moment("from", "now", true)}}
                                        </span>
                                        <span v-else> - </span>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Tempat, Tgl. Lahir</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowReg.aur_surat_dpjp_pj_pob||"-"}}, {{rowReg.aur_surat_dpjp_pj_dob | moment("DD MMMM YYYY") }}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Alamat</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">{{rowReg.aur_surat_dpjp_pj_alamat||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">No. Telp / HP</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">{{rowReg.aur_surat_dpjp_pj_no_hp||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Hubungan dengan Pasien</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowReg.aur_surat_dpjp_pj_hub_dengan_pasien||"-"}}
                                    </td>
                                    </tr>
                                </tbody></table>

                                <br>
                                <p style="margin: 0;margin-bottom: 4px;">Sebagai penanggungjawab atas pasien:</p>
                                <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                    <tbody><tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Nama</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowPasien.ap_fullname||"-"}}</td>
                                    </tr>

                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Umur / Tanggal Lahir</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%" 
                                    v-if="rowPasien.ap_dob"> {{rowPasien.ap_dob | moment("from", "now", true)}} / {{rowPasien.ap_dob | moment("DD MMMM YYYY") }}</td>
                                    </tr>

                                </tbody></table>
                                <br>
                                <p style="margin: 0;margin-bottom: 4px;">Dengan ini saya meminta untuk dirawat oleh dokter spesialis yaitu:</p>
                                <table style="width: 100%;border: 0;border-collapse: collapse;font-size: 12px;margin-bottom: 12px;line-height: 1.5;">
                                    <tbody><tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Nama Dokter</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowReg.dokter_ranap||"-"}}</td>
                                    </tr>
                                    <tr>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-left: 0;" width="20%">Spesialis</td>
                                    <td style="padding: 3px 0;vertical-align:top;" width="20">:</td>
                                    <td style="padding: 3px 8px;vertical-align:top;padding-right: 0;" width="80%">
                                    {{rowReg.poli_ranap||"-"}}</td>
                                    </tr>
                                </tbody></table>
                                <br>
                                <p style="margin: 0;margin-bottom: 8px;">Demikian surat ini dibuat tanpa ada unsur paksaan dari pihak manapun.</p>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 4px 8px;" width="50%">
                                <div style="text-align:center;">
                                    <div style="margin-bottom: 4px;">&nbsp;</div>
                                    <div style="margin-bottom: 48px;">Petugas</div>
                                    
                                    <span v-if="rowReg.aur_surat_dpjp_is_ttd == 'Digital'">
                                    <img :src="rowReg.aur_surat_dpjp_ttd_petugas ? rowReg.aur_surat_dpjp_ttd_petugas: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                    <br/>
                                    </span>
                                    <span v-else>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>         
                                    </span>                            

                                    <div>( <span>{{user.fullName||"-"}}</span> )</div>
                                </div>
                                </td>
                                <td style="padding: 4px 8px;" width="50%">
                                <div style="text-align:center;">
                                    <div style="margin-bottom: 4px;">Magelang, {{now}}</div>
                                    <div style="margin-bottom: 48px;">Pembuat Pernyataan</div>
                            
                                    <span v-if="rowReg.aur_surat_dpjp_is_ttd == 'Digital'">
                                    <img :src="rowReg.aur_surat_dpjp_ttd ? rowReg.aur_surat_dpjp_ttd: 'https://placehold.co/400x160'" alt="signature" width="200" id="ttdPasien">
                                    <br/>
                                    </span>
                                    <span v-else>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>         
                                    </span>                            

                                    <div>(<span> {{rowReg.aur_surat_dpjp_pj_name||"-"}} </span>)</div>
                                </div>
                                </td>
                            </tr>
                            </tbody></table>
                        </div>
                    </div>
                    <!-- End Copas Template -->
                </div>
                </div>
            </div>
        </div>
        
        <div :class="rowReg.aur_surat_dpjp_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>



<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'


const moment = require('moment')
export default{
    extends: GlobalVue, 
    components: {
      Datepicker
    },
    data(){
        return {
            rowPasien: {},
            rowData: {},
            rowReg: {}
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowReg[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowReg[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowReg.aur_surat_dpjp_is_ttd == 'Digital'){
                    if(!this.rowReg.aur_surat_dpjp_ttd){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                    if(!this.rowReg.aur_surat_dpjp_ttd_petugas){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }

                this.rowReg.aur_surat_dpjp_template = $(".document-framer").html()


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowReg
                            data.type = 'submit-data-ttd-surat-dpjp'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDSuratKematian(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-surat-dpjp',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                if(!this.rowReg.aur_surat_dpjp_pj_name){
                    this.rowReg.aur_surat_dpjp_pj_name = this.rowPasien.arpj_fullname
                }
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDSuratKematian()
        
        setTimeout(()=>{
            if(this.$refs['ttdPetugas']){
                this.$refs['ttdPetugas'].fromDataURL(this.rowReg.aur_surat_dpjp_ttd_petugas)
            }
            if(this.$refs['ttdPembuatPernyataan']){
                this.$refs['ttdPembuatPernyataan'].fromDataURL(this.rowReg.aur_surat_dpjp_ttd)
            }
            
        },1500)
    },
}      
</script>
