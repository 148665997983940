<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row" v-if="!rowSuratIzinPulang.ausip_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowSuratIzinPulang['ausip_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowSuratIzinPulang.ausip_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowSuratIzinPulang.ausip_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="mb-2">
                  <div class="form-group">
                    <label for="">Nama Dokter Pemeriksa<span class="text-danger">*</span></label>
                    <b-form-input class="form-control" v-model="rowSuratIzinPulang.ausip_ttd_dpjp_name">
                    </b-form-input>
                    <VValidate 
                        v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital'"
                        :name="'Nama Dokter Pemeriksa'" 
                        v-model="rowSuratIzinPulang.ausip_ttd_dpjp_name" 
                        :rules="{required : 1, max:128}"
                    />
                  </div>
                  <div class="form-group">
                  <label for="">Tanda Tangan Dokter Pemeriksa<span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdDokterDPJP">
                      <VueSignaturePad
                          class="sign-container"
                          width="100%"
                          height="160px"
                          ref="ttdDokterDPJP"
                      />
                      
                      <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdDokterDPJP','ausip_ttd_dpjp')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                          <span>Clear</span>
                      </button>
                      
                      <button type="button" @click="output('ttdDokterDPJP','ausip_ttd_dpjp')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                      </button>
                      </div>
                  </div>
                  
                  <VValidate 
                      v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital'"
                      :name="'TTD Dokter'" 
                      v-model="rowSuratIzinPulang.ausip_ttd_dpjp" 
                      :rules="{required : 1}"
                  />
                  </div>
                </div>

                <div class="mb-2">
                  <div class="form-group">
                    <label for="">Nama Pasien<span class="text-danger">*</span></label>
                    <b-form-input class="form-control" v-model="rowSuratIzinPulang.ausip_ttd_pasien_name">
                    </b-form-input>
                    <VValidate 
                        v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital'"
                        :name="'Nama Pasien'" 
                        v-model="rowSuratIzinPulang.ausip_ttd_pasien_name" 
                        :rules="{required : 1, max:128}"
                    />
                  </div>
                  <div class="form-group">
                  <label for="">Tanda Tangan Pasien<span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdPasien">
                      <VueSignaturePad
                          class="sign-container"
                          width="100%"
                          height="160px"
                          ref="ttdPasien"
                      />
                      <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPasien','ausip_ttd_pasien')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                          <span>Clear</span>
                      </button>
                      
                      <button type="button" @click="output('ttdPasien','ausip_ttd_pasien')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                      </button>
                      </div>
                  </div>
                  
                  <VValidate 
                      v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital'"
                      :name="'TTD Pasien'" 
                      v-model="rowSuratIzinPulang.ausip_ttd_pasien" 
                      :rules="{required : 1}"
                  />
                  </div>
                </div>

                <div class="mb-2">
                  <div class="form-group">
                    <label for="">Nama Kepala Ruang<span class="text-danger">*</span></label>
                    <b-form-input class="form-control" v-model="rowSuratIzinPulang.ausip_ttd_kepala_ruang_name">
                    </b-form-input>
                    <VValidate 
                        v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital'"
                        :name="'Nama Kepala Ruang'" 
                        v-model="rowSuratIzinPulang.ausip_ttd_kepala_ruang_name" 
                        :rules="{required : 1, max:128}"
                    />
                  </div>
                  <div class="form-group">
                  <label for="">Tanda Tangan Kepala Ruang<span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdKepalaRuang">
                      <VueSignaturePad
                          class="sign-container"
                          width="100%"
                          height="160px"
                          ref="ttdKepalaRuang"
                      />
                      <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdKepalaRuang','ausip_ttd_kepala_ruang')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                          <span>Clear</span>
                      </button>
                      
                      <button type="button" @click="output('ttdKepalaRuang','ausip_ttd_kepala_ruang')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                      </button>
                      </div>
                  </div>
                  <VValidate 
                      v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital'"
                      :name="'TTD Kepala Ruang'" 
                      v-model="rowSuratIzinPulang.ausip_ttd_kepala_ruang" 
                      :rules="{required : 1}"
                  />
                  </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="document-framer">
                    <div class="df-content">
                      <div style="position: relative; max-width: 700px;">
                        <div
                          style="height: 100%; position: relative; font-family: serif; color: rgb(51, 51, 51); line-height: 1.5; font-size: 12px;">
                          <div style="padding: 24px; box-sizing: border-box;">
                            <table style="border-collapse: collapse; width: 100%; font-size: 12px; line-height: 1.5;">
                              <tbody>
                                <tr>
                                  <td style="padding: 8px; border-bottom: 1px solid rgb(0, 0, 0);">
                                    <div>
                                      <div style="width: 10%; float: left; line-height: 68px; box-sizing: border-box; padding-right: 16px;">
                                        <img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" alt="" style="max-width: 100%;"></div>
                                      <div style="float: left; width: 90%;">
                                        <h2 style="margin: 0px 0px 2px; font-size: 16px; text-transform: uppercase; line-height: 22px;">
                                          Rumah Sakit Harapan</h2>
                                        <div>Jl. Panembahan Senopati No. 11 Magelang</div>
                                        <div>Telp. (0293) 364033 s/d 364035 Fax. (0293) 364037</div>
                                      </div>
                                      <div style="clear: both;"></div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th style="padding: 16px 8px 8px;">
                                    <h2 style="font-size: 16px; text-transform: uppercase; margin: 0px; display: inline-block;">FORM IJIN
                                      PULANG SEMENTARA</h2>
                                  </th>
                                </tr>
                                <tr>
                                  <td style="padding: 8px 8px 4px; text-align: justify;">
                                    <p style="margin-bottom: 5px;">Demi kepentingan hukum dan / atau menghormati hak pasien untuk:
                                      <span>{{rowPasien.ap_fullname}}</span></p>
                                    <p style="margin-bottom: 8px;">Maka kami, atas nama pihak RS HARAPAN MAGELANG mengijinkan kepada pasien
                                      atas nama:</p>
                                    <table style="border-collapse: collapse; font-size: 12px; line-height: 1.5; margin-bottom: 16px;">
                                      <tbody>
                                        <tr>
                                          <td style="vertical-align: top; padding: 2px 8px;">Nama</td>
                                          <td style="vertical-align: top;">:</td>
                                          <td style="vertical-align: top; padding: 2px 8px;">{{rowPasien.ap_fullname}}</td>
                                        </tr>
                                        <tr>
                                          <td style="vertical-align: top; padding: 2px 8px;">Tanggal Lahir</td>
                                          <td style="vertical-align: top;">:</td>
                                          <td style="vertical-align: top; padding: 2px 8px;">
                                            <span v-if="rowPasien.ap_dob">{{rowPasien.ap_dob | moment("DD MMMM YYYY") }}</span><span v-else> - </span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style="vertical-align: top; padding: 2px 8px;">No. RM</td>
                                          <td style="vertical-align: top;">:</td>
                                          <td style="vertical-align: top; padding: 2px 8px;">{{rowPasien.ap_code}}</td>
                                        </tr>
                                        <tr>
                                          <td style="vertical-align: top; padding: 2px 8px;">Alamat</td>
                                          <td style="vertical-align: top;">:</td>
                                          <td style="vertical-align: top; padding: 2px 8px;">
                                            {{ rowPasien.ap_address }}, {{ rowPasien.ap_rt ? 'RT ' + rowPasien.ap_rt : '' }} {{ rowPasien.ap_rw ? '/' + rowPasien.ap_rw : '' }} {{ rowPasien.ap_kelurahan ? '. Kel. ' + rowPasien.ap_kelurahan : '' }} {{ rowPasien.ap_kecamatan ? '. Kec. ' + rowPasien.ap_kecamatan : '' }}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style="vertical-align: top; padding: 2px 8px;">No. Telepon</td>
                                          <td style="vertical-align: top;">:</td>
                                          <td style="vertical-align: top; padding: 2px 8px;"></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <p style="margin-bottom: 8px;">Untuk PULANG SEMENTARA (<span>2 jam</span>) dengan didampingi oleh
                                      perawat RS HARAPAN MAGELANG :</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="3" style="padding: 4px 8px;">
                                    <p style="margin-top: 0px; margin-bottom: 8px;">Magelang, {{now}}</p>
                                    <table width="100%"
                                      style="border-collapse: collapse; font-size: 12px; line-height: 1.5; text-align: center; width: 100%; border-color: rgb(0, 0, 0);">
                                      <tbody>
                                        <tr>
                                          <td width="33.33%" style="padding: 8px;"></td>
                                          <td width="33.33%" style="padding: 8px;"></td>
                                          <td width="33.33%" style="padding: 4px 8px;">
                                            <div style="text-align: center;">
                                              <template v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital' && rowSuratIzinPulang.ausip_ttd_dpjp">
                                                <span>Dokter </span> <br />
                                                <img :src="rowSuratIzinPulang.ausip_ttd_dpjp" alt="signature" width="200">
                                              </template>
                                              <template v-else>
                                                <div style="margin-bottom: 48px;">Dokter </div>
                                              </template>
                                              <div v-if="rowSuratIzinPulang.ausip_ttd_dpjp_name">({{ rowSuratIzinPulang.ausip_ttd_dpjp_name }})</div>
                                              <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <p style="margin-top: 0px; margin-bottom: 20px; text-align: center;">Mengetahui :</p>
                            <table
                              style="border-collapse: collapse; font-size: 12px; line-height: 1.5; text-align: center; width: 100%; border: 1px solid rgb(51, 51, 51);">
                              <tbody>
                                <tr>
                                  <td width="33.33%" style="padding: 4px 8px;">
                                    <div style="text-align: center;">
                                      <template v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital' && rowSuratIzinPulang.ausip_ttd_pasien">
                                        <span>Pasien </span> <br />
                                        <img :src="rowSuratIzinPulang.ausip_ttd_pasien" alt="signature" width="200">
                                      </template>
                                      <template v-else>
                                        <div style="margin-bottom: 48px;">Pasien </div>
                                      </template>
                                      <div v-if="rowSuratIzinPulang.ausip_ttd_pasien_name">({{ rowSuratIzinPulang.ausip_ttd_pasien_name }})</div>
                                      <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                    </div>
                                  </td>
                                  <td width="33.33%" style="padding: 4px 8px;"></td>
                                  <td width="33.33%" style="padding: 4px 8px;">
                                    <div style="text-align: center;">
                                      <template v-if="rowSuratIzinPulang.ausip_is_ttd == 'Digital' && rowSuratIzinPulang.ausip_ttd_kepala_ruang">
                                        <span>Kepala Ruang</span> <br />
                                        <img :src="rowSuratIzinPulang.ausip_ttd_kepala_ruang" alt="signature" width="200">
                                      </template>
                                      <template v-else>
                                        <div style="margin-bottom: 48px;">Kepala Ruang</div>
                                      </template>
                                      <div v-if="rowSuratIzinPulang.ausip_ttd_kepala_ruang_name">({{ rowSuratIzinPulang.ausip_ttd_kepala_ruang_name }})</div>
                                      <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowSuratIzinPulang.ausip_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{
    extends: GlobalVue, 
    data(){
        return {
            rowSuratIzinPulang: {},
            rowPasien: {},
            rowReg: {},
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowSuratIzinPulang[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowSuratIzinPulang[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowSuratIzinPulang.ausip_is_ttd == 'Digital'){
                    if(!this.rowSuratIzinPulang.ausip_ttd_dpjp){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }

                this.rowSuratIzinPulang.ausip_template = $(".document-framer").html()


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowSuratIzinPulang
                            data.type = 'submit-data-ttd-surat-izin-pulang'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDSuratSakit(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-surat-izin-pulang',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDSuratSakit()
        setTimeout(()=>{
            if(this.$refs['ttdDokterDPJP']){
              this.$refs['ttdDokterDPJP'].fromDataURL(this.rowSuratIzinPulang.ausip_ttd_dpjp)
            }
            if(this.$refs['ttdPasien']){
              this.$refs['ttdPasien'].fromDataURL(this.rowSuratIzinPulang.ausip_ttd_pasien)
            }
            if(this.$refs['ttdKepalaRuang']){
              this.$refs['ttdKepalaRuang'].fromDataURL(this.rowSuratIzinPulang.ausip_ttd_kepala_ruang)
            }
        },1500)
    }
}      
</script>
