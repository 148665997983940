<template>
    <div class="content">
        <div class="card mb-2">
          <div class="card-header bg_head_panel">
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Surat Rujukan</h6>
            </div>
          </div>
  
        <validation-observer ref="VForm">
            <b-form @submit.prevent="doSubmit" class="card">
            <div class="form-underlying card-body p-3">
              <div class="row">
                <div class="col-md-4 col-xl-3">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Dokter yang merujuk</label>
                    <p> <b>{{rowReg.bu_full_name||"-"}}</b> </p>
                  </div>
                </div>

                <div class="col-md-5 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Faskes Tujuan <span class="text-danger">*</span> </label>
                    <b-form-input v-model="rowRujukan.aurujd_faskes_rujuk_sisrute_text" type="text" class="form-control" placeholder="Faskes Rujukan"/>
                    
                    <!--
                    <v-select class="diagnosa-section select-paging" placeholder="Cari Nama Faskes"
                        v-model="rowRujukan.aurujd_faskes_rujuk_sisrute" 
                        @input="inpMasterFaskesSisrute($event)"
                        @search="getMasterFaskesSisrute($event)"
                        :options="mFaskesSisrute" label="NAMA" value="KODE"
                        :clearable="true" :reduce="v=>v.KODE">
                        
                        <template slot="no-options">
                            <span></span>
                        </template>
                    </v-select>
                    -->

                    <VValidate :name="'Faskes'"
                    v-model="rowRujukan.aurujd_faskes_rujuk_sisrute_text"
                    :rules="{required : 1}" />
                  </div>
                </div>

                <div class="col-md-3 col-xl-3">
                  <div class="form-group">
                    <label>Alasan merujuk</label>
                    <p> <b>{{getConfigDynamic(Config.mr.alasanRujuk, rowRujukan.aupd_alasan_rujuk)||"-"}}</b> </p>
                    <p>{{rowRujukan.aupd_alasan_rujuk_text||"-"}}</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-xl-3">
                  <div class="form-group">
                    <label>Diagnosa Medis</label>
                    <p>{{rowRujukan.auod_diagnosa_kode_bpjs_text||"-"}}</p>
                  </div>
                </div>
                
                <div class="col-md-3 col-xl-3">
                  <div class="form-group">
                    <label>Kondisi Pasien Saat Dipindahkan</label>
                    <p>{{rowRujukan.ausd_catatan_dokter||"-"}}</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-xl-12">
                  <div class="form-group">
                    <label>Tanda Tanda Vital</label>

                    <div class="">
                      <table class="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Tekanan Darah</h4>
                                <p>{{rowRujukan.auod_ttv_tekanan_darah_min||"-"}}/{{rowRujukan.auod_ttv_tekanan_darah_max||"-"}}</p> 
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Nadi</h4>
                                <p>{{rowRujukan.auod_ttv_nadi||"-"}}x permenit</p> 
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Suhu</h4>
                                <p>{{rowRujukan.auod_ttv_suhu||"-"}} C</p> 
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>SPO2</h4>
                                <p>{{rowRujukan.auod_ttv_spo2||"-"}}</p> 
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                  <h4>Tingkat Kesadaran</h4>
                                  <p>{{rowRujukan.auod_gcs_total||0}} 
                                        <span v-if="rowRujukan.auod_gcs_total >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 12 && rowRujukan.auod_gcs_total <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 10 && rowRujukan.auod_gcs_total <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 7 && rowRujukan.auod_gcs_total <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 5 && rowRujukan.auod_gcs_total <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                        <span v-else-if="rowRujukan.auod_gcs_total >= 4 && rowRujukan.auod_gcs_total <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                        <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>

                                        <span style="margin-right:20px;">E: 
                                            {{rowRujukan.auod_response_mata||0}} 
                                        </span>
                                        <span style="margin-right:20px;">M: 
                                            {{rowRujukan.auod_response_motorik||0}}                                 
                                        </span>
                                        <span style="margin-right:20px;">V: 
                                            {{rowRujukan.auod_response_verbal||0}}
                                        </span>
                                    <br />(GCS)</p>
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Respiration Rate</h4>
                                <p>{{rowRujukan.auod_ttv_pernafasan||"-"}}x Permenit</p> 
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="result_tab">
                                <h4>Reflek Pupil </h4>
                                <b-form-textarea v-model="rowRujukan.aurujd_reflek_pupil" name="" id="" rows="3" class="form-control" />
                              </div>
                            </td>
                            <td>
                              <div class="result_tab">
                                <h4>Reflek Cahaya </h4>
                                <b-form-textarea v-model="rowRujukan.aurujd_reflek_cahaya" name="" id="" rows="3" class="form-control" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>                
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Alergi</label>
                    <b-form-textarea v-model="rowRujukan.aurujd_alergi" name="" id="" rows="3" class="form-control" />
                  </div>
                </div>
                
                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Riwayat Penyakit</label>
                    <b-form-textarea v-model="rowRujukan.aurujd_riwayat_penyakit" name="" id="" rows="3" class="form-control" />
                  </div>
                </div>

                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Tindakan yang telah dilakukan</label>
                    <b-form-textarea v-model="rowRujukan.aurujd_tindakan" name="" id="" rows="3" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Pemeriksaan yang telah dilakukan</label>
                    <b-form-textarea v-model="rowRujukan.aurujd_pemeriksaan" name="" id="" rows="3" class="form-control" />
                  </div>
                </div>
                
                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Pengobatan yang telah diberikan</label>
                    <b-form-textarea v-model="rowRujukan.aurujd_pengobatan" name="" id="" rows="3" class="form-control" />
                  </div>
                </div>

                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label for="dnrDibuatAtas">Perawatan pasien yang dibutuhkan</label>
                    <b-form-textarea v-model="rowRujukan.aurujd_perawatan_pasien" name="" id="" rows="3" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="row">
  
                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label>Peralatan Medis yang Terpasang<small class="txt_mandatory">*</small></label>
                    <b-form-radio-group :options="Config.mr.yesNoOptV2"
                        v-model="rowRujukan.aurujd_peralatan_is_terpasang" />

                    <VValidate :name="'Peralatan Medis yang Terpasang'"
                    v-model="rowRujukan.aurujd_peralatan_is_terpasang"
                    :rules="{required : 1}" />
                  </div>

                  <div class="form-group mt-2" v-if="rowRujukan.aurujd_peralatan_is_terpasang == 'Y'">
                    <label>Pilih Medis yang Terpasang<small class="txt_mandatory">*</small></label>
                    <v-select multiple class="vs__multiple" placeholder="Pilih Media Terpasang" v-model="rowRujukan.aurujd_peralatan_yang_terpasang"
                        :options="Config.mr.mediaTerpasang" label="text"
                        :reduce="v=>v.value">
                    </v-select>     
                  
                    <VValidate :name="'Pilih Media Terpasang'"
                    v-model="rowRujukan.aurujd_peralatan_yang_terpasang"
                    :rules="{required : 1}" />
                  </div>

                </div>

                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label>Persetujuan Resiko Jalan<small class="txt_mandatory">*</small></label>
                    <b-form-radio-group :options="Config.mr.yesNoOptV2"
                        v-model="rowRujukan.aurujd_persetujuan_risiko_jalan" />

                    <VValidate :name="'Persetujuan Resiko Jalan'"
                    v-model="rowRujukan.aurujd_persetujuan_risiko_jalan"
                    :rules="{required : 1}" />
                  </div>
                </div>
                
                <div class="col-md-4 col-xl-4">
                  <div class="form-group">
                    <label>Kejadian Selama Transfer<small class="txt_mandatory">*</small></label>
                    <b-form-radio-group :options="Config.mr.yesNoOptV2"
                        v-model="rowRujukan.aurujd_kejadian_transfer" />

                    <VValidate :name="'Kejiadian Selama Transfer'"
                    v-model="rowRujukan.aurujd_kejadian_transfer"
                    :rules="{required : 1}" />
                  </div>

                  <div class="form-group mt-2" v-if="rowRujukan.aurujd_kejadian_transfer == 'Y'">
                    <b-form-textarea type="text" v-model="rowRujukan.aurujd_kejadian_transfer_text" class="form-control form-control-sm d-block" placeholder="Kejadian Transfer" />
                    <VValidate :name="'Kejadian Transfer'"
                    v-model="rowRujukan.aurujd_kejadian_transfer_text"
                    :rules="{required : 1}" />
                  </div>
                </div>

                
              </div>

            </div>

            <!-- template -->
            
            <div class="card-footer">
              <div class="text-right">
                <button type="button" @click="$router.back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
            </b-form>
        </validation-observer>

        </div>
        <!-- /content area -->
        <!-- Footer -->
        <!-- /footer -->
    </div>
      
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
    extends: GlobalVue,   
    computed: {
        isParent(){
            return this.$parent
        },
        now(){
            return moment().format('DD MMM YYYY')
        },
    },
    data(){
        return {
            mFaskesSisrute: [],
            rowRujukan: {},
            rowPasien: {},
            rowReg: {},
            mrValidationRujukan: {},
            openTTD: false,
            options: {
              penColor: "#000",
            },
        }
    },
    methods: {
        
        
        getMasterFaskesSisrute: _.debounce(function (e) {
            if(e){
                let data = {
                    search : e,
                    type : 'select-faskes-sisrute'
                }
                Gen.apiRest(
                    "/do/"+'UGDFormDokter', 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    this.mFaskesSisrute = res.data
                })
            }
        }, 1000),
        inpMasterFaskesSisrute(e){
            if(e){
                let idx = this.mFaskesSisrute.findIndex(x => x.KODE == e) 
                this.rowRujukan.aurujd_faskes_rujuk_sisrute = this.mFaskesSisrute[idx]['KODE']
                this.rowRujukan.aurujd_faskes_rujuk_sisrute_text = this.mFaskesSisrute[idx]['NAMA']
            }
        },
        getConfigDynamic(master, value) {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index]['text']
                }
            }
            return text
        },
        simpanDNR(){
          let inval = 0
          if(this.rowRujukan.aud_form_dibuat_atas == 'Pasien'){
            if(this.rowRujukan.aud_ttd_pasien == null || this.rowRujukan.aud_ttd_perawat == null || this.rowRujukan.aud_ttd_saksi == null){
              inval = inval + 1
            }
          }
          else if(this.rowRujukan.aud_form_dibuat_atas == 'DPJP'){
            if(this.rowRujukan.aud_ttd_dpjp == null || this.rowRujukan.aud_ttd_dokter == null){
              inval = inval + 1
            }
          }
          else if(this.rowRujukan.aud_form_dibuat_atas == 'Keluarga'){
            if(this.rowRujukan.aud_ttd_keluarga == null || this.rowRujukan.aud_ttd_saksi == null){
              inval = inval + 1

            }
          }


          if(inval){
            return this.$swal({
              icon: 'error',
              title: 'Tanda tangan tidak lengkap',
            })
          }

          this.openTTD = false
        },  
        onBegin() {
          console.log('=== Begin ===')
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowRujukan[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowRujukan[field] = data
          }
        },
        openDoc(temp){
          if(this.rowRujukan.aud_form_dibuat_atas == 'DPJP' && !this.rowRujukan.aud_dpjp_usaha){
            return this.$swal({
              icon: 'error',
              title: 'Mohon isi data Pernyataan dan Instruksi Dokter terlebih dahulu'
            })
          }

          this.openTTD = true
          setTimeout(()=>{
            $(".sign-container canvas").attr("width",400)
            $(".sign-container canvas").attr("height",160)                
            this.$nextTick(() => {
              if(this.rowRujukan.aud_ttd_keluarga){
                if(this.$refs['ttdUtamaKeluarga']){
                  this.$refs['ttdUtamaKeluarga'].fromDataURL(this.rowRujukan.aud_ttd_keluarga)
                }
              }
              if(this.rowRujukan.aud_ttd_saksi){
                if(this.$refs['ttdSaksi']){
                  this.$refs['ttdSaksi'].fromDataURL(this.rowRujukan.aud_ttd_saksi)
                }
              }
              if(this.rowRujukan.aud_ttd_pasien){
                if(this.$refs['ttdPasien']){
                  this.$refs['ttdPasien'].fromDataURL(this.rowRujukan.aud_ttd_pasien)
                }
              }
              if(this.rowRujukan.aud_ttd_perawat){
                if(this.$refs['ttdPerawat']){                
                 this.$refs['ttdPerawat'].fromDataURL(this.rowRujukan.aud_ttd_perawat)
                }                
              }
              if(this.rowRujukan.aud_ttd_dpjp){
                if(this.$refs['ttdDpjp']){                
                 this.$refs['ttdDpjp'].fromDataURL(this.rowRujukan.aud_ttd_dpjp)
                }                
              }
              if(this.rowRujukan.aud_ttd_dokter){
                if(this.$refs['ttdDokter']){                
                 this.$refs['ttdDokter'].fromDataURL(this.rowRujukan.aud_ttd_dokter)
                }                
              }
            })
          },200)

        },
        apiGetDNR(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-surat-rujukan',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })

        },
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
          
        autoSave: _.debounce(function (data) {
          data.type = 'auto-save-data-surat-rujukan'
          Gen.apiRest(
            "/do/" + 'UGDTindakLanjut', {
              data: data
            },
            "POST"
          )
        }, 1000),

        doSubmit(){
            this.$refs['VForm'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                this.rowRujukan.aud_template_doc = $(".document-framer").html()
                this.rowRujukan.bu_full_name = this.rowReg.bu_full_name
                      
                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowRujukan

                            data.type = 'submit-data-surat-rujukan'
                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
    },  
    mounted() {
        this.apiGetDNR()
        
        setTimeout(()=>{
            this.getMasterFaskesSisrute(this.rowDNR.aurujd_faskes_rujuk_sisrute||"A")
        },1000)
    },
    watch: {
      rowRujukan: {
        handler(v) {
          this.autoSave(v)
        },
        deep: true
      }
    }
}      
</script>
