<template>
  <div class="content">
    <div class="card mb-2">
      <div class="card-header bg_head_panel">
        <div class="d-flex align-items-center">
          <h6 class="card-title font-weight-semibold">Laporan Persalinan</h6>
        </div>
      </div>

      <template v-if="!isDetail">
        <validation-observer ref="VForm">
          <b-form @submit.prevent="doSubmit" class="card">
            <div class="row">
              <div class="col-md-12">
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="" for="">Tanggal Pengkajian</label>
                        <div class="input-group">
                          <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                          </div>
                          <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="rowCatatanPersalinan.aucp_kajian_date">
                          </datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="" for="">Jam</label>
                        <div class="input-group">
                          <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                          </div>
                          <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowCatatanPersalinan.aucp_kajian_time">
                          </vue-timepicker>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="" for="">Apakah pasien mengalami KPD?</label>
                        <b-form-radio-group :options="Config.mr.yesNoOptV2"
                            v-model="rowCatatanPersalinan['aucp_kpd']" />
                      </div>
                    </div>
                    
                    <div class="col-md-3" v-if="rowCatatanPersalinan['aucp_kpd'] == 'Y'">
                      <div class="form-group">
                        <Uploader v-model="rowCatatanPersalinan.aucp_kpd_file" isDocument type="docimage" />
                
                        <VValidate name="File KPD" 
                        v-model="rowCatatanPersalinan.aucp_kpd_file"                  
                        :rules="{required: 1}" />
                      </div>
                    </div>
                  </div>

                  <validation-observer ref="VFormCatatanBBL">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <div class="row">
                            <div class="col-md-4">
                              <h6 class="card-title font-weight-semibold">Catatan BBL {{(dataCatatanPersalinan||[]).length ? '#1':''}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label class="" for="">Tanggal Lahir <span class="text-danger">*</span></label>
                                <div class="input-group">
                                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                                  </div>
                                  <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="rowCatatanPersalinan.aucp_dob">
                                  </datepicker>
                                </div>
                                <VValidate 
                                  name="Tanggal Lahir" 
                                  v-model="rowCatatanPersalinan.aucp_dob" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="" for="">Jam Lahir <span class="text-danger">*</span></label>
                                <div class="input-group">
                                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                                  </div>
                                  <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="rowCatatanPersalinan.aucp_tob">
                                  </vue-timepicker>
                                </div>
                                <VValidate 
                                  name="Jam Lahir" 
                                  v-model="rowCatatanPersalinan.aucp_tob" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="">Bayi Lahir <span class="text-danger">*</span></label>
                                <b-form-radio-group :options="mrBayiLahir" text-field="label" v-model="rowCatatanPersalinan.aucp_lahir" @change="changeBayiLahir()" class="mt-1 mb-1">
                                </b-form-radio-group>
                                <VValidate 
                                  name="Bayi Lahir" 
                                  v-model="rowCatatanPersalinan.aucp_lahir" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Bayi Pindah Ke Rawat Inap<span class="text-danger">*</span></label>
                                <b-form-radio-group :disabled="rowCatatanPersalinan.aucp_is_inserted == 'Y'?true:false"  :options="mrDipindahkanKe" text-field="text" v-model="rowCatatanPersalinan.aucp_bayi_pindah" >
                                </b-form-radio-group>
                                <VValidate 
                                  name="Bayi Dipindahkan" 
                                  v-model="rowCatatanPersalinan.aucp_bayi_pindah" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            
                            <div class="col-md-4 mb-3" v-if="rowCatatanPersalinan.aucp_lahir == 'MATI'">
                              <label for="">Sebab Kematian</label>
                              <b-form-textarea type="text" placeholder="Sebab kematian" v-model="rowCatatanPersalinan.aucp_bayi_lahir_mati_note" />
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="JenisKelamin">Jenis Kelamin <span class="text-danger">*</span></label>
                                <v-select :reduce="v => v.value" label="text" :options="mrGender" v-model="rowCatatanPersalinan.aucp_gender" :clearable="true" />
                                
                                <VValidate 
                                  name="Jenis Kelamin" 
                                  v-model="rowCatatanPersalinan.aucp_gender" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="bayiBeratBadan">Berat Badan</label>
                                <div class="input-group">
                                  <b-form-input type="text" id="bayiBeratBadan" placeholder="e.g. 3.97" :formatter="Formatter.decimalNumber" v-model="rowCatatanPersalinan.aucp_berat_badan" />
                                  <div class="input-group-append"><span class="input-group-text">gram</span></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="bayiPanjang">Panjang Badan </label>
                                <div class="input-group">
                                  <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="rowCatatanPersalinan.aucp_panjang_badan" />
                                  <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="LingkarKepala">Lingkar Kepala</label>
                                <div class="input-group">
                                  <b-form-input type="text" id="LingkarKepala" placeholder="" :formatter="Formatter.decimalNumber" v-model="rowCatatanPersalinan.aucp_lingkar_kepala" />
                                  <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="bayiPanjang">Lingkar Dada </label>
                                <div class="input-group">
                                  <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="rowCatatanPersalinan.aucp_lingkar_dada" />
                                  <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Kelainan Konginetal</label>

                                <div class="d-block">
                                  <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="rowCatatanPersalinan.aucp_kelainan_konginetal" class="mt-1 mb-1">
                                  </b-form-radio-group>
                                  <div class="mt-2" v-if="rowCatatanPersalinan.aucp_kelainan_konginetal == 'Y'">
                                    <b-form-input type="text" placeholder="sebutkan" v-model="rowCatatanPersalinan.aucp_kelainan_konginetal_note" />
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Untuk kelainan Jelek, Lahir Hidup kemudian meninggal</label>
                                <div class="d-block">
                                  <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="rowCatatanPersalinan.aucp_kelainan_jelek" class="mt-1 mb-2">
                                  </b-form-radio-group>

                                  <template v-if="rowCatatanPersalinan.aucp_kelainan_jelek == 'Y'">                          
                                    <div class="mb-1">
                                      <b-form-input v-model="rowCatatanPersalinan.aucp_kelainan_jelek_time" :formatter="Formatter.number" />
                                    </div>
                                    <v-select :options="mrKelainanJelekTimeType" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 200px;" v-model="rowCatatanPersalinan.aucp_kelainan_jelek_time_type" />
                                  </template>
                                </div>
                              </div>
                            </div>

                            <template v-if="rowCatatanPersalinan.aucp_lahir == 'HIDUP'">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Nama Bayi <span class="text-danger">*</span></label>
                                <b-form-input type="text" placeholder="e.g. Kusuma" v-model="rowCatatanPersalinan.aucp_nama_bayi"/>
                                <VValidate 
                                  name="Nama Bayi" 
                                  v-model="rowCatatanPersalinan.aucp_nama_bayi" 
                                  :rules="{required: 1, min: 3, max:256}"
                                />
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Dokter DPJP <span v-if="rowCatatanPersalinan.aucp_bayi_pindah == 'RANAP'" class="text-danger">*</span></label>
                                <v-select :reduce="v => v.value" label="text" :options="mDokter" v-model="rowCatatanPersalinan.aucp_dokter" :clearable="true" />
                                <VValidate 
                                  v-if="rowCatatanPersalinan.aucp_bayi_pindah == 'RANAP'"
                                  name="Dokter DPJP" 
                                  v-model="rowCatatanPersalinan.aucp_dokter" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            
                            <div class="col-md-3" v-if="rowCatatanPersalinan.aucp_bayi_pindah == 'RANAP'">
                              <div class="form-group">
                                <label for="">Jenis Rawat Inap <span class="text-danger">*</span></label>
                                <v-select :disabled="rowCatatanPersalinan.aucp_is_inserted == 'Y'" :reduce="v => v.value" label="text" :options="jenisRawatInap" v-model="rowCatatanPersalinan.aucp_jenis_ranap" :clearable="true"/>
                                <VValidate 
                                  name="Jenis Rawat Inap" 
                                  v-model="rowCatatanPersalinan.aucp_jenis_ranap" 
                                  :rules="{required: 1}"
                                />
                                
                              </div>
                            </div>
                            </template>

                            <div class="col-md-3" v-if="Object.keys(rowCatatanPersalinan||{}).length">
                              <div class="form-group">
                                <label for="bayiPanjang">Bayi Lahir Kembar </label>
                                <div class="input-group">
                                  <b-form-radio-group :disabled="rowCatatanPersalinan.aucp_is_lock_bayi_kembar == 'Y'?true:false" :options="Config.mr.yesNoOptV2" text-field="text" v-model="rowCatatanPersalinan.aucp_is_bayi_kembar" @change="changeInputKembar($event)" class="radio-inline mt-1 mb-1">
                                  </b-form-radio-group>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12" v-if="Object.keys(rowCatatanPersalinan||{}).length && rowCatatanPersalinan.aucp_lahir == 'HIDUP'">
                              <div class="d-block float-right text-right">
                                      
                              <button type="button" v-if="rowCatatanPersalinan.aucp_is_inserted == 'Y'" class="btn btn-outline-warning-2 mt-3 mr-2" @click="updateData()">
                              Update Data Pasien
                              </button>
                              
                              <button type="button" class="btn btn-outline-success mt-3" @click="addReservasi()">
                              {{rowCatatanPersalinan.aucp_is_inserted == 'Y' ? 'Kirimkan ulang data Pasien' : 'Kirimkan data Pasien' }}

                              </button>
                                <small class="text-info d-flex text-right mt-1" 
                                v-if="rowCatatanPersalinan.aucp_inserted_date && rowCatatanPersalinan.aucp_is_inserted == 'Y'"
                                > *Terkirim pada : 
                                  <span>{{rowCatatanPersalinan.aucp_inserted_date|moment("DD MMMM YYYY, HH:mm")}}
                                  <span v-if="rowCatatanPersalinan.aucp_inserted_by">, Oleh {{rowCatatanPersalinan.aucp_inserted_by||"-"}}</span>
                                  </span>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </validation-observer>
                  
                  <template v-if="rowCatatanPersalinan.aucp_is_bayi_kembar == 'Y'">
                  <validation-observer ref="VFormCatatanBBLKembar">
                  <div class="row">
                    <div class="col-md-12" v-for="(v,k) in (dataCatatanPersalinan||[])" :key="k">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <div class="row">
                            <div class="col-md-4">
                              <h6 class="card-title font-weight-semibold">Catatan BBL #{{k+2}}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label class="" for="">Tanggal Lahir <span class="text-danger">*</span></label>
                                <div class="input-group">
                                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-calendar"></i></span>
                                  </div>
                                  <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="v.aucb_tanggal_lahir">
                                  </datepicker>
                                  
                                </div>
                                
                                <VValidate 
                                  message="Tanggal Lahir" 
                                  :name="'Tanggal Lahir '+(k+1)"
                                  v-model="v.aucb_tanggal_lahir" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label class="" for="">Jam Lahir <span class="text-danger">*</span></label>
                                <div class="input-group">
                                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span>
                                  </div>
                                  <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="v.aucb_jam_lahir">
                                  </vue-timepicker>
                                </div>
                                
                                <VValidate 
                                  message="Jam Lahir" 
                                  :name="'Jam Lahir '+(k+1)"
                                  v-model="v.aucb_jam_lahir" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="">Bayi Lahir <span class="text-danger">*</span></label>
                                <b-form-radio-group :options="mrBayiLahir" text-field="label" v-model="v.aucb_bayi_lahir" class="mt-1 mb-1">
                                </b-form-radio-group>
                                
                                <VValidate 
                                  message="Bayi Lahir" 
                                  :name="'Bayi Lahir '+(k+1)"
                                  v-model="v.aucb_bayi_lahir" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Bayi Pindah Ke Rawat Inap<span class="text-danger">*</span></label>
                                <b-form-radio-group :disabled="v.aucb_is_inserted == 'Y'?true:false"  :options="mrDipindahkanKe" text-field="text" v-model="v.aucb_bayi_pindah" >
                                </b-form-radio-group>
                                <VValidate 
                                  name="Bayi Dipindahkan" 
                                  v-model="v.aucb_bayi_pindah" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            
                            <div class="col-md-4 mb-3" v-if="v.aucb_bayi_lahir == 'MATI'">
                              <label for="">Sebab Kematian</label>
                              <b-form-textarea type="text" placeholder="Sebab kematian" v-model="v.aucb_sebab_kematian" />
                            </div>

                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="JenisKelamin">Jenis Kelamin <span class="text-danger">*</span></label>
                                <v-select :reduce="v => v.value" label="text" :options="mrGender" v-model="v.aucb_jenis_kelamin" :clearable="true" />
                                  
                                <VValidate 
                                  message="Jenis Kelamin" 
                                  :name="'Jenis Kelamin '+(k+1)"
                                  v-model="v.aucb_jenis_kelamin" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="bayiBeratBadan">Berat Badan</label>
                                <div class="input-group">
                                  <b-form-input type="text" id="bayiBeratBadan" placeholder="e.g. 3.97" :formatter="Formatter.decimalNumber" v-model="v.aucb_bb"/>
                                  <div class="input-group-append"><span class="input-group-text">gram</span></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="bayiPanjang">Panjang Badan </label>
                                <div class="input-group">
                                  <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="v.aucb_tb" />
                                  <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="LingkarKepala">Lingkar Kepala</label>
                                <div class="input-group">
                                  <b-form-input type="text" id="LingkarKepala" placeholder="" :formatter="Formatter.decimalNumber" v-model="v.aucb_lingkar_kepala" />
                                  <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-3 col-lg-2">
                              <div class="form-group">
                                <label for="bayiPanjang">Lingkar Dada </label>
                                <div class="input-group">
                                  <b-form-input type="text" id="bayiPanjang" placeholder="e.g. 75" :formatter="Formatter.decimalNumber" v-model="v.aucb_lingkar_dada"/>
                                  <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Kelainan Konginetal</label>

                                <div class="d-block">
                                  <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="v.aucb_kelainan_konginetal" class="mt-1 mb-1">
                                  </b-form-radio-group>
                                  <div class="mt-2" v-if="v.aucb_kelainan_konginetal == 'Y'">
                                    <b-form-input type="text" placeholder="sebutkan" v-model="v.aucb_kelainan_konginetal_text"/>
                                  </div>
                                </div>

                              </div>
                            </div>
                            

                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Untuk kelainan Jelek, Lahir Hidup kemudian meninggal</label>
                                <div class="d-block">
                                  <b-form-radio-group :options="Config.mr.yesNoOptV6" v-model="v.aucb_kelainan_jelek" class="mt-1 mb-1">
                                  </b-form-radio-group>

                                  <template v-if="v.aucb_kelainan_jelek == 'Y'">                          
                                    <div class="mt-2 mb-1">
                                      <b-form-input v-model="v.aucb_kelainan_jelek_text" :formatter="Formatter.number" />
                                    </div>
                                    <v-select :options="mrKelainanJelekTimeType" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 200px;" v-model="v.aucb_kelainan_jelek_text_2" />
                                  </template>
                                </div>
                              </div>
                            </div>
                            

                            <template v-if="v.aucb_bayi_lahir == 'HIDUP'">
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Nama Bayi <span class="text-danger">*</span></label>
                                <b-form-input type="text" placeholder="e.g. Kusuma" v-model="v.aucb_nama_bayi"/>
                                <VValidate 
                                  name="Nama Bayi" 
                                  v-model="v.aucb_nama_bayi" 
                                  :rules="{required: 1, min: 3, max:256}"
                                />
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="">Dokter DPJP <span class="text-danger" v-if="v.aucb_bayi_pindah == 'RANAP'">*</span></label>
                                <v-select :reduce="v => v.value" label="text" :options="mDokter" v-model="v.aucb_dokter" :clearable="true" />
                                <VValidate 
                                  v-if="v.aucb_bayi_pindah == 'RANAP'"
                                  message="Dokter DPJP" 
                                  :name="'Dokter DPJP '+(k+1)"
                                  v-model="v.aucb_dokter" 
                                  :rules="{required: 1}"
                                />
                              </div>
                            </div>
                            <div class="col-md-3" v-if="v.aucb_bayi_pindah == 'RANAP'">
                              <div class="form-group">
                                <label for="">Jenis Rawat Inap <span class="text-danger">*</span></label>
                                <v-select :disabled="v.aucb_is_inserted == 'Y'?true:false" :reduce="v => v.value" label="text" :options="jenisRawatInap" v-model="v.aucb_jenis_ranap" :clearable="true"/>
                                <VValidate 
                                  message="Jenis Rawat Inap" 
                                  :name="'Jenis Rawat Inap '+(k+1)"
                                  v-model="v.aucb_jenis_ranap" 
                                  :rules="{required: 1}"
                                />
                                
                              </div>
                            </div>
                            </template>

                            <div class="col-md-12">
                              <div class="d-block float-right text-right">
                                <button v-if="v.aucb_is_inserted != 'Y'" type="button" class="btn btn-outline-danger mt-3 mr-2" @click="deleteCatatanBBL(v,k)">
                                  Hapus Catatan BBL</button>

                                <button type="button" v-if="v.aucb_is_inserted == 'Y'" class="btn btn-outline-warning-2 mt-3 mr-2" @click="updateDataCatatanBBL(v,k)">
                                Update Data Pasien
                                </button>
                                
                                <button type="button" v-if="v.aucb_bayi_lahir == 'HIDUP'" class="btn btn-outline-success mt-3" @click="addReservasiKembar(v,k)">
                                  {{v.aucb_is_inserted == 'Y' ? 'Kirimkan ulang data Pasien' : 'Kirimkan data Pasien' }}                          
                                </button>

                                <small class="text-info d-flex text-right mt-1" 
                                v-if="v.aucb_inserted_date && v.aucb_is_inserted == 'Y'"
                                > *Terkirim pada : 
                                  <span>{{v.aucb_inserted_date|moment("DD MMMM YYYY, HH:mm")}}</span><span v-if="v.aucb_inserted_by">, Oleh {{v.aucb_inserted_by||"-"}}</span>
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 text-center mb-3">
                      <button type="button" class="btn btn-outline-primary" @click="addCatatanBBL()">Tambah Catatan BBL</button>
                    </div>
                  </div>
                  </validation-observer>
                  </template>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <div class="row">
                            <div class="col-md-4">
                              <h6 class="card-title font-weight-semibold">Keadaan ibu Paska Persalinan </h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-5">
                              <div class="form-group">
                                <label>Keadaan Umum</label>
                                <b-form-input type="text" v-model="rowCatatanPersalinan.aucp_ki_keadaan_umum" />
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Tekanan Darah</label>
                                <div class="input-group">
                                  <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="rowCatatanPersalinan.aucp_ki_sistolik" :formatter="Formatter.number" />
                                  <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                                  <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="rowCatatanPersalinan.aucp_ki_diastolik" :formatter="Formatter.number" />
                                  <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                                </div>

                                <VValidate name="Tekanan Darah" :value="rowCatatanPersalinan.aucp_ki_sistolik && rowCatatanPersalinan.aucp_ki_diastolik" :rules="mrValidationCatatanPersalinan.aucp_aucp_ki_sistolik" />
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Nadi</label>
                                <div class="input-group">
                                  <b-form-input type="text" class="form-control" v-model="rowCatatanPersalinan.aucp_nadi" :formatter="Formatter.number" />

                                  <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                  <div class="input-group-append">
                                    <v-select :options="MonitoringOptions.mrNadi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="rowCatatanPersalinan.aucp_nadi_type" />
                                  </div>
                                </div>

                                <VValidate name="Nadi" :value="rowCatatanPersalinan.aucp_nadi && rowCatatanPersalinan.aucp_nadi_type" :rules="mrValidationCatatanPersalinan.aucp_aucp_nadi" />
                              </div>
                            </div>                      
                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Suhu Badan</label>
                                <div class="form-row">
                                  <div class="col-md-12">
                                    <div class="input-group">
                                      <b-form-input 
                                        type="text" 
                                        v-model="rowCatatanPersalinan.aucp_suhu_badan" 
                                        :formatter="Formatter.decimalNumber" 
                                       
                                      />
                                      <div class="input-group-append"><span class="input-group-text">Celcius</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Uterus</label>
                                <div class="input-group">
                                  <v-select :options="mrUterus" v-model="rowCatatanPersalinan.aucp_uterus" style="width: 100%;" />
                                </div>
                              </div>
                            </div>
                            
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="">Lama Kala I</label>
                                    <div class="input-group">
                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_kala_1_jam" :formatter="Formatter.number" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_kala_1_menit" :formatter="Formatter.number" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">menit</span></div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="">Lama Kala II</label>
                                    <div class="input-group">
                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_kala_2_jam" :formatter="Formatter.number" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_kala_2_menit" :formatter="Formatter.number" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">menit</span></div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="">Lama Kala III</label>
                                    <div class="input-group">
                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_kala_3_jam" :formatter="Formatter.number" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_kala_3_menit" :formatter="Formatter.number" type="text" class="form-control" />
                                      <div class="input-group-append"><span class="input-group-text">menit</span></div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="">Lama Persalinan</label>
                                    <div class="input-group">
                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_persalinan_jam" :formatter="Formatter.number" type="text" class="form-control" readonly />
                                      <div class="input-group-append"><span class="input-group-text">jam</span></div>

                                      <b-form-input v-model="rowCatatanPersalinan.aucp_lama_persalinan_menit" :formatter="Formatter.number" type="text" class="form-control" readonly />
                                      <div class="input-group-append"><span class="input-group-text">menit</span></div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div>
                                    <label>Komplikasi Persalinan</label>
                                    <b-form-input :formatter="Formatter.normalText" v-model="rowCatatanPersalinan.aucp_komplikasi_persalinan"  type="text" class="form-control" />
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="bayiWarnaKulit">Ketuban</label>
                                    <b-form-input :formatter="Formatter.normalText" v-model="rowCatatanPersalinan.aucp_ketuban"
                                      type="text" class="form-control" />
                                  </div>
                                </div>                          

                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="bayiWarnaKulit">Perdarahan Kala III<strong class="text-danger">*</strong></label>

                                    <div class="input-group">
                                      <b-form-input type="text" placeholder="Perdarahan Kala III" v-model="rowCatatanPersalinan.aucp_perdarahan_kala_3" :formatter="Formatter.number" />
                                      <div class="input-group-append"><span class="input-group-text">cc</span></div>
                                    </div>

                                    <VValidate
                                      name="Perdarahan Kala III"
                                      v-model="rowCatatanPersalinan.aucp_perdarahan_kala_3"
                                      :rules="mrValidationCatatanPersalinan.aucp_perdarahan_kala_3"
                                    />
                                  </div>
                                </div>
                                
                                <div class="col-md-3">
                                  <div class="form-group">
                                    <label for="bayiWarnaKulit">Perdarahan Kala IV<strong class="text-danger">*</strong></label>

                                    <div class="input-group">
                                      <b-form-input type="text" placeholder="Perdarahan Kala IV" v-model="rowCatatanPersalinan.aucp_perdarahan_kala_4" :formatter="Formatter.number" />
                                      <div class="input-group-append"><span class="input-group-text">cc</span></div>
                                    </div>

                                    <VValidate
                                      name="Perdarahan Kala IV"
                                      v-model="rowCatatanPersalinan.aucp_perdarahan_kala_4"
                                      :rules="mrValidationCatatanPersalinan.aucp_perdarahan_kala_4"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="">Kondisi Bayi <strong class="text-danger">*</strong></label>
                                <b-form-radio-group :options="Config.mr.sehatTidakSehat" text-field="text" v-model="rowCatatanPersalinan.aucp_p_kondisi_bayi" class="custom-block mt-1 mb-1">
                                </b-form-radio-group>
                                
                                <VValidate
                                  name="Kondisi Bayi"
                                  v-model="rowCatatanPersalinan.aucp_p_kondisi_bayi"
                                  :rules="mrValidationCatatanPersalinan.aucp_p_kondisi_bayi"
                                />
                              </div>
                            </div>
                            

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg_head_panel">
                          <div class="row">
                            <div class="col-md-4">
                              <h6 class="card-title font-weight-semibold">Placenta</h6>
                            </div>
                          </div>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="bayiWarnaKulit">Bentuk <strong class="text-danger">*</strong></label>
                                <b-form-input type="text" v-model="rowCatatanPersalinan.aucp_p_bentuk" placeholder="Bentuk" />

                                <VValidate
                                  name="Bentuk"
                                  v-model="rowCatatanPersalinan.aucp_p_bentuk"
                                  :rules="mrValidationCatatanPersalinan.aucp_p_bentuk"
                                />
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="bayiWarnaKulit">Tali Pusat<strong class="text-danger">*</strong></label>
                                <b-form-input type="text" v-model="rowCatatanPersalinan.aucp_p_tali_pusat" placeholder="Tali Pusat" />

                                <VValidate
                                  name="Tali Pusat"
                                  v-model="rowCatatanPersalinan.aucp_p_tali_pusat"
                                  :rules="mrValidationCatatanPersalinan.aucp_p_tali_pusat"
                                />
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="form-group">
                                <label for="bayiWarnaKulit">Kulit Ketuban<strong class="text-danger">*</strong></label>
                                <v-select :options="mrKulitKetuban" v-model="rowCatatanPersalinan.aucp_p_kulit_ketuban" />

                                <VValidate
                                  name="Kulit Ketuban"
                                  v-model="rowCatatanPersalinan.aucp_p_kulit_ketuban"
                                  :rules="mrValidationCatatanPersalinan.aucp_p_kulit_ketuban"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="text-right">
                    <button type="button" class="btn" @click="$router.back()">Kembali</button>
                    <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </validation-observer>
      </template>

      <template v-else>      
        <div class="mb-3">
          <table class="table table-bordered table-sm">
            <tr>
              <td width="50%">
                <div class="result_tab">
                  <h4>Tgl Pengkajian</h4>
                  <p>{{ rowCatatanPersalinan.aucp_kajian_date | moment("LL") }}</p>
                </div>
              </td>
              <td width="50%">
                <div class="result_tab">
                  <h4>Jam</h4>
                  <p>{{ rowCatatanPersalinan.aucp_kajian_time }}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="card">
          <div class="card-header bg_head_panel">
            <div class="row">
              <div class="col-md-4">
                <h6 class="card-title font-weight-semibold">Catatan BBL {{(dataCatatanPersalinan||[]).length ? '#1':''}}</h6>
              </div>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <tr>
              <td colspan="4">
                <div class="result_tab">
                  <h4>Nama Bayi</h4>
                  <p>{{ rowCatatanPersalinan.aucp_nama_bayi || "-" }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Tanggal Lahir</h4>
                  <p>{{ rowCatatanPersalinan.aucp_dob | moment("LL") }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Jam lahir</h4>
                  <p>{{ rowCatatanPersalinan.aucp_tob }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Bayi Lahir</h4>
                  <p class="text-capitalize">{{ (rowCatatanPersalinan.aucp_lahir||"").toLowerCase() }}
                    <span> - {{getConfigDynamic(mrDipindahkanKe,rowCatatanPersalinan.aucp_bayi_pindah)||"-"}}</span>
                  </p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Jenis Kelamin</h4>
                  <p>{{ rowCatatanPersalinan.aucp_gender == "W" ? "Perempuan" :  rowCatatanPersalinan.aucp_gender == "P" ? "Laki-Laki": "Tidak Diketahui" }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Berat Badan</h4>
                  <p>{{ rowCatatanPersalinan.aucp_berat_badan }}gram</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Panjang Badan</h4>
                  <p>{{ rowCatatanPersalinan.aucp_panjang_badan }}cm</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Lingkar Kepala</h4>
                  <p>{{ rowCatatanPersalinan.aucp_lingkar_kepala }}cm</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Lingkar Dada</h4>
                  <p>{{ rowCatatanPersalinan.aucp_lingkar_dada }}cm</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Kelainan Konginetal</h4>
                  <p>{{ rowCatatanPersalinan.aucp_kelainan_konginetal == "Y" ? "Ya" : "Tidak" }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Untuk kelainan Jelek, Lahir Hidup kemudian Meninggal</h4>
                  <p>{{ rowCatatanPersalinan.aucp_kelainan_jelek == "Y" ? "Ada" : "Tidak ada" }} {{ rowCatatanPersalinan.aucp_kelainan_jelek == "Y" ? `, ${rowCatatanPersalinan.aucp_kelainan_jelek_time||"-"} ${(rowCatatanPersalinan.aucp_kelainan_jelek_time_type||"").toLowerCase()}` : "" }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Bayi Lahir Mati</h4>
                  <p>{{ rowCatatanPersalinan.aucp_lahir == "MATI" ? "Ya" : "Tidak" }}  {{ rowCatatanPersalinan.aucp_lahir == "MATI" ? `, ${rowCatatanPersalinan.aucp_bayi_lahir_mati_note}` : "" }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Bayi Lahir Kembar</h4>
                  <p>{{ rowCatatanPersalinan.aucp_is_bayi_kembar == "Y" ? "Ya" : "Tidak" }} </p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        
        <template v-if="(dataCatatanPersalinan||[]).length">
        <div class="card" v-for="(v,k) in (dataCatatanPersalinan||[])" :key="k">
          <div class="card-header bg_head_panel">
            <div class="row">
              <div class="col-md-4">
                <h6 class="card-title font-weight-semibold">Catatan BBL #{{k+2}}</h6>
              </div>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <tr>
              <td colspan="4">
                <div class="result_tab">
                  <h4>Nama Bayi</h4>
                  <p>{{ v.aucb_nama_bayi || "-" }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Tanggal Lahir</h4>
                  <p>{{ v.aucb_tanggal_lahir | moment("LL") }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Jam lahir</h4>
                  <p>{{ v.aucb_jam_lahir }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Bayi Lahir</h4>
                  <p class="text-capitalize">{{ (v.aucb_bayi_lahir||"").toLowerCase() }}
                    <span> - {{getConfigDynamic(mrDipindahkanKe,v.aucb_bayi_pindah)||"-"}}</span>
                  </p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Jenis Kelamin</h4>
                  <p>
                    {{ v.aucb_jenis_kelamin == "W" ? "Perempuan" :  v.aucb_jenis_kelamin == "P" ? "Laki-Laki": "Tidak Diketahui" }}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Berat Badan</h4>
                  <p>{{ v.aucb_bb }}gram</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Panjang Badan</h4>
                  <p>{{ v.aucb_tb }}cm</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Lingkar Kepala</h4>
                  <p>{{ v.aucb_lingkar_kepala }}cm</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Lingkar Dada</h4>
                  <p>{{ v.aucb_lingkar_dada }}cm</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Kelainan Konginetal</h4>
                  <p>{{ v.aucb_kelainan_konginetal == "Y" ? "Ya" : "Tidak" }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Untuk kelainan Jelek, Lahir Hidup kemudian Meninggal</h4>
                  <p>{{ v.aucb_kelainan_jelek == "Y" ? "Ada" : "Tidak ada" }} {{ v.aucb_kelainan_jelek == "Y" ? `, ${v.aucb_kelainan_jelek_text||"-"} ${(v.aucb_kelainan_jelek_text_2||"").toLowerCase()}` : "" }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Bayi Lahir Mati</h4>
                  <p>{{ v.aucb_bayi_lahir == "MATI" ? "Ya" : "Tidak" }}  {{ v.aucb_bayi_lahir == "MATI" ? `, ${v.aucb_sebab_kematian}` : "" }}</p>
                </div>
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        </template>

        <div class="card">
          <div class="card-header bg_head_panel">
            <div class="row">
              <div class="col-md-4">
                <h6 class="card-title font-weight-semibold">Keadaan ibu Paska Persalinan </h6>
              </div>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Keadaan Umum</h4>
                  <p>{{ rowCatatanPersalinan.aucp_ki_keadaan_umum }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Tekanan Darah</h4>
                  <p>{{ rowCatatanPersalinan.aucp_ki_sistolik }}/{{ rowCatatanPersalinan.aucp_ki_diastolik }}mmHG</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Nadi</h4>
                  <p>{{ rowCatatanPersalinan.aucp_nadi }}x/mnt</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Suhu Badan</h4>
                  <p>{{ rowCatatanPersalinan.aucp_suhu_badan }}&deg;C</p>
                </div>
              </td>
              <td colspan="2">
                <div class="result_tab">
                  <h4>Uterus</h4>
                  <p>{{ rowCatatanPersalinan.aucp_uterus }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Lama Kala I</h4>
                  <p>{{ rowCatatanPersalinan.aucp_lama_kala_1_jam || 0 }} Jam {{ rowCatatanPersalinan.aucp_lama_kala_1_menit || 0 }} Menit</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Lama Kala II</h4>
                  <p>{{ rowCatatanPersalinan.aucp_lama_kala_2_jam || 0 }} Jam {{ rowCatatanPersalinan.aucp_lama_kala_2_menit || 0 }} Menit</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Perdarahan Kala III</h4>
                  <p>{{ rowCatatanPersalinan.aucp_lama_kala_3_jam || 0 }} Jam {{ rowCatatanPersalinan.aucp_lama_kala_3_menit || 0 }} Menit</p>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="result_tab">
                  <h4>Lama Persalinan</h4>
                  <p>{{ rowCatatanPersalinan.aucp_lama_persalinan_jam || 0 }} Jam {{ rowCatatanPersalinan.aucp_lama_persalinan_menit || 0 }} Menit</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Komplikasi Persalinan</h4>
                  <p>{{ rowCatatanPersalinan.aucp_komplikasi_persalinan||'-' }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Ketuban</h4>
                  <p>{{ rowCatatanPersalinan.aucp_ketuban||'-' }}</p>
                </div>
              </td>
            </tr>
            
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Perdarahan Kala III</h4>
                  <p>{{ rowCatatanPersalinan.aucp_perdarahan_kala_3||0 }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Perdarahan Kala IV</h4>
                  <p>{{ rowCatatanPersalinan.aucp_perdarahan_kala_4||0 }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Kondisi Bayi</h4>
                  <p>{{ rowCatatanPersalinan.aucp_p_kondisi_bayi||"-" }}</p>
                </div>
              </td>
            </tr>

          </table>
        </div>
        
        <div class="card">
          <div class="card-header bg_head_panel">
            <div class="row">
              <div class="col-md-4">
                <h6 class="card-title font-weight-semibold">Placenta</h6>
              </div>
            </div>
          </div>
          <table class="table table-bordered table-sm">
            <tr>
              <td>
                <div class="result_tab">
                  <h4>Bentuk</h4>
                  <p>{{ rowCatatanPersalinan.aucp_p_bentuk }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Tali Pusat</h4>
                  <p>{{ rowCatatanPersalinan.aucp_p_tali_pusat }}</p>
                </div>
              </td>
              <td>
                <div class="result_tab">
                  <h4>Kulit Ketuban</h4>
                  <p>{{ rowCatatanPersalinan.aucp_p_kulit_ketuban }}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="card-footer">
          <div class="text-right">
            <button type="button" class="btn" @click="$router.back()">Kembali</button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
  extends: GlobalVue, 
  components: {
    Datepicker,
    VueTimepicker
  },
  data(){
    return {
      rowCatatanPersalinan: {},
      dataCatatanPersalinan: [],
      mrValidationCatatanPersalinan: {},
      mDokter: [],
      mrBayiLahir: [],
      mrKelainanJelekTimeType: [],
      mrUterus: [],
      mrKulitKetuban: [],
      
      jenisRawatInap: [
        {
          text: "Perinatologi",
          value: 2
        },
        {
          text: "Kritis",
          value: 4
        },
      ],
      mrGender: [
        {
          text: 'Laki-Laki',
          value: 'P'
        },
        {
          text: 'Perempuan',
          value: 'W'
        },
        {
          text: 'Tidak Diketahui',
          value: 'TK'
        },
      ],
      mrDipindahkanKe: [
        {
          text : "Tidak",
          value : "UGD",
        },
        {
          text : "Ya",
          value : "RANAP",
        },
      ]
    }
  }, 
  mounted() {
    this.apiGetCatatanPersalinan()
  },
  methods: {
    
    assetLocal(img) {
      let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL + "/" : "/"
      img = def + img
      return img
    },
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },

    changeInputKembar(e){
      if(e == 'Y'){
        this.addCatatanBBL()  
      }else{
        this.deleteAllCatatanBBL()
      } 
    },

    addCatatanBBL(){
      console.log(this.isParent.rowReg.aur_id)
      let data = {
        aucb_aur_id: this.isParent.rowReg.aur_id,
        aucb_aucp_id: this.rowCatatanPersalinan.aucp_id,
        aucb_tanggal_lahir: this.rowCatatanPersalinan.aucp_dob,
        aucb_jam_lahir: this.rowCatatanPersalinan.aucp_tob||"",
        aucb_dokter: null,
        aucb_jenis_ranap: null,
        aucb_bb: null,
        aucb_tb: null,
        aucb_lingkar_kepala: null,
        aucb_lingkar_dada: null,
        aucb_nama_bayi: this.isParent.row.ap_fullname,
        aucb_inserted_date: null,
        aucb_inserted_by: null,
        type: 'add-catatan-persalinan-data',
      }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.dataCatatanPersalinan.push(res.data.data)
      }) 
    },
    deleteCatatanBBL(row,k){
      let data = row
      data.type = 'remove-catatan-persalinan-data'
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.dataCatatanPersalinan.splice(k,1)
      }) 
    },
    deleteAllCatatanBBL(){
      let data = {
        type: 'remove-all-catatan-persalinan-data',
        id_reg: this.$route.params.pageSlug
     }
      Gen.apiRest(
          "/do/" + this.modulePage, {
          data: data
          },
          "POST"
      ).then(res => {
        this.dataCatatanPersalinan = []
      }) 
    },

    autoSaveData: _.debounce(function (data) {
      let dataPost = {
          type: 'auto-save-data-catatan-persalinan-data',
          data: data
      }
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: dataPost
        },
        "POST"
      )
    }, 1000),
    apiGetCatatanPersalinan(){
      this.loadingOverlay = true
      Gen.apiRest('/do/' + 'UGDTindakLanjut', {
        data: {
          type: 'get-data-catatan-persalinan',
          id: this.$route.params.pageSlug,
        }
      }, 'POST').then(res => {
        _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
        })
        this.loadingOverlay = false            
      })
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save-data-catatan-persalinan'
      Gen.apiRest(
        "/do/" + 'UGDTindakLanjut', {
          data: data
        },
        "POST"
      )
    }, 1000),

    
    doSubmit(){
      
      if(this.rowCatatanPersalinan.aucp_is_bayi_kembar == 'Y' && !(this.dataCatatanPersalinan||[]).length){
        return this.$swal({
          title: "Bayi Lahir Kembar Diharuskan mengisi Catatan BBL Lebih dari satu",
          icon: 'error',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }

      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
                setTimeout(() => {
                    let inv = []
                    let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                    for (let i = 0; i < (el || []).length; i++) {
                        if (el[i].style.display !== 'none') {
                        inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})

                },500)
            }
          })
        }
                
        if (success) {
            this.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menyimpan data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {
                if (result.value) {
                    let data = this.rowCatatanPersalinan
                    data.type = 'submit-data-catatan-persalinan'
                    this.loadingOverlay = true
                    Gen.apiRest(
                    "/do/" + 'UGDTindakLanjut', {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
                        this.$swal({
                            title: resp.message,
                            icon: resp.status,
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}})
                        })
                    }).catch(err => {
                        this.loadingOverlay = false
                        if (err) {
                            err.statusType = err.status
                            err.status = "error"
                            err.title = err.response?.data?.title
                            err.message = err.response?.data?.message
                            err.messageError = err.message
                        }
                        this.doSetAlertForm(err)
                    })
                }
            })
        }
      })
    },

    
    addReservasiKembar(row,k){
      this.$refs['VFormCatatanBBLKembar'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin untuk mengirim data pasien? Pastikan data pendaftaran pasien sudah berdasarkan pengetahuan Dokter.',
            text: row.aucb_bayi_pindah == 'RANAP' ? 'Data BBL akan dikirim ke Admisi Rawat Inap. Jenis ranap tidak bisa diubah setelah data dikirim.' : 'Data BBL akan dikirim ke Admisi IGD.',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  this.$swal.showLoading()
                }
              })

              let data = row
              data.type = "add-reservasi-kajian-bbl-kembar"
              data.id_registrasi = this.$route.params.pageSlug
              data.p_kondisi_bayi = this.rowCatatanPersalinan.aucp_p_kondisi_bayi

              Gen.apiRest(
                "/do/" + this.modulePage, {
                  data: data      
                },
                "POST"
              ).then(resp => {
                this.$swal.close()
                this.$swal({
                  title: "Berhasil mendaftarkan pasien",
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                }).then(result => {
                  if (result.value) {
                    this.apiGetCatatanPersalinan()
                  }
                }).catch(err=>{ 
                  this.$swal.close()
                  this.loadingOverlay = false 
                  if(err){
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response.data.title
                      err.message = err.response.data.message
                      err.messageError = err.message
                  }
                  this.doSetAlertForm(err)
                })    
              })
            }
          })
        }
      })
      
    },
    addReservasi(){
      this.$refs['VFormCatatanBBL'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin untuk mengirim data pasien? Pastikan data pendaftaran pasien sudah berdasarkan pengetahuan Dokter.',
            text:  this.rowCatatanPersalinan.aucp_bayi_pindah == 'RANAP' ? 'Data BBL akan dikirim ke Admisi Rawat Inap. Jenis ranap tidak bisa diubah setelah data dikirim.' : 'Data BBL akan dikirim ke Admisi IGD.',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              this.$swal.fire({
                title: 'Loading...',
                showConfirmButton: false,
                allowOutsideClick: false,
                didOpen: () => {
                  this.$swal.showLoading()
                }
              })

              Gen.apiRest(
                "/do/" + this.modulePage, {
                  data: {
                    type: "add-reservasi-kajian-bbl",
                    id_registrasi: this.$route.params.pageSlug,
                    p_kondisi_bayi: this.rowCatatanPersalinan.aucp_p_kondisi_bayi,
                    bayi_hidup : this.rowCatatanPersalinan.aucp_lahir,
                    dokter: this.rowCatatanPersalinan.aucp_dokter,
                    jenis_ranap: this.rowCatatanPersalinan.aucp_jenis_ranap,
                    gender: this.rowCatatanPersalinan.aucp_gender,
                    berat_badan: this.rowCatatanPersalinan.aucp_berat_badan,
                    panjang_badan: this.rowCatatanPersalinan.aucp_panjang_badan,
                    lingkar_kepala: this.rowCatatanPersalinan.aucp_lingkar_kepala,
                    lingkar_dada: this.rowCatatanPersalinan.aucp_lingkar_dada,
                    nama_bayi: this.rowCatatanPersalinan.aucp_nama_bayi,
                    bayi_pindah : this.rowCatatanPersalinan.aucp_bayi_pindah
                  }
                },
                "POST"
              ).then(resp => {
                this.$swal.close()
                this.$swal({
                  title: "Berhasil mendaftarkan pasien",
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                }).then(result => {
                  if (result.value) {
                    this.apiGetCatatanPersalinan()
                  }
                })
              })
            }
          })
        }
      })
      
      
    },
    
    changeBayiLahir(){
      this.rowCatatanPersalinan.aucp_nama_bayi = this.isParent.row.ap_fullname
    },

    updateDataCatatanBBL(v,k){
      this.$swal({
        title: "Berhasil mengupdate data pasien",
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
    },
    updateData(){
      this.$swal({
        title: "Berhasil mengupdate data pasien",
        icon: 'success',
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
      })
    }
  },
  computed: {
    isParent(){
      return this.$parent
    },
    isDetail(){
      return this.$route.query.isDetailForm
    },
    now(){
      return moment().format('DD MMM YYYY')
    },
    nowData(){
      return moment().format('YYYY-MM-DD')
    },
    Formatter() {
      return Formatter
    },
    MonitoringOptions() {
      return MonitoringOptions
    },
    totalLamaPersalinan(){
      const lamaKala1 = ( (this.rowCatatanPersalinan.aucp_lama_kala_1_jam||0) * 60) + (+this.rowCatatanPersalinan.aucp_lama_kala_1_menit||0)
      const lamaKala2 = ( (this.rowCatatanPersalinan.aucp_lama_kala_2_jam||0) * 60) + (+this.rowCatatanPersalinan.aucp_lama_kala_2_menit||0)
      const lamaKala3 = ( (this.rowCatatanPersalinan.aucp_lama_kala_3_jam||0) * 60) + (+this.rowCatatanPersalinan.aucp_lama_kala_3_menit||0)

      const totalJamKala = Math.floor((lamaKala1 + lamaKala2 + lamaKala3) / 60) * 60

      return {
        hours: Math.floor((lamaKala1 + lamaKala2 + lamaKala3) / 60),
        minutes: ((+lamaKala1 + +lamaKala2 + +lamaKala3) - (+totalJamKala)) || 0,
      }
    }
  },
  watch: {  
    rowCatatanPersalinan: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    dataCatatanPersalinan: {
      handler(v) {
        this.autoSaveData(v)
      },
      deep: true
    },

    
    totalLamaPersalinan(v){
      this.$set(this.rowCatatanPersalinan, 'aucp_lama_persalinan_jam', v.hours)
      this.$set(this.rowCatatanPersalinan, 'aucp_lama_persalinan_menit', v.minutes)

      setTimeout(() => { 
        this.autoSave(this.rowCatatanPersalinan)
      }, 100)
    },

    

  }
}
</script>