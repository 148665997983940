const options = {
  mrNadi: [
    { value: "REGULER", label: "Reguler" },
    { value: "IRREGULER", label: "Irreguler" },
  ],

  EWS: {
    mrKesadaran: [
      { value: "SADAR", label: "Sadar" },
      { value: "GELISAH", label: "Gelisah" },
      { value: "NYERI", label: "Nyeri" },
      { value: "VERBAL", label: "Verbal" },
      { value: "TIDAK_RESPON", label: "Tidak Respon" },
    ]
  },

  PEWS: {
    mrWarnaKulit: [
      { value: "PINK", label: "Pink" },
      { value: "PUCAT_BERBINTIK", label: "Pucat Berbintik" },
    ],
    mrKesadaran: [
      { value: "SADAR_PENUH", label: "Sadar Penuh" },
      { value: "VERBAL", label: "Verbal" },
      { value: "NYERI_RESPOND", label: "Nyeri / Respond" },
    ],    
  },

  MEOWS: {
    mrKesadaran: [
      { value: "SADAR", label: "Sadar" },
      { value: "NYERI", label: "Nyeri" },
      { value: "VERBAL", label: "Verbal" },
      { value: "TIDAK_RESPON", label: "Tidak Respon" },
    ],
    mrProteinuria: ["-", "+", "++>"],
    mrDischarge: [
      { value: "NORMAL", label: "Normal" },
      { value: "TIDAK_NORMAL", label: "Tidak Normal" }
    ],
    mrNyeri: [
      { value: "NORMAL", label: "Normal" },
      { value: "TIDAK_NORMAL", label: "Tidak Normal" }
    ],
  },

  BalanceCairan: {
    tetesanType: ["tpm", "cc/jam"],
  },

  CriticalPatient: ["KR"],
  ObsgynPatient: ["OB"]

}

export default options