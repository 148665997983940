<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-12 mb-2">
        <div class="bg_card_blue p-3">
          <table class="table table-borderless table-sm text-uppercase">
            <tbody>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Reg</h4>
                    <p>{{isParent.rowReg.aur_reg_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>No. Rekam Medis</h4>
                    <p>{{isParent.rowReg.ap_code||"-"}}</p>
                  </div>
                </td>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Nama Pasien</h4>
                    <p>{{isParent.rowReg.ap_fullname||"-"}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td width="33%">
                  <div class="result_tab">
                    <h4>Tempat, Tanggal Lahir </h4>
                    <p>{{isParent.rowReg.ap_address||"-"}}, 
                    <span v-if="isParent.rowReg.ap_dob">{{isParent.rowReg.ap_dob | moment("DD MMMM YYYY")}}</span>
                    <span v-else> - </span>
                    </p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Usia</h4>
                    <p>{{isParent.rowReg.ap_usia_with_ket}}</p>
                  </div>
                </td>
                <td>
                  <div class="result_tab">
                    <h4>Jenis Kelamin</h4>
                    <p>{{isParent.rowReg.ap_gender == 1 ? 'Laki-Laki' : 'Perempuan'}}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Monitoring Fibrinolisis</h6>
          </div>
          <table class="table table-bordered table-sm patient-table">
            <thead>
              <tr>
                <th rowspan="2">Monitoring</th>
                <th colspan="6">Waktu Pemberian Fibrinolisis</th>
              </tr>
              <tr>
                <th>Pre Fibrinolisis</th>
                <th>15 Menit</th>
                <th>30 Menit</th>
                <th>45 Menit</th>
                <th>60 Menit</th>
                <th>1 Jam Post Fibrinolisis</th>
              </tr>
            </thead>
            <tbody>
              <tr  v-for="(v,k) in (rowData.aukpksm_data||[])" :key="k">
                <th>{{v.label}}</th>
                <template v-if="v.type == 'radio'">
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.pre)||"-"}}</td>
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.s_15)||"-"}}</td>        
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.s_30)||"-"}}</td>   
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.s_45)||"-"}}</td>                 
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.s_60)||"-"}}</td>                 
                  <td>{{getConfigDynamic(Config.mr.yesNoOptV2,v.data.s_1jam)||"-"}}</td>           
                </template>
                <template v-if="v.type == 'td'">
                  <td>
                    <span v-if="v.data.pre_min && v.data.pre_max">{{v.data.pre_min}}/{{v.data.pre_max}} mmHG</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_15_min && v.data.s_15_max">{{v.data.s_15_min}}/{{v.data.s_15_max}} mmHG</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_30_min && v.data.s_30_max">{{v.data.s_30_min}}/{{v.data.s_30_max}} mmHG</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_45_min && v.data.s_45_max">{{v.data.s_45_min}}/{{v.data.s_45_max}} mmHG</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_60_min && v.data.s_60_max">{{v.data.s_60_min}}/{{v.data.s_60_max}} mmHG</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_1jam_min && v.data.s_1jam_max">{{v.data.s_1jam_min}}/{{v.data.s_1jam_max}} mmHG</span>
                    <span v-else> - </span>
                  </td>
                </template>
                <template v-if="v.type == 'nadi'">
                  <td>
                    <span v-if="v.data.pre_val">
                      {{v.data.pre_val}} x/mnt, {{getConfigDynamic(Config.mr.StatusRegular,v.data.pre_label)||"-"}}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_15_val">
                      {{v.data.s_15_val}} x/mnt, {{getConfigDynamic(Config.mr.StatusRegular,v.data.s_15_label)||"-"}}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_30_val">
                      {{v.data.s_30_val}} x/mnt, {{getConfigDynamic(Config.mr.StatusRegular,v.data.s_30_label)||"-"}}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_45_val">
                      {{v.data.s_45_val}} x/mnt, {{getConfigDynamic(Config.mr.StatusRegular,v.data.s_45_label)||"-"}}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_60_val">
                      {{v.data.s_60_val}} x/mnt, {{getConfigDynamic(Config.mr.StatusRegular,v.data.s_60_label)||"-"}}
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_1jam_val">
                      {{v.data.s_1jam_val}} x/mnt, {{getConfigDynamic(Config.mr.StatusRegular,v.data.s_1jam_label)||"-"}}
                    </span>
                    <span v-else> - </span>
                  </td>
                </template>

                <template v-if="v.type == 'nafas'">
                  <td>
                    <span v-if="v.data.pre">{{v.data.pre}} x/mnt</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_15">{{v.data.s_15}} x/mnt</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_30">{{v.data.s_30}} x/mnt</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_45">{{v.data.s_45}} x/mnt</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_60">{{v.data.s_60}} x/mnt</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_1jam">{{v.data.s_1jam}} x/mnt</span>
                    <span v-else> - </span>
                  </td>
                </template>

                <template v-if="v.type == 'spo2'">
                  <td>
                    <span v-if="v.data.pre">{{v.data.pre}}%</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_15">{{v.data.s_15}}%</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_30">{{v.data.s_30}}%</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_45">{{v.data.s_45}}%</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_60">{{v.data.s_60}}%</span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <span v-if="v.data.s_1jam">{{v.data.s_1jam}}%</span>
                    <span v-else> - </span>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')


export default{
  extends: GlobalVue,
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
  },
  data() {
    return {
      rowData: {},
      rowReg: {}
    }
  },
  methods: {
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = 'UGDKajianStriptokinase'+'/'+this.$route.params.pageSlug+'/'+this.isParent.rowEdit.aukpks_id+'?monitoring=1'
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })
    },
  },
  mounted() {
    this.apiGetForm()
  }
}

</script>