<template>
    <div>
    <validation-observer ref="VFormTTD">
        <b-form @submit.prevent="doSubmit">
        <div class="row" v-if="!rowSuratPelayananKerohanian.auspr_template">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">Penandatanganan Dokumen</label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.ttdCreatedBy"
                            v-model="rowSuratPelayananKerohanian['auspr_is_ttd']"
                        />
                        <VValidate 
                            name="Penandatanganan Dokumen" 
                            v-model="rowSuratPelayananKerohanian.auspr_is_ttd" 
                            :rules="{required : 1}"
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div :class="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital' ? 'row' : 'd-none'">
            <div class="col-md-4">
                <div class="mb-2">
                  <div class="form-group">
                    <label for="">Nama Penanggung Jawab<span class="text-danger">*</span></label>
                    <b-form-input class="form-control" v-model="rowSuratPelayananKerohanian.auspr_nama_pj">
                    </b-form-input>
                    <VValidate 
                        v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'"
                        :name="'Nama Penanggung Jawab'" 
                        v-model="rowSuratPelayananKerohanian.auspr_nama_pj" 
                        :rules="{required : 1, max:128}"
                    />
                  </div>
                  <div class="form-group">
                  <label for="">Tanda Tangan Dokter Pemeriksa<span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdPJ">
                      <VueSignaturePad
                          class="sign-container"
                          width="100%"
                          height="160px"
                          ref="ttdPJ"
                      />
                      
                      <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPJ','auspr_ttd_pj')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                          <span>Clear</span>
                      </button>
                      
                      <button type="button" @click="output('ttdPJ','auspr_ttd_pj')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                      </button>
                      </div>
                  </div>
                  
                  <VValidate 
                      v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'"
                      :name="'TTD Dokter'" 
                      v-model="rowSuratPelayananKerohanian.auspr_ttd_pj" 
                      :rules="{required : 1}"
                  />
                  </div>
                </div>

                <div class="mb-2">
                  <div class="form-group">
                    <label for="">Nama Petugas<span class="text-danger">*</span></label>
                    <b-form-input class="form-control" v-model="rowSuratPelayananKerohanian.auspr_nama_petugas">
                    </b-form-input>
                    <VValidate 
                        v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'"
                        :name="'Nama Petugas'" 
                        v-model="rowSuratPelayananKerohanian.auspr_nama_petugas" 
                        :rules="{required : 1, max:128}"
                    />
                  </div>

                  <div class="form-group">
                    <label>Tanggal Lahir Penanggung Jawab <span class="text-danger">*</span></label>
                    <datepicker v-model="rowSuratPelayananKerohanian.auspr_tanggal_lahir_pj" input-class="form-control transparent" placeholder="Pilih Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" />
                    <VValidate v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'" name="Tanggal Lahir Penanggung Jawab" v-model="rowSuratPelayananKerohanian.auspr_tanggal_lahir_pj" rules="required"></VValidate>
                  </div>
                  <div class="form-group">
                    <label>Jenis Kelamin Penanggung Jawab <span class="text-danger">*</span></label>
                    <b-form-radio-group name="jenis_kelamin" :options="Config.mr.genderText" v-model="rowSuratPelayananKerohanian.auspr_jenis_kelamin_pj"></b-form-radio-group>
                    <VValidate v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'" name="Jenis Kelamin Penanggung Jawab" v-model="rowSuratPelayananKerohanian.auspr_jenis_kelamin_pj" rules="required"></VValidate>
                  </div>
                  <div class="form-group">
                    <label>Alamat Penanggung Jawab <span class="text-danger">*</span></label>
                    <b-form-textarea v-model="rowSuratPelayananKerohanian.auspr_alamat_pj"></b-form-textarea>
                    <VValidate v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'" name="Alamat Penanggung Jawab" v-model="rowSuratPelayananKerohanian.auspr_alamat_pj" rules="required"></VValidate>

                  </div>
                  <div class="form-group">
                    <label>Agama Penanggung Jawab <span class="text-danger">*</span></label>
                    <v-select :options="mAgama" v-model="rowSuratPelayananKerohanian.auspr_agama_pj" label="text" value="val" :clearable="true" :reduce="v=>v.val"></v-select>
                    <VValidate v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'" name="Agama Penanggung Jawab" v-model="rowSuratPelayananKerohanian.auspr_agama_pj" rules="required"></VValidate>
                    
                    <div class="mt-3" v-if="rowSuratPelayananKerohanian.auspr_agama_pj == 'Lain-lain'">
                      <label for="">Agama Penanggung Jawab Lainnya<span class="text-danger">*</span></label>
                      <b-form-input class="form-control" v-model="rowSuratPelayananKerohanian.auspr_agama_pj_text">
                      </b-form-input>
                      <VValidate 
                          v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'"
                          :name="'Agama Penanggung Jawab Lainnya'" 
                          v-model="rowSuratPelayananKerohanian.auspr_agama_pj_text" 
                          :rules="{required : 1, max:128}"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Hubungan Dengan Pasien <span class="text-danger">*</span></label>
                    <v-select :options="mRelasi" v-model="rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                    <VValidate v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'" name="Hubungan Dengan Pasien" v-model="rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien" rules="required"></VValidate>
                    <div class="mt-3" v-if="rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien == 'Lain-lain'">
                      <label for="">Hubungan Dengan Pasien Lainnya<span class="text-danger">*</span></label>
                      <b-form-input class="form-control" v-model="rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien_text">
                      </b-form-input>
                      <VValidate 
                          v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'"
                          :name="'Hubungan Dengan Pasien Lainnya'" 
                          v-model="rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien_text" 
                          :rules="{required : 1, max:128}"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                  <label for="">Tanda Tangan Petugas<span class="text-danger">*</span></label>
                  <div class="signing-element" data-target-id="ttdPetugas">
                      <VueSignaturePad
                          class="sign-container"
                          width="100%"
                          height="160px"
                          ref="ttdPetugas"
                      />
                      <div class="mt-2">
                      <button type="button" @click="undoTTD('ttdPetugas','auspr_ttd_petugas')" class="btn btn-sm btn-danger btn-labeled btn-labeled-left se-cleaner">
                          <b><i class="icon-cross3"></i></b>
                          <span>Clear</span>
                      </button>
                      
                      <button type="button" @click="output('ttdPetugas','auspr_ttd_petugas')" class="btn btn-sm btn-success btn-labeled btn-labeled-left se-cleaner ml-2">
                          <b><i class="icon-checkmark2"></i></b>
                      <span>Simpan</span>
                      </button>
                      </div>
                  </div>
                  
                  <VValidate 
                      v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'"
                      :name="'TTD Petugas'" 
                      v-model="rowSuratPelayananKerohanian.auspr_ttd_petugas" 
                      :rules="{required : 1}"
                  />
                  </div>
                </div>

            </div>
            <div class="col-md-8">
              <div class="document-framer">
                <div class="df-content">
                  <div style="position: relative; max-width: 700px;">
                    <div
                      style="height: 100%; position: relative; font-family: serif; color: rgb(51, 51, 51); line-height: 1.5; font-size: 12px;">
                      <div>
                        <table border="1"
                          style="border-collapse: collapse; width: 100%; font-size: 12px; border-color: rgb(0, 0, 0); line-height: 1.5;">
                          <tr>
                            <td width="20%" rowspan="4" style="padding: 4px 8px;">
                              <div style="text-align: center;"><img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" alt=""
                                  style="max-width: 100%;">
                                <h2 style="margin: 8px 0px 0px; font-size: 16px; text-transform: uppercase; line-height: 20px;"> Rumah
                                  Sakit Harapan</h2>
                              </div>
                            </td>
                            <td width="40%" style="padding: 4px 8px;"><span>No. RM: <strong>{{rowPasien.ap_code}}</strong></span></td>
                            <td width="40%" style="padding: 4px 8px;"><span>DPJP: <strong>{{rowReg.bu_full_name}}</strong></span>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Nama Pasien: <strong>{{rowPasien.ap_fullname}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>NIK: <strong>{{rowPasien.ap_nik||"-"}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Lahir:
                                <strong>{{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</strong></span></td>
                            <td style="padding: 4px 8px;"><span>Jenis Kelamin: <strong>
                              {{rowPasien.ap_gender == 1 ? 'Laki-Laki': rowPasien.ap_gender == 2 ? 'Perempuan' : 'Lainnya'}}</strong></span></td>
                          </tr>
                          <tr>
                            <td style="padding: 4px 8px;"><span>Tgl. Masuk:
                                <strong>{{rowReg.aur_reg_date | moment("DD MMMM YYYY, HH:mm")}} WIB</strong></span></td>
                            <td style="padding: 4px 8px;"><span>UGD</span>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" style="padding: 4px 8px;">
                              <div style="text-transform: uppercase; text-align: center;">
                                <h3 style="margin: 4px 0px 0px; font-size: 14px; text-transform: uppercase; line-height: 18px;">
                                  Formulir Permintaan Pelayanan Kerohanian </h3>
                              </div>
                            </td>
                          </tr>
                        </table>
                        <table style="border-collapse: collapse; width: 100%; border: 1px solid; font-size: 12px; line-height: 1.5;">
                          <tr>
                            <td colspan="2" style="padding: 4px 8px;">
                              <p style="margin: 0px 0px 4px;">Yang bertanda tangan dibawah ini:</p>
                              <table
                                style="width: 100%; border: 0px; border-collapse: collapse; font-size: 12px; margin-bottom: 8px; line-height: 1.5;">
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Nama</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">{{rowSuratPelayananKerohanian.auspr_nama_pj||"-"}}</td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Tanggal Lahir</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">
                                    <span v-if="rowSuratPelayananKerohanian.auspr_tanggal_lahir_pj">
                                    {{rowSuratPelayananKerohanian.auspr_tanggal_lahir_pj | moment("DD MMMM YYYY")}}
                                    </span>
                                    <span v-else> - </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;"> Umur/Jenis Kelamin</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;"> {{calculateAge(rowSuratPelayananKerohanian.auspr_tanggal_lahir_pj)}}tahun, {{rowSuratPelayananKerohanian.auspr_jenis_kelamin_pj}}</td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Alamat </td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">{{rowSuratPelayananKerohanian.auspr_alamat_pj||"-"}}</td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Agama </td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">
                                    {{rowSuratPelayananKerohanian.auspr_agama_pj}}
                                    <span class="ml-1" v-if="rowSuratPelayananKerohanian.auspr_agama_pj == 'Lain-lain'"> : {{rowSuratPelayananKerohanian.auspr_agama_pj_text}}</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Hubungan dengan Pasien</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">
                                    {{rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien}}
                                    <span class="ml-1" v-if="rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien == 'Lain-lain'"> : {{rowSuratPelayananKerohanian.auspr_hubungan_dengan_pasien_text}}</span></td>
                                </tr>
                                <tr>
                                  <td colspan="3" style="padding: 4px 8px 4px 0px; vertical-align: top;"> Dengan ini menyatakan
                                    permintaan pelayanan kerohanian agama kepada Rumah Sakit Harapan terhadap pasien:</td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Nama Pasien</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">{{rowPasien.ap_fullname}}</td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Tanggal Lahir</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">
                                    {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Alamat </td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="padding: 4px 0px 4px 8px; vertical-align: top;">{{ rowPasien.ap_address }},
                                    {{ rowPasien.ap_rt ? 'RT ' + rowPasien.ap_rt : '' }}
                                    {{ rowPasien.ap_rw ? '/' + rowPasien.ap_rw : '' }}
                                    {{ rowPasien.ap_kelurahan ? '. Kel. ' + rowPasien.ap_kelurahan : '' }}
                                    {{ rowPasien.ap_kecamatan ? '. Kec. ' + rowPasien.ap_kecamatan : '' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Dalam Bentuk</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="vertical-align: top;">
                                    {{rowSuratPelayananKerohanian.auspr_bentuk_layanan}}
                                  </td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Diberikan oleh</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="vertical-align: top;">
                                    {{rowSuratPelayananKerohanian.auspr_diberikan_oleh}}
                                  </td>
                                </tr>
                                <tr>
                                  <td width="17.5%" style="padding: 4px 8px 4px 0px; vertical-align: top;">Nama Pembimbing Rohani</td>
                                  <td width="20" style="padding: 4px 8px; vertical-align: top;">:</td>
                                  <td width="88%" style="vertical-align: top;">
                                    {{rowSuratPelayananKerohanian.auspr_nama_pembimbing}}
                                  </td>
                                </tr>
                              </table>
                              <p style="margin: 0px 0px 8px;">Demikian surat permohonan permintaan pelayanan kerohanian ini saya buat,
                                atas perhatiannya saya ucapkan terima kasih.</p>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%" style="padding: 4px 8px;">
                              <div style="text-align: center;">
                                <template v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital' && rowSuratPelayananKerohanian.auspr_ttd_petugas">
                                  <span>Petugas </span> <br />
                                  <img :src="rowSuratPelayananKerohanian.auspr_ttd_petugas" alt="signature" width="200">
                                </template>
                                <template v-else>
                                  <div style="margin-bottom: 48px;">Petugas </div>
                                </template>
                                <div v-if="rowSuratPelayananKerohanian.auspr_nama_petugas">({{ rowSuratPelayananKerohanian.auspr_nama_petugas }})</div>
                                <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                              </div>
                            </td>
                            <td width="50%" style="padding: 4px 8px;">
                              <div style="text-align: center;">
                                <div style="margin-bottom: 0px;">Magelang, {{now}}</div>
                                <div style="text-align: center;">
                                  <template v-if="rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital' && rowSuratPelayananKerohanian.auspr_ttd_pj">
                                    <span>Penanggung Jawab </span> <br />
                                    <img :src="rowSuratPelayananKerohanian.auspr_ttd_pj" alt="signature" width="200">
                                  </template>
                                  <template v-else>
                                    <div style="margin-bottom: 48px;">Dokter </div>
                                  </template>
                                  <div v-if="rowSuratPelayananKerohanian.auspr_nama_pj">({{ rowSuratPelayananKerohanian.auspr_nama_pj }})</div>
                                  <div v-else>(<span style="padding: 0 64px;"></span>)</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        
        <div :class="rowSuratPelayananKerohanian.auspr_is_ttd == 'Manual' ? 'row' : 'd-none'">
            <div class="col-md-12">
                <div class="alert alert-info opsiTtd" id="opsiTtdManual">
                    <div class="d-flex">
                    <i class="icon-info22"></i><span class="ml-2">Simpan pengisian formulir ini, lalu unduh dan cetak dokumen pada halaman Dokumen Terkait</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.ttdModal = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
        
        </b-form>
    </validation-observer>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
export default{ 
    components: {
      Datepicker
    },
    extends: GlobalVue, 
    data(){
        return {
          rowSuratPelayananKerohanian: {},
          rowPasien: {},
          rowReg: {},
          mAgama: [],
          mRelasi: [],
        }
    },
    computed: {
        now(){
            return moment().format('DD MMM YYYY')
        },
        isParent(){
            return this.$parent.$parent.$parent
        },
    },
    methods: {
        calculateAge(birthDate) {
            if(!birthDate) return '-'
            const today = new Date()
            const birthDateObj = new Date(birthDate)
          
            let age = today.getFullYear() - birthDateObj.getFullYear()
            const monthDiff = today.getMonth() - birthDateObj.getMonth()
            
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
                age--
            }
            
            return age
        },
        toValidate(val){
            return {...val}
        },
        assetLocal(img){
          let def = process.env.VUE_APP_MODE != 'local' ? process.env.VUE_APP_BASE_URL+"/" : "/"
          img = def+img
          return img
        },
        undoTTD(refs,field){
          this.$refs[refs].clearSignature()
          this.rowSuratPelayananKerohanian[field] = null
        },
        output(refs,field) {
          let { isEmpty, data } = this.$refs[refs].saveSignature()
          if(!isEmpty){
            this.rowSuratPelayananKerohanian[field] = data
          }
        },
        doSubmit(){
            this.$refs['VFormTTD'].validate().then(success => {
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }
                            },250)
                        }
                    })
                }

                if(this.rowSuratPelayananKerohanian.auspr_is_ttd == 'Digital'){
                    if(!this.rowSuratPelayananKerohanian.auspr_ttd_pj){
                        return this.$swal({
                            icon: 'error',
                            title: 'Dokumen Belum Ditanda tangani'
                        })   
                    }
                }

                this.rowSuratPelayananKerohanian.auspr_template = $(".document-framer").html()


                if (success) {
                    this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.rowSuratPelayananKerohanian
                            data.type = 'submit-data-ttd-surat-pelayanan-kerohanian'
                            data.status = 'U'

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + 'UGDTindakLanjut', {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.isParent.ttdModal = false
                                    this.isParent.$parent.apiGet()

                                    
                                    this.$router.push({name : 'UGDTindakLanjut', params: {pageSlug : this.$route.params.pageSlug},query: {isDetail: true, regId: this.$route.query.regId}}).catch(()=>{})
                                })
                            }).catch(err => {
                                this.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })
        },
        apiGetTTDSuratPelayananKerohanian(){
            this.loadingOverlay = true
            Gen.apiRest('/do/' + 'UGDTindakLanjut', {
                data: {
                    type: 'get-data-ttd-surat-pelayanan-kerohanian',
                    id: this.$route.params.pageSlug,
                }
            }, 'POST').then(res => {
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.loadingOverlay = false            
            })
        }
    },
    
    mounted() {
        this.apiGetTTDSuratPelayananKerohanian()
        setTimeout(()=>{
            if(this.$refs['ttdPJ']){
              this.$refs['ttdPJ'].fromDataURL(this.rowSuratPelayananKerohanian.auspr_ttd_pj)
            }
            if(this.$refs['ttdPetugas']){
              this.$refs['ttdPetugas'].fromDataURL(this.rowSuratPelayananKerohanian.auspr_ttd_petugas)
            }
        },1500)
    }
}      
</script>
