<template>
  <div class="card">
    <div class="card-header bg-white border-bottom-str">
      <h6 class="card-title font-weight-semibold">Tambah Monitoring Kajian Restrain</h6>
    </div>
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                <datepicker :disabledDates="disabledDates" input-class="form-control transparent h-38" placeholder="Tanggal"
                    class="my-datepicker" calendar-class="my-datepicker_calendar"
                    v-model="rowData.aukpkr_date">
                </datepicker>

                <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="rowData.aukpkr_jam">
                </vue-timepicker>
                <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i
                        class="icon-calendar"></i></span>
                </div>
                </div>
                <VValidate 
                    :name="'Tanggal'" 
                    v-model="rowData.aukpkr_date" 
                    :rules="{required : 1}"
                    class="d-block"
                />
                <VValidate :name="'Jam'" v-model="rowData.aukpkr_jam"
                    :rules="{required: 1}" />
              </div>
            </div>
          </div>
        </div>
        
        <div class="card-body">
          <div class="row gy-2">
            <div class="col-10">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="diagnosa">Diagnosa <span class="text-danger">*</span></label>
                    <b-form-textarea v-model="rowData.aukpkrm_diagnosa" name="diagnosa" id="diagnosa" rows="2" class="form-control"
                      placeholder="Diagnosa"></b-form-textarea>                          
                    <VValidate :name="'Diagnosa'" v-model="rowData.aukpkrm_diagnosa"
                        :rules="toValidate(mrValidation.aukpkrm_diagnosa)" />
                  </div>
                </div>
                
                <!--
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="kenyamananNyeri">Jenis Restrain <span class="text-danger">*</span></label>
                    <v-select placeholder="Pilih Label" v-model="rowData.aukpkrm_jenis_restrain" :options="Config.mr.jenisRestrain" label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                    <VValidate :name="'Jenis Restrain'" v-model="rowData.aukpkrm_jenis_restrain"
                        :rules="toValidate(mrValidation.aukpkrm_jenis_restrain)" />
                  </div>
                </div>
                -->
                
                <div class="col-4">
                  <div class="mt-0">
                    <label for="kkeStatusMental">Lokasi Pemasangan</label>
                    <div>
                        <b-form-group>
                            <b-form-checkbox-group class="checkbox-block"
                                v-model="rowData.aukpkrm_lokasi_pemantauan"
                                :options="Config.mr.lokasiPemasanganRestrain"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card mb-0 h-100">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Restrain Non Farmakologi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <!--
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Pukul <span class="text-danger">*</span></label>
                        <div class="input-group bootstrap-timepicker timepicker">
                          <span class="input-group-prepend input-group-addon">
                            <span class="input-group-text">Jam</span>
                          </span>
                          <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="rowData.aukpkrm_pukul">
                          </vue-timepicker>
                        </div>
                            
                        <VValidate :name="'Pukul'" v-model="rowData.aukpkrm_pukul"
                            :rules="toValidate(mrValidation.aukpkrm_pukul)" />
                      </div>
                    </div>
                    -->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Jenis Ikatan</label>
                        <b-form-input v-model="rowData.aukpkrm_jenis_ikatan">
                        </b-form-input>
                        <!--
                        <VValidate :name="'Jenis Ikatan'" v-model="rowData.aukpkrm_jenis_ikatan"
                            :rules="toValidate(mrValidation.aukpkrm_jenis_ikatan)" />
                        -->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <table class="table-bordered table table-sm">
                        <thead>
                          <tr>
                            <th colspan="2">Kondisi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-popup="tooltip" title="" data-original-title="Klik untuk mengisi">
                              <label>Edema</label>
                              <div>
                                <b-form-radio-group name="edema" :options="isParent.Config.mr.yesNoOptV2"
                                v-model="rowData.aukpkrm_edema" />
                              </div>
                            </td>

                            <td data-popup="tooltip" title="" data-original-title="Klik untuk mengisi">
                              <label>Sensorik</label>
                              <div>
                                <b-form-radio-group name="sensorik" :options="isParent.Config.mr.baikNoOpt"
                                v-model="rowData.aukpkrm_sensorik" />
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td data-popup="tooltip" title="" data-original-title="Klik untuk mengisi">
                              <label>Iritasi</label>
                              <div>
                                <b-form-radio-group name="iritasi" :options="isParent.Config.mr.yesNoOptV2"
                                v-model="rowData.aukpkrm_iritasi" />
                              </div>
                            </td>

                            <td data-popup="tooltip" title="" data-original-title="Klik untuk mengisi">
                              <label>Sirkulasi </label>
                              <div>
                                <b-form-radio-group name="sirkulasi" :options="isParent.Config.mr.baikNoOpt"
                                v-model="rowData.aukpkrm_sirkulasi" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <label>Hasil </label>
                          <div>
                            <b-form-radio-group :options="isParent.Config.mr.configManfaat"
                            v-model="rowData.aukpkrm_is_manfaat" />
                          </div>
                        </div>
                        <div class="col-md-12 mt-3">
                          <label>Petugas </label>
                          <div class="form-row">
                            <div class="col-md-6">
                              <label>PPA </label>
                              <b-form-input v-model="rowData.aukpkrm_petugas_ppa" type="text" class="form-control" />
                            </div>
                            <div class="col-md-6">
                              <label>Nama </label>
                              <b-form-input v-model="rowData.aukpkrm_petugas_nama" type="text" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="card mb-0 h-100">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Restrain Farmakologi</h6>
                </div>
                <div class="card-body">
                    <!--
                  <div class="row mt-3">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Pukul <span class="text-danger">*</span></label>
                        <div class="input-group bootstrap-timepicker timepicker">
                          <span class="input-group-prepend input-group-addon">
                            <span class="input-group-text">Jam</span>
                          </span>
                          <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="rowData.aukpkrm_pukul">
                          </vue-timepicker>
                        </div>
                            
                        <VValidate :name="'Pukul'" v-model="rowData.aukpkrm_pukul"
                            :rules="toValidate(mrValidation.aukpkrm_pukul)" />
                      </div>
                    </div>
                  </div>
                    -->

                  <div class="row gy-2 mt-3">
                    <div class="col-md-12">
                      <div class="card mb-0 h-100">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
                        </div>
                        <div class="card-body p-3">
                          <div class="row">
                            <div class="col-md-4">
                              <div class="form-group">
                              <label>Tekanan Darah </label>
                              <div class="input-group">
                                  <b-form-input :formatter="number" v-model="rowData.aukpkrm_ttv_tekanan_darah_min" type="text" name="name" class="form-control" placeholder="Systole"/>
                                  
                                  <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>

                                  <b-form-input :formatter="number" placeholder="Diastole" v-model="rowData.aukpkrm_ttv_tekanan_darah_max" type="text" class="form-control" />
                                  <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                              </div>      
                              <!--
                              <VValidate 
                                  name="Tekanan Darah Min" 
                                  v-model="rowData.aukpkrm_ttv_tekanan_darah_min" 
                                  :rules="toValidate(mrValidation.aukpkrm_ttv_tekanan_darah_min)"
                              />
                              <VValidate 
                                  name="Tekanan Darah Max" 
                                  v-model="rowData.aukpkrm_ttv_tekanan_darah_max" 
                                  :rules="toValidate(mrValidation.aukpkrm_ttv_tekanan_darah_max)"
                              />
                              -->
                              </div>
                          </div>

                          <div class="col-md-4">
                              <div class="form-group">
                                  <label>Nadi </label>
                                  <div class="form-row">
                                      <div class="col-md-12">
                                      <div class="input-group">
                                          <b-form-input :formatter="number" v-model="rowData.aukpkrm_ttv_nadi" type="text" class="form-control" />
                                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                          <div class="input-group-append">
                                              <div style="width: 140px;">
                                              <v-select placeholder="Pilih Label" v-model="rowData.aukpkrm_ttv_label" :options="Config.mr.StatusRegular" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                              </div>
                                          </div>
                                      </div>
                                      </div>
                                  </div>

                                  <!--
                                  <VValidate 
                                      name="Nadi" 
                                      v-model="rowData.aukpkrm_ttv_nadi" 
                                      :rules="toValidate(mrValidation.aukpkrm_ttv_nadi)"
                                  />
                                  <VValidate 
                                      name="Label" 
                                      v-model="rowData.aukpkrm_ttv_label" 
                                      :rules="toValidate(mrValidation.aukpkrm_ttv_label)"
                                  />
                                  -->
                              </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                            <label>Suhu</label>
                            <div class="form-row">

                                <div class="col-md-12">
                                <div class="input-group">
                                    <b-form-input :formatter="number" v-model="rowData.aukpkrm_ttv_suhu"  type="text" class="form-control" />
                                    <div class="input-group-append"><span class="input-group-text">C</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!--                                    
                            <VValidate 
                                name="Suhu" 
                                v-model="rowData.aukpkrm_ttv_suhu" 
                                :rules="toValidate(mrValidation.aukpkrm_ttv_suhu)"
                            />
                            -->
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                            <label>Kesadaran</label>
                            <button type="button" @click="isOpenTS=true" class="btn btn-light mr-3">Isi Tingkat Kesadaran</button>
                            <template v-if="rowData.aukpkrm_response_gcs">
                                <div class="">
                                <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </div>
                            </template>
                            <!--                                                                
                            <VValidate 
                                name="Kesadaran" 
                                v-model="rowData.aukpkrm_response_gcs" 
                                :rules="{required : 1}"
                            />
                            -->
                            </div>

                            
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Perawat yang mengisi</label>
                              <b-form-input v-model="rowData.aukpkrm_perawat_nama" type="text" class="form-control" disabled/>
                            </div>
                          </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="card mb-0">
                        <div class="card-header bg_head_panel">
                          <h6 class="card-title font-weight-semibold">Obat yang diberikan</h6>
                        </div>
                        <div class="card-body p-3">
                            <ResepObat />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Perawat yang memberikan obat</label>
                        <b-form-input v-model="rowData.aukpkrm_perawat_pemberi_obat" type="text" class="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        
        <div class="card mb-0">
            <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Tindakan Lanjutan</h6>
            </div>
            <div class="card-body">
                <div class="row mt-2">
                    <div class="col-md-5">
                        <label for="">Jadwal Monitoring <span class="text-danger">*</span></label>
                        <b-form-radio-group
                            :options="Config.mr.lanjutanMonitoring"
                            v-model="rowData.aukpkr_is_next_monitoring"
                        />
                        <VValidate :name="'Jadwal Monitoring'" v-model="rowData.aukpkr_is_next_monitoring" :rules="{required: 1}" />
                    </div>

                    <div class="col-md-5" v-if="rowData.aukpkr_is_next_monitoring == 'Y'">
                        <label>Interval Waktu<small class="txt_mandatory">*</small></label>
                        <div class="form-row">
                        <div class="col-md-5">
                            <v-select placeholder="-- Pilih Salah Satu -- " v-model="rowData.aukpkrm_label" :options="isParent.Config.mr.waktuKajianInterval" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            
                            <VValidate 
                                :name="'Interval Waktu'" 
                                v-model="rowData.aukpkrm_label" 
                                :rules="toValidate(mrValidation.aukpkrm_label)"
                                class="d-block"
                            />
                        </div>
                        <div class="col-md-7" v-if="rowData.aukpkrm_label == 999999">
                            <div class="form-group">
                            <div class="input-group">
                            <b-form-input :formatter="numberOnly" v-model="rowData.aukpkrm_waktu" type="text" class="form-control" />
                            <div class="input-group-append">
                                <div style="width: 120px;">                                
                                <v-select placeholder="-- Pilih -- " v-model="rowData.aukpkrm_waktu_label" :options="isParent.Config.mr.ketWaktuInterval" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                </div>
                            </div>
                            
                            <VValidate 
                                :name="'Interval Waktu Lainnya'" 
                                v-model="rowData.aukpkrm_waktu" 
                                :rules="toValidate(mrValidation.aukpkrm_waktu)"
                                class="d-block"
                            />
                            
                            <VValidate 
                                :name="'Keterangan Waktu'" 
                                v-model="rowData.aukpkrm_waktu_label" 
                                :rules="toValidate(mrValidation.aukpkrm_waktu_label)"
                                class="d-block"
                            />
                        </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-footer">
          <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-2">Back</button>
              <button type="button" class="btn btn-info mr-2" @click="doSave()">Simpan Sementara <i class="icon-paperplane mr-2"></i></button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane mr-2"></i></button>
          </div>
        </div>

        
        <b-modal v-model="isOpenTS" :title="'Isi Tingkat Kesadaran'" size="xl" ok-only ok-title="Simpan">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                <th>Pengkajian</th>
                                <th>Nilai</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
                                
                                <!-- ele pertama hardcode -->
                                <td>
                                <b-form-radio v-model="rowData.aukpkrm_response_mata" name="aukpkrm_response_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
                                </td>

                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio v-model="rowData.aukpkrm_response_mata" name="aukpkrm_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
                                
                                <td>
                                <b-form-radio v-model="rowData.aukpkrm_response_verbal" name="aukpkrm_response_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
                                </b-form-radio>
                                </td>
                                
                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio v-model="rowData.aukpkrm_response_verbal" name="aukpkrm_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody>
                                <tr>
                                <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
                                
                                <td>
                                <b-form-radio v-model="rowData.aukpkrm_response_motorik" name="aukpkrm_response_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
                                </td>
                                
                                </tr>
                                <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                                    <tr v-if="k != 0" :key="k">
                                    <td>
                                        <b-form-radio v-model="rowData.aukpkrm_response_motorik" name="aukpkrm_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                                    </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot>
                                <tr>
                                <td class="table-info mt-1" colspan="9">
                                    <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                    <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                    <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                    <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                    <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                </td>
                                </tr>
                            </tfoot>
                            </table>

                            <!--
                            <VValidate 
                                name="Response Mata" 
                                v-model="rowData.aukpkrm_response_mata" 
                                :rules="toValidate(isParent.mrValidation.aukpkrm_response_mata)"
                                class="d-block"
                            />

                            
                            <VValidate 
                                name="Response Verbal" 
                                v-model="rowData.aukpkrm_response_verbal" 
                                :rules="toValidate(isParent.mrValidation.aukpkrm_response_verbal)"
                                class="d-block"
                            />
                            
                            <VValidate 
                                name="Response Motorik" 
                                v-model="rowData.aukpkrm_response_motorik" 
                                :rules="toValidate(isParent.mrValidation.aukpkrm_response_motorik)"
                                class="d-block"
                            />
                            -->


                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

      </b-form>
    </validation-observer>
  </div>

</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import ResepObat from './ResepObat.vue'

export default{
  extends: GlobalVue,
  components: {Datepicker,VueTimepicker,ResepObat}, 
  computed: {
    isParent(){
      return this.$parent
    },
    tingkatKesadaranTotal(){
      return (this.rowData.aukpkrm_response_mata||0) + 
      (this.rowData.aukpkrm_response_verbal||0) + 
      (this.rowData.aukpkrm_response_motorik||0)
    },
    modulePage(){
      return 'UGDKajianRestrain' 
    },
  },
  data() {
    return {
      rowData: {},
      rowReg: {},
      isMonitoring: false,
      isOpenTS: false,
      
      resepObat: [],
      mRacikan: [],
      riwayatObat: [],
      mObatPreferensi: [],
      mDosis: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.query.kajianNo+'?monitoring=1'
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.query.kajianNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    
    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
        data: dataPost
        },
        "POST"
      )
    }, 1000),

    
    doSave(){
      let data = this.rowData
      data.aukpkr_updated_by = this.user.id
      data.aukpkr_updated_date = moment().format("YYYY-MM-DD HH:mm:ss")
      data.type = 'auto-save'

      this.loadingOverlay = true

      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.loadingOverlay = false
        
        let resp = res.data
        resp.statusType = 200
        this.$swal({
            title: "Data Berhasil Disimpan",
            icon: "success",
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            this.$router.push({ 
                name: 'UGDTindakLanjut', 
                params: {pageSlug: this.pageSlug},
                query: {regId: this.pageSlug, isRestrain: true}
            }).catch(()=>{}) 
        })
      })
    },

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            text: "Pastikan Jadwal Monitoring yang diiisi telah sesuai",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data-monitoring'
              data.resepObat = this.resepObat
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ 
                        name: 'UGDTindakLanjut', 
                        params: {pageSlug: this.pageSlug},
                        query: {regId: this.pageSlug, isRestrain: true}
                      }).catch(()=>{}) 
                  }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                  })
              })
            }
          })
        }
      })
    }
  },
  mounted() {
    this.apiGetForm()

    setTimeout(()=>{
      this.rowData.aukpkrm_petugas_ppa = this.user.level
    },1000)
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    tingkatKesadaranTotal(v){
        setTimeout(()=>{
            this.rowData.aukpkrm_response_gcs = v
        },250)
    },
    resepObat: {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    },
  }
    
}

</script>